import { IAuthLoginTranslations, IAuthRegisterTranslations } from '.';

const login: IAuthLoginTranslations = {
  welcome: 'Bienvenue',
  email: 'Adresse électronique',
  email_placeholder: 'Entrer votre adresse électronique',
  password: 'Mot de passe',
  password_placeholder: 'Entrer un mot de passe',
  login: 'Connexion',
  log_back: 'Se reconnecter ?',
  logged_out: 'Vous vous êtes déconnecté',
  logout: 'Se déconnecter',
  or: 'ou',
  other_account: 'Autre compte',
  forgot_password: 'Mot de passe oublié?',
  newAccount: 'Nouveau compte',
  wrong_password: 'Impossible de se connecter avec les identifiants saisis',
  link_sent: 'Check your inbox, we sent you a direct link to access the event!',
  send_link: 'Send login link',
  send_me_link: 'Send me an email with the login link',
  use_password: 'Use password to login',
  recover_password: 'Recover password',
  recover_password_link_sent: 'Check your inbox! We sent you a link for resetting your password',
};

const register: IAuthRegisterTranslations = {
  newAccount: 'Nouveau compte',
  error:
    "Une erreur s'est produite lors de la création de votre utilisateur. Veuillez réessayer ultérieurement.",
  cancel: 'Annuler',
  signUp: 'Inscription',
  welcome: 'Bienvenue sur la page de beamian !',
  confirmationEmail:
    'Nous vous avons envoyé un courrier électronique pour confirmer votre compte. Veuillez vérifier votre boîte de réception.',
  login: 'Connexion',
  password_not_match: 'Les mots de passe ne correspondent pas.',
};

export default { login, register };
