import {
  IManagerLegacyNavTranslations,
  IEventManagerHomeTranslations,
  IEventManagerExhibitorsTranslations,
  IEventManagerProductsTranslations,
  IEventManagerSessionsTranslations,
  IEventManagerExportsTranslations,
  IEventManagerEventsTranslations,
  IManagerTranslations,
} from '@/translations/default/managers';

const legacy_nav: Partial<IManagerLegacyNavTranslations> = {
  product_form: 'Products form',
  product_list: 'Job listing',
  product_categories: 'Product categories',
};

const home: Partial<IEventManagerHomeTranslations> = {
  products_subtitle: 'Number of products created',
};

const exhibitors: Partial<IEventManagerExhibitorsTranslations> = {
  products_limit_label: 'Product limit',
  products_limit_message: 'Maximum number of products this exhibitor can create',
};

const products: Partial<IEventManagerProductsTranslations> = {
  empty_list_form_subtitle: 'Create the product form',
  empty_list_categories_subtitle: 'Add product categories',
  empty_list_product_title: 'Product',
  empty_list_product_subtitle: 'Create a new product',
  title: 'Available products',
  subtitle: 'Number of products created',
};

const sessions: Partial<IEventManagerSessionsTranslations> = {
  // leave empty until needed
};

const exports: Partial<IEventManagerExportsTranslations> = {
  // leave empty until needed
};

const events: Partial<IEventManagerEventsTranslations> = {
  // leave empty until needed
};

const managers: DeepPartial<IManagerTranslations> = {
  legacy_nav,
  home,
  exhibitors,
  products,
  sessions,
  exports,
  events,
};

export default managers;
