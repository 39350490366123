import { ICommonTranslations } from '@/translations/default/common';

const common: Partial<ICommonTranslations> = {
  exhibitors: 'Empresas',
  exhibitor: 'Empresa',
  product_interactions: 'Candidaturas a ofertas de emprego',
  contact_request: 'Candidatura espontânea',
  filter_visitor_contacts: 'Filtrar os candidatos',
  filter_exhibitors: 'Filtrar empresas',
  check_sources: 'Filtrar por origem de interacção e respostas dos candidatos',
  filter_visitor_multiple_select:
    'Ao selecionar vários itens, os candidatos serão filtrados por todas as opções seleccionadas',
};

export default common;
