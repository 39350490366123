import { computed } from 'vue';
import store from '@/store';
import { IAuthState } from '@/store/modules/auth';
import { IUser } from '@/models/user';

export default function useAuth() {
  const userIsLoggedIn = computed(() => <boolean>store.getters.userIsLoggedIn);
  const userAuthState = computed(() => <IAuthState>store.getters.userAuthState);
  const currentUser = computed(() => <IUser>store.getters.currentUser);
  return {
    userAuthState,
    currentUser,
    userIsLoggedIn,
  };
}
