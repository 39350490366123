import { IPhoneInputTranslations } from '.';

const phone_input: IPhoneInputTranslations = {
  country_selector_label: 'Country code',
  country_selector_error: 'Choose code',
  phone_number_label: 'Phone number',
  phone_number_error: 'Wrong phone number format.',
};

export default phone_input;
