import axios from 'axios';
import { RealTimeAPI } from 'rocket.chat.realtime.api.rxjs';
import {
  IRocketSuccess,
  IRocketUserLogged,
  IRocketUser,
  ICreateIMResponse,
  IRocketCreateUserPayload,
  IRocketRegisterPayload,
  IRocketRegisterResponse,
  IRocketSubscriptionResponse,
  IRocketChannelMessagesResponse,
  IRocketJoinChannelPayload,
  IChannelsInfoResponse,
  IRocketUserLoggedIn,
} from '@/models/chat';
import { INetworking } from '@/models/networking';

import { Resolve, IData } from '.';
import config, { rocketConfig } from './config';

const url = process.env.VUE_APP_SERVER_PATH;
const rcurl = process.env.VUE_APP_ROCKET_SERVER_PATH;
const rcsurl = process.env.VUE_APP_ROCKET_SOCKET;

export const connectToRocketChat = () => new RealTimeAPI(rcsurl);

export const createRocketUser = (user: IRocketCreateUserPayload) =>
  new Promise((resolve: Resolve<IRocketSuccess>, reject) => {
    axios
      .post(`${rcurl}/api/v1/users.create`, user, rocketConfig())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const setChannelRead = (channel: { rid: string }) =>
  new Promise((resolve: Resolve<IRocketSuccess>, reject) => {
    axios
      .post(`${rcurl}/api/v1/subscriptions.read`, channel, rocketConfig())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const createSupportChannel = (params: { event: number; rocket_chat_user_id: string }) =>
  new Promise(
    (
      resolve: Resolve<{
        chat_room: number;
        event: number;
        id: number;
        rocket_chat_room_id: string;
      }>,
      reject,
    ) => {
      const requestUrl = `${url}/v2/support-chat-rooms`;

      axios
        .post(requestUrl, params, config())
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    },
  );

export const createSessionModerationChannel = (params: {
  session: number;
  rocket_chat_user_id: string;
}) =>
  new Promise(
    (
      resolve: Resolve<{
        chat_room: number;
        session: number;
        id: number;
        rocket_chat_room_id: string;
      }>,
      reject,
    ) => {
      const requestUrl = `${url}/v2/session-moderation-chat-rooms`;

      axios
        .post(requestUrl, params, config())
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    },
  );

export const sendMessage = (params: { roomId: string; text: string }) =>
  new Promise((resolve: Resolve<any>, reject) => {
    const requestUrl = `${rcurl}/api/v1/chat.postMessage`;

    axios
      .post(requestUrl, params, rocketConfig())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const syncChatUserAccount = (id: number) =>
  new Promise((resolve: Resolve<IRocketUserLoggedIn>, reject) => {
    axios
      .put(
        `${url}/v2/beamian-user-chat-accounts/${id}`,
        {
          trigger_chat_account_sync: true,
        },
        config(),
      )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const syncChatExhibitorAccount = (id: number) =>
  new Promise((resolve: Resolve<IRocketUserLoggedIn>, reject) => {
    axios
      .put(
        `${url}/v2/exhibitor-chat-accounts/${id}`,
        {
          trigger_chat_account_sync: true,
        },
        config(),
      )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const joinRocketChannel = (args: IRocketJoinChannelPayload) =>
  new Promise((resolve: Resolve<IRocketSuccess>, reject) => {
    axios
      .post(`${rcurl}/api/v1/channels.join`, args, rocketConfig())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const registerRocketUser = (user: IRocketRegisterPayload) =>
  new Promise((resolve: Resolve<IRocketRegisterResponse>, reject) => {
    axios
      .post(`${rcurl}/api/v1/users.register`, user, rocketConfig())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const loginRocketUser = (user: { user: string; password: string }) =>
  new Promise((resolve: Resolve<IRocketUserLogged>, reject) => {
    axios
      .post(`${rcurl}/api/v1/login`, user, rocketConfig())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getRocketUser = (username: string) =>
  new Promise((resolve: Resolve<IRocketRegisterResponse>, reject) => {
    axios
      .get(`${rcurl}/api/v1/users.info?username=${username}`, rocketConfig())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getChannelMessages = (id: string) =>
  new Promise((resolve: Resolve<IRocketChannelMessagesResponse>, reject) => {
    axios
      .get(`${rcurl}/api/v1/channels.messages?roomId=${id}`, rocketConfig())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const getImMessages = (id: string) =>
  new Promise((resolve: Resolve<IRocketChannelMessagesResponse>, reject) => {
    axios
      .get(`${rcurl}/api/v1/im.messages?roomId=${id}`, rocketConfig())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getChannelInfo = (id: string | null, name: string) =>
  new Promise((resolve: Resolve<IChannelsInfoResponse>, reject) => {
    let requestUrl = `${rcurl}/api/v1/channels.info`;

    if (id) {
      requestUrl += `?roomId=${id}`;
    }

    if (name) {
      requestUrl += `?roomName=${name}`;
    }

    axios
      .get(requestUrl, rocketConfig())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getRoomInfo = (id: string | null, name: string) =>
  new Promise((resolve: Resolve<IChannelsInfoResponse>, reject) => {
    let requestUrl = `${rcurl}/api/v1/rooms.info`;

    if (id) {
      requestUrl += `?roomId=${id}`;
    }

    if (name) {
      requestUrl += `?roomName=${name}`;
    }

    axios
      .get(requestUrl, rocketConfig())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const createRocketInstantMessage = (username: string) =>
  new Promise((resolve: Resolve<ICreateIMResponse>, reject) => {
    axios
      .post(`${rcurl}/api/v1/im.create`, { username }, rocketConfig())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const createRocketChannel = (channel: { name: string; members: string[] }) =>
  new Promise((resolve: Resolve<IRocketUser>, reject) => {
    axios
      .post(`${rcurl}/api/v1/channels.create`, channel, rocketConfig())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getRocketSubscriptions = () =>
  new Promise((resolve: Resolve<IRocketSubscriptionResponse>, reject) => {
    axios
      .get(`${rcurl}/api/v1/subscriptions.get`, rocketConfig())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const logoutRocketUser = () =>
  new Promise((resolve: Resolve<IRocketSubscriptionResponse>, reject) => {
    axios
      .post(`${rcurl}/api/v1/logout`, {}, rocketConfig())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const setUserNetworking = (networking: { space: number; visitor: number }) =>
  new Promise((resolve: Resolve<IRocketSubscriptionResponse>, reject) => {
    axios
      .post(`${url}/v2/networking-subscriptions`, networking, config())

      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const getUserNetworking = (visitor = 0) =>
  new Promise((resolve: Resolve<IData<INetworking>>, reject) => {
    let urlString = `${url}/v2/networking-subscriptions`;

    if (visitor !== 0) {
      urlString = `${urlString}?visitor=${visitor}`;
    }

    axios
      .get(urlString, config())

      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const createNewtorkingSpace = (event: number) =>
  new Promise((resolve: Resolve<IRocketSubscriptionResponse>, reject) => {
    axios
      .post(`${url}/v2/networking-spaces`, { event }, config())

      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getExhibitorChatAccount = (exhibitor: number) =>
  new Promise((resolve: Resolve<IData<IRocketUserLoggedIn>>, reject) => {
    axios
      .get(
        `${url}/v2/exhibitor-chat-accounts?exhibitor=${exhibitor}&page_size=10&ordering=-id`,
        config(),
      )

      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const getUserChatAccount = (user: number) =>
  new Promise((resolve: Resolve<IData<IRocketUserLoggedIn>>, reject) => {
    axios
      .get(`${url}/v2/beamian-user-chat-accounts?user=${user}&page_size=10&ordering=-id`, config())

      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const getNetworkingSpaces = (event: number) =>
  new Promise((resolve: Resolve<{ event: number; id: number }[]>, reject) => {
    axios
      .get(`${url}/v2/networking-spaces?event=${event}`, config())

      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const getEventChatRooms = (event: number) =>
  new Promise((resolve: Resolve<{ event: number; id: number; roomId: string }[]>, reject) => {
    axios
      .get(`${url}/v2/event-chat-rooms?event=${event}`, config())

      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
