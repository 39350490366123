import {
  ISessionDetailsTranslations,
  ISessionsAssignmentTranslations,
  ISessionsCommonTranslations,
  ISessionsTranslations,
} from '.';

const details: ISessionDetailsTranslations = {
  session_has_ended: 'Diese Sitzung ist vorbei.',
  register_title: 'Für diese Sitzung registrieren',
  online_seats: 'Online-Sitze',
  in_person_seats: 'Persönliche Sitzplätze',
};

const inputs = {
  other_languages: 'Otros-Idiome',
  session_type: 'Tipo de sesión',
  session_type_placeholder: 'Auswahl des Sitzungstyps',
  privacy_label: '¿Quién puede ver esta sesión?',
  privacy_open: 'Cualquiera',
  privacy_registration: 'Registrierung anfordern',
  privacy_closed: 'Requerir invitación directa',
  video_call_url: 'URL von Videollamada',
  video_call_url_placeholder: 'URL de la videollamada oder déjala vacía einfügen',
  select_channel: 'Kanal auswählen',
  stage: 'Bühne',
  stage_placeholder: 'Wählen Sie eine Bühne',
  allow_tag_not_registered: 'Nicht registrierten Tag zulassen',
};

const common: ISessionsCommonTranslations = {
  in_person: 'Persönlich',
  online: 'Online',
  stream: 'Stream',
  video_call: 'Videoanruf',
  in_person_registration: 'Persönlich Registration',
  online_registration: 'Online Registration',
  moderation_question: 'Dem Moderator eine Frage stellen',
  moderation_chat: 'Chatten Sie mit dem Moderator',
  moderation_chat_error: 'Zugang zum Moderations-Chat nicht möglich.',
  moderation_question_created: 'Frage erfolgreich an den Moderator gesendet.',
  moderation_question_error: 'Die Frage konnte nicht vorgelegt werden.',
};

const assignment: ISessionsAssignmentTranslations = {
  heading: 'Wählen Sie einen Besucher, den Sie als Sitzungsleiter zuweisen möchten',
  success: 'Besucher erfolgreich als Sitzungsleiter eingesetzt.',
  error: 'Dieser Besucher konnte nicht als Sitzungsleiter zugewiesen werden.',
  empty_list: 'keine Ergebnisse!',
  assign: 'Manager hinzufügen',
  remove_from: 'Entfernen Sie',
  unassign_1: 'Do you wish to unassign',
  unassign_2: 'as a manager to this session?',
};

const sessions: ISessionsTranslations = {
  assignment,
  details,
  common,
  inputs,
};

export default sessions;
