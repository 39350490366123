import { IDashboardTranslations } from '.';

const dashboard: IDashboardTranslations = {
  welcome: 'Plataforma para eventos ao vivo, híbridos e virtuais',
  whatsup:
    'A beamian oferece uma solução integrada de gestão de eventos que se adapta a todos os cenários.',
  contact_us: 'Fale connosco',
};

export default dashboard;
