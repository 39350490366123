import {
  IEventManagerAddonsTranslations,
  IEventManagerCheckInTranslations,
  IEventManagerEventsTranslations,
  IEventManagerExhibitorsTranslations,
  IEventManagerExportsTranslations,
  IEventManagerHomeTranslations,
  IEventManagerProductsTranslations,
  IEventManagerPromoCodesTranslations,
  IEventManagerSessionsTranslations,
  IManagerLegacyNavTranslations,
  IManagerTranslations,
  IEventManagerTicketsTranslations,
} from '.';

const promo_codes: IEventManagerPromoCodesTranslations = {
  discount_value: 'Discount value',
  discount_type: 'Discount type',
  discount_type_percentage: 'Percentage',
  discount_type_value: 'Value',
  promo_code_type: 'Promo-code type',
  promo_code_type_discount: 'Discount',
  promo_code_type_revealing: 'Reveal hidden tickets',
  promo_code_type_hybrid: 'Discounts hidden tickets',
};

const legacy_nav: IManagerLegacyNavTranslations = {
  event_details: "Détails de l'événement",
  event_information: "Informations sur l'événement",
  media_channels: 'Canaux médias',
  team: 'Équipe',
  networking: 'Réseautage',
  trends: 'Nouvelles',
  visitor_types: 'Types de visiteurs',
  packages: 'Forfaits',
  exhibitors: 'Exposants',
  exhibitor_form: 'Fiche exposants',
  exhibitor_list: 'Liste des exposants',
  exhibitor_cards: "Cartes d'exposant",
  exhibitor_sessions: 'Séances exposants',
  exhibitor_categories: "Catégories d'exposants",
  product_form: 'Formulaire du produit',
  product_list: 'Liste de produits',
  product_categories: 'Catégories de produits',
  interactions: 'Interactions',
  exhibitor_tickets: 'Billets exposants',
  exhibitor_packages: 'Forfaits exposants',
  visitors: 'Visiteurs',
  tickets: 'Billets',
  visitor_form: 'formulaire visiteur',
  session_areas: 'Zones de session',
  labels: 'Étiquettes',
  consumptions: 'Consommations',
  visitors_list: 'Liste des visiteurs',
};

const home: IEventManagerHomeTranslations = {
  title: 'Hello',
  subtitle: 'Welcome to the beamian event management app',
  visitor_registration: 'Visitor registration',
  exhibitor_registration: 'Exhibitor registration',
  general_information_title: 'Your performance indicators',
  general_information_subtitle: 'How visitors are interacting with you',
  networking_subtitle: 'Visitors enrolled in networking',
  manage_networking_space: 'Manage networking space',
  products_subtitle: 'Amount of products created',
};

const addons: IEventManagerAddonsTranslations = {
  subtitle: 'Manage add-ons',
  name_placeholder: "Enter this addon's name",
  description_placeholder: 'Describe this add-on',
  price_placeholder: 'Please insert the value in cents',
  price_label: 'Price',
  image_placeholder: '5mb max, png, jpg',
  availability_start_date_label: 'Available from',
  availability_end_date_label: 'Available until',
  time_limited_label: 'Time limited',
  time_limited_until_placeholder:
    'Select this setting if you want your addon to only be available for a limited time',
  restricted_usage_period_label: 'Restringir el uso',
  restricted_usage_period_placeholder:
    'Seleccione esta configuración si desea que esto sólo se pueda utilizar durante un tiempo limitado',
  hidden_label: 'Hidden',
  max_quantity: 'Max quantity',
  max_quantity_per_user_label: 'Max quantity per user',
  min_quantity_per_user_label: 'Group ticket quantity',
  min_quantity_per_user_message: 'A group ticket must account for at least 2 visitors.',
  group_ticket: 'Group ticket',
  leave_ticket_empty:
    'Deja esta lista vacía si quieres que este complemento esté disponible para todos los billetes',

  select_ticket: 'Sélectionner le billet',
  select_session: 'Sélectionner la session',
  select_channel: 'Sélectionner le canal',
  select_item: 'Sélectionner le consommable',
  empty_list_title: 'No hay complementos creados todavía',
  empty_list_subtitle: '¡Comienza a crear complementos!',
  categories_message: "'Ceux-ci seront disponibles dans vos formulaires d'addons'",
  categories_updated_message: 'Liste des catégories mise à jour avec succès',
  ticket_type_restriction_label: 'Restriction par type de billet',
};

const exhibitors: IEventManagerExhibitorsTranslations = {
  ticket_invites: 'Attribution de billets',
  allowance_label: 'Allocation limit',
  allowance_message: "Nombre d'utilisations autorisées pour chaque ticket",
  ticket_code_label: 'Code du billet',
  ticket_code_message: 'Les exposants peuvent partager ce code pour utiliser leur allocation',
  ticket_type_default: 'Sélectionnez le type de ticket',
  ticket_type_label: 'Type de ticket',
  products_limit_label: 'Limites de produits',
  products_limit_message: "Nombre maximal de produits qu'un exposant peut créer",
  sessions_limit_label: 'Limites des sessions',
  sessions_limit_message: "Nombre maximal de sessions qu'un exposant peut créer",
  exhibitor_tier_label: 'Tier',
  exhibitor_tier_default: 'Sélectionnez le niveau de cet exposant',
  exhibitor_tier_message: "Plus le niveau est élevé, plus il est visible sur l'événement",
  exhibitor_tier_ds: 'Aucun niveau',
  exhibitor_tier: 'Tier',
  exhibitor_qrcode_reader_ds: 'Lecteur de codes QR',
  exhibitor_qrcode_reader_message: 'Permettre aux exposants de scanner les codes QR',
  empty_list_title: 'Inscriptions des exposants',
  empty_list_subtitle: "Indiquer aux exposants où s'inscrire",
};

const products: IEventManagerProductsTranslations = {
  empty_list_form_title: 'Formulaire',
  empty_list_form_subtitle: 'Set up a product form',
  empty_list_categories_title: 'Catégories',
  empty_list_categories_subtitle: 'Ajouter des catégories de produits',
  empty_list_product_title: 'Produit',
  empty_list_product_subtitle: 'Créer un nouveau produit',
  title: "Offres d'emploi disponibles",
  subtitle: "Nombre d'emplois créés",
  empty_list_title: 'Commençons à créer des choses !',
  empty_list_subtitle: 'Construisez votre catalogue',
};

const sessions: IEventManagerSessionsTranslations = {
  empty_list_title: 'Commençons à créer des choses!',
  empty_list_subtitle: 'Remplissez votre calendrier',
};

const exports: IEventManagerExportsTranslations = {
  empty_list_title: 'Exporter des contacts',
  empty_list_subtitle: 'Exportez vos contacts',
};

const checkin: IEventManagerCheckInTranslations = {
  subtitle: 'Review the visitor entrances and exits of your event',
  empty_list_title: 'No check-ins yet!',
  empty_list_subtitle: "Come back later and maybe we'll have something for you",
};

const events: IEventManagerEventsTranslations = {
  title: 'Bienvenue sur la page de beamian',
  subtitle: 'Veuillez choisir un événement',
  rolesTitle: 'Bienvenue',
  rolesSubtitle: 'Que souhaitez-vous faire ?',
  registerVisitor: 'Inscrire un visiteur',
  registerVisitorDescription:
    'Inscrivez-vous ou inscrivez une autre personne en tant que visiteur de cet événement.',
  createExhibitor: 'Inscrire un exposant',
  createExhibitorDescription: 'Inscrivez votre exposant à cet événement.',
  manageVisitor: 'Gérer un visiteur',
  manageVisitorDescription:
    'Modifier les informations relatives à un visiteur, voir les interactions.',
  manageExhibitor: 'Gérer un exposant',
  manageExhibitorDescription:
    'Modifier les informations relatives à un exposant, voir les interactions.',
  manageEvent: 'Gérer un événement',
  manageEventDescription: "Accéder aux paramètres, aux informations et au contenu de l'événement.",
  event_settings: "Paramètres d'événement",
  new: 'New event',
  edit: 'Edit event',
  create: 'Create event',
  send_registration_invite: 'Send registration invite',
  email: 'Email',
  name: 'Name',
  name_placeholder: 'Enter event name',
  assign: 'Assign',
  delete_manager: 'Delete event manager',
  no_managers_assigned: 'This event has no event managers assigned',
  countdown_date: 'Countdown Date',
  start_date: 'Start date',
  end_date: 'End date',
  capacity: 'Capacity',
  used: 'Used',
  available: 'Available',
  location: 'Location',
  location_placeholder: 'Enter event location',
  is_published: 'Is published',
  base_cost: 'Base cost',
  max_capacity: 'Max capacity',
  delete: 'Delete event',
  assign_manager: 'Assign event manager (select 1 event only)',
  manager: 'Event manager',
  select_manager: 'Select event manager',
  timezone: 'Timezone',
  europe: 'Europe',
  lisbon: 'Lisbon',
  london: 'London',
  madrid: 'Madrid',
  berlin: 'Berlin',
  default_language: 'Default language',
  portuguese: 'Portuguese',
  spanish: 'Spanish',
  english: 'English',
  german: 'German',
  french: 'French',
  description: 'Description',
  description_placeholder: 'Enter an event description',
  image: 'Image',
  image_placeholder: 'Chargez une image',
  background_image: 'Background image',
  background_image_placeholder: 'Chargez une image',
  created_success: 'Event created successfuly',
  manager_email_placeholder: 'Enter user email',
  managers: 'Event managers',
  manager_added_success: 'Event manager added successfuly',
  manager_deleted_success: 'Event manager deleted successfuly',
  manager_user_not_exists:
    'User does not exist. Make sure you typed the email correctly or send a registration invitation to this email.',
  manager_setup: 'Choose name and password',
  manager_user_password: 'Password',
  manager_user_name_placeholder: 'Enter user name',
  manager_user_password_placeholder: 'Enter user password',
  setup_booklet: 'Set up booklet',
  booklet: 'Booklet',
  configure_booklet: 'If you want to send a booklet when the event ends please configure it.',
  no_fields_selected: 'No fields selected.',
  drag_drop_fields: 'Drag and drop fields to set order.',
  title_placeholder: 'Enter booklet title',
  cover: 'Cover',
  cover_placeholder: 'Upload booklet cover image',
  fields: 'Fields',
  create_booklet_success: 'Booklet created',
  create_booklet_error: 'Booklet creation failed',
  edit_booklet_success: 'Booklet edited',
  edit_booklet_error: 'Booklet editing failed',
  no_available_fields: 'No available fields',
  empty_space: 'Empty space',
  live_interactions_email: 'Live interactions email invitation',
};

const tickets: IEventManagerTicketsTranslations = {
  subtitle: 'Gérez vos billets',
  amount: 'Quantité',
  canceled: 'Annulé',
};

const managers: IManagerTranslations = {
  legacy_nav,
  home,
  exhibitors,
  products,
  promo_codes,
  addons,
  events,
  sessions,
  exports,
  checkin,
  tickets,
};

export default managers;
