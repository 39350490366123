import {
  IVisitorsDashboardTranslations,
  IVisitorSidebar,
  IVisitorSettingsTranslations,
  IVisitorExhibitorTranslations,
  IVisitorOnboardingTranslations,
  IVisitorNetworkingTranslations,
  IVisitorSessions,
  IVisitorExhibitorSessions,
  IVisitorProfile,
  IVisitorProducts,
} from '@/translations/default/visitors';

const dashboard: IVisitorsDashboardTranslations = {
  home: 'Home',
  information: 'Profile',
  networking: 'Networking',
  networking_description: 'Meet fellow visitors',
  sessions: 'Programme',
  sessions_description: 'See all sessions',
  sessions_btn: 'Event programme',
  name: 'Name of the visitor:',
  edit: 'Edit',
  exhibitors: 'Posters & Partners',
  nrOfInteractions: 'See all posters & partners',
  seeAll: 'Posters & Partners',
  download: 'Download',
  join_networking: 'Networking',
  event_finished: 'Event is finished',
  event_not_start: 'Event did not start yet',
  featured_products: 'Featured products',
  featured_sessions: 'Featured sessions',
  featured_exhibitors: 'Meet our Partners',
  upcoming_exhibitors: 'Programme',
};

const sidebar: IVisitorSidebar = {
  title: 'Visiting',
  home: 'Home',
  exhibitors: 'Exhibitors',
  settings: 'Profile',
  sessions: 'Programme',
  exhibitor_sessions: 'My programme',
  event_sessions: 'Event programme',
  myCalendar: 'My programme',
  eventCalendar: 'Event programme',
};

const settings: IVisitorSettingsTranslations = {
  title: 'Visitor form',
  basicInfo: 'Basic information',
  save: 'Save',
  additionalInfo: 'Registration information',
  formError: 'No information retrieved, form not available.',
};

const exhibitors: IVisitorExhibitorTranslations = {
  title: 'Posters & Partners',
  subtitle: 'See posters & partners and check their offers',
  download: 'Download',
  noExhibitors: 'No posters & partners to show.',
  exhibitor_information: 'Exhibitor information',
  contact_success: 'Your information was shared succesfuly!',
  all_exhibitors: 'All posters & partners',
  interacted_exhibitors: 'Posters & partners I interacted with',
  not_interacted_exhibitors: 'Posters & partners I didn’t interact with',
  filter_exhibitors: 'Filter posters & partners',
  contact_request: 'Leave my contact',
  company_info: 'Exhibitor information',
  job_offers: 'Offers',
  apply_to_job_offer: "I'm interested",
  job_offer_info: 'Information about the offer',
  cover_letter: 'Why are you interested?',
  cover_letter_text:
    'Let the exhibitor know why you’re interested and/or ask him a question about the offer',
  your_info_will_be_shared: 'Your information will be shared with the exhibitor',
  submit_my_application: 'Submit',
  successfully_submited: 'Successfully submitted ',
  already_applied: 'You have already shared your interest in this offer.',
  schedule_interview: 'Reserve your spot',
  select_time_slot: 'Choose a time slot to join',
  all_visitors: 'All visitors',
};

const onboarding: DeepPartial<IVisitorOnboardingTranslations> = {
  basicInformation: {
    title: 'Visitor details',
    createVisitor: 'Register as a visitor',
    modal: {
      title: 'Warning',
      subtitle: 'If you wish to manage your visitor, please login first.',
      skip: 'Skip',
      createAccount: 'Create account',
      login: 'Login',
    },
    already_registered: 'You are already registered in this event!',
    send_login_link: 'Click here to receive the confirmation email.',
  },
  tickets: {
    title: 'Tickets',
    subtitle: 'Buy tickets or redeem your ticket code',
    message_head: 'If you need to buy tickets in bulk and register later, please',
    to_buy_tickets: 'to buy tickets;',
    copy_ticket_code:
      "Copy the registration code we'll send you to your email and register with it.",
    ticket_code_invalid: 'Registration code is invalid or has already been used',
    ticket_code_label: 'Registration code',
    ticket_code_placeholder: 'Insert your registration code',
    use_ticket_code: 'Use a ticket code',
  },
  managedFields: {
    title: 'Registration information',
  },
  review: {
    title: 'Review information',
    name: 'Name',
    proceed_to_payment: 'Continue',
    register_someone_else: 'Add another person',
  },
  checkout: {
    vat_label: 'VAT',
    country_label: 'Country',
    country_default: 'Select your country',
    city_label: 'City',
    address_label: 'Address',
    zip_code: 'Zip code',
    payment_method: 'Payment method',
    payment_method_card_label: 'Credit or debit card',
    payment_processed: 'Payment processed.',
    check_your_phone: 'Please check your phone.',
    payment_failed: 'Payment failed. Please try again.',
    payment_details_sent: 'We sent the payment details to your email.',
    mbref_payment_message: "We'll send an MB reference to your email.",
  },
  completed: {
    title: 'Thank you for your registration!',
    subtitle: "Please check your inbox, we've sent you a confirmation email.",
    manage: 'Manage',
  },
  back: 'Back',
  next: 'Next',
  submit: 'Submit',
  not_allowed_title: 'Your account is pending aproval',
  not_allowed_subtitle: "Please wait for the event manager's approval",
  registration_slow: 'Registration is taking longer than usual. Please wait.',
  order_summary: 'Order summary',
};

const networking: IVisitorNetworkingTranslations = {
  title: 'Networking',
  subtitle: 'Talk to fellow visitors',
  chat: 'Chat',
  profile: 'Profile',
  signup_title: 'Do you wish to meet other visitors?',
  signup_message:
    'You’re about to join the networking area. Your information will be available to other visitors.',
};

const sessions: IVisitorSessions = {
  subtitle: "Check your registration for the event's sessions here",
  anonymous_subtitle: "Check here and register for the event's sessions",
  event_sessions: 'Event sessions',
  exhibitor_sessions: 'Exhibitor sessions',
  all_sessions: 'All sessions',
  join_in_15: 'This session is not currently ongoing',
  need_help: 'Need help?',
  refresh: 'Refresh',
  troubleshoot: 'Troubleshoot',
  help_title: 'Having issues?',
  help_content: `
    <p>
      If you have issues with chat or audio please make sure you're not using a restricted proxy
      connection or have a VPN enabled at this time. <br />
    </p>
    <p>
      Check
      <a
        href="https://www.easypcmod.com/how-to-fix-jitsi-meet-no-audio-problem-11976"
        target="_blank"
        style="text-decoration: underline"
        >this guide </a
      >to help troubleshoot any issue unresolved by updating your network settings.
    </p>
    <p>
      The following network ports must be open on your firewall to allow for server traffic:
    </p>
    <ul>
     <li>80 TCP – for SSL certificate verification with Let's Encrypt. </li>
     <li>443 TCP – for general access by the video call provider.</li>
     <li>10000 UDP - for network video and audio communications.</li>
     <li>TCP/4443</li>
    </ul>
  `,
};

const exhibitorSessions: IVisitorExhibitorSessions = {
  subtitle: 'Sessions in which you are registered',
};

const profile: IVisitorProfile = {
  visitor_information: 'Visitor information',
};

const products: IVisitorProducts = {
  title: 'Offers',
  subtitle: 'Browse and interact with exhibitor offers',
  products_same_brand: 'Offers from the same brand',
};

export default {
  dashboard,
  settings,
  exhibitors,
  onboarding,
  sidebar,
  networking,
  sessions,
  exhibitorSessions,
  profile,
  products,
};
