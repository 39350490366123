
import { computed, defineComponent } from 'vue';
import { IAddon } from '@/models/ticket';
import store from '@/store';
import { ICartAddon } from '@/store/modules/cart';
import useContext from '@/composition/context';

// @ is an alias to /src
export default defineComponent({
  setup() {
    const { eventContextRoute, translations, contextCurrency } = useContext();
    const cartAddons = computed(() => store.getters.cartAddons as ICartAddon[]);
    const cartPrice = computed(() => store.getters.cartPrice as number);

    return { eventContextRoute, cartAddons, cartPrice, translations, contextCurrency };
  },
  methods: {
    clearAddon(addon: IAddon) {
      store.commit('clearCartAddon', addon);
    },
    addAddon(addon: IAddon) {
      store.commit('addCartAddon', addon);
    },
    removeAddon(addon: IAddon) {
      store.commit('removeCartAddon', addon);
    },
  },
});
