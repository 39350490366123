import { INotificationsTranslations } from '.';

const notifications: INotificationsTranslations = {
  exhibitor_invite_title: 'Foi convidado(a) para uma sessão com expositor ',
  product_interaction_title: ' interagiu com ',
  interaction_title: ' partilhou o seu contacto consigo.',
  moderation_question: ' colocou um questão para sua moderação.',
};

export default notifications;
