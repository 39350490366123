import { IEventV2, IManager } from '@/models/event';
import { ILiveVisitor, IVisitor, IVisitorSearchFields } from '@/models/visitor';
import { IExhibitor } from '@/models/exhibitor';
import { ISession } from '../../models/session';
import { IPaymentInfo } from '@/models/payments';

export type UserRole = 'exhibitor' | 'visitor' | 'manager' | 'admin' | 'unset';

export interface IContextState {
  event: IEventV2 | null;
  exhibitor: IExhibitor | null;
  visitor: IVisitor | null;
  role: UserRole;
  manager: IManager | null;
  liveMenuLocked: boolean;
  session: ISession;
  checkinSession: ISession;
  liveVisitor: ILiveVisitor | null;
  paymentInfo: IPaymentInfo;
  visitorSearchFields: IVisitorSearchFields;
}

const state: IContextState = {
  event: {} as IEventV2,
  exhibitor: {} as IExhibitor,
  manager: {} as any,
  liveMenuLocked: false,
  visitor: {
    id: 0,
    name: 'Guest',
    email: 'guest@beamian.com',
    event: 0,
    status: 'registered',
    type: 0,
    type_name: '',
    user_account: 0,
  },
  liveVisitor: {} as ILiveVisitor,
  role: 'unset',
  session: {} as ISession,
  checkinSession: {} as ISession,
  paymentInfo: {} as IPaymentInfo,
  visitorSearchFields: {} as IVisitorSearchFields,
};

const getters = {
  contextExhibitor: (state: IContextState) => state.exhibitor,
  contextManager: (state: IContextState) => state.manager,
  contextEvent: (state: IContextState) => state.event,
  contextVisitor: (state: IContextState) => state.visitor,
  contextRole: (state: IContextState) => state.role,
  contextSession: (state: IContextState) => state.session,
  contextCheckinSession: (state: IContextState) => state.checkinSession,
  contextLiveVisitor: (state: IContextState) => state.liveVisitor,
  liveMenuLocked: (state: IContextState) => state.liveMenuLocked,
  contextPaymentInfo: (state: IContextState) => state.paymentInfo,
  contextVisitorSearchFields: (state: IContextState) => state.visitorSearchFields,
};

const actions = {};

const mutations = {
  setContextEvent: (state: IContextState, event: IEventV2) => {
    state.event = event;
  },
  setContextExhibitor: (state: IContextState, exhibitor: IExhibitor) => {
    state.exhibitor = exhibitor;
    state.role = 'exhibitor';
  },
  setContextRole: (state: IContextState, role: UserRole) => {
    state.role = role;
  },
  setContextManager: (state: IContextState, manager: IManager) => {
    state.manager = manager;
  },
  setLiveMenuLocked: (state: IContextState, liveMenuLocked: boolean) => {
    state.liveMenuLocked = liveMenuLocked;
  },
  setContextVisitor: (state: IContextState, visitor: IVisitor) => {
    state.visitor = {} as IVisitor;
    state.visitor = visitor;
    state.role = 'visitor';
  },
  setContextSession: (state: IContextState, session: ISession) => {
    state.session = session;
  },
  setContextCheckinSession: (state: IContextState, session: ISession) => {
    state.checkinSession = session;
  },
  setContextLiveVisitor: (state: IContextState, visitor: ILiveVisitor) => {
    state.liveVisitor = {} as ILiveVisitor;
    state.liveVisitor = visitor;
  },
  setPaymentInfo: (state: IContextState, paymentInfo: IPaymentInfo) => {
    state.paymentInfo = paymentInfo;
  },
  setContextVisitorSearchFields: (
    state: IContextState,
    visitorSearchFields: IVisitorSearchFields,
  ) => {
    state.visitorSearchFields = visitorSearchFields;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
