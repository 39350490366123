import { ILiveTranslations } from '.';

const live: ILiveTranslations = {
  awaiting_payment: 'Non payé',
  buy_ticket: 'Acheter un billet',
  checkin_success: 'Enregistrement réussi',
  checkout_success: 'Check-out réussi',
  entered: 'Entré',
  exited: 'Sorti',
  invoicing: 'Facturation',
  new_registration: 'Nouvel enregistrement',
  no_email_label: 'Sans e-mail',
  no_results: 'Impossible de trouver des résultats',
  payment_confirmation_popup: 'Souhaitez-vous confirmer ce paiement ?',
  paymnet_confirmation_success: 'Paiement confirmé avec succès',
  payment_information_title: 'Informations de paiement',
  print_label: "Imprimer l'étiquette",
  registered: 'Enregistré',
  pending_success: "Le statut est passé à 'en attente",
  pre_registration_success: 'Le pré-enregistrement de ce visiteur a été effectué avec succès',
  registration_success: 'Enregistré avec succès',
  session_registrations: 'Enregistrements de session',
  set_pending: 'Définir le statut comme étant en attente',
  set_registered: 'Définir le statut comme étant enregistré',
  short_id_error:
    "L'identifiant court n'a pas pu être mis à jour ; vérifiez qu'il existe dans l'événement",
  subtitle: "Utilitaires d'événements en temps réel",
  subtitle_empty: 'Veuillez rechercher un visiteur ou en créer un nouveau',
  visitor_already_exists: 'Un visiteur avec cet email a déjà été enregistré pour cet événement',
  visitor_label: 'Étiquette du visiteur',
  visitor_not_found: "Le visiteur n'a pas pu être trouvé",
  visitor_type: 'Type de visiteur',
  ticket_type: 'Type de billet',
  ticket_type_help: "Ceci n'inclut pas les billets de groupe",
  title_empty: 'Vos informations sur le visiteur seront affichées ici !',
  unregistered: 'Non inscrit',
};

export default live;
