import { ActionContext } from 'vuex';
import { editUser } from '@/api/users';
import { IUser } from '@/models/user';

import { IState, IObjectState, IStateObject } from '..';

/*
  In a real scenario, all these interfaces would be under the "model" folder.
*/
export interface IStateUser extends IUser, IObjectState {}
export type IUsersState = IStateObject<IStateUser>;

const state: IUsersState = {
  list: [],
  loading: false,
  message: '',
  error: false,
};

const getters = {
  users: (state: IUsersState) => state.list,
  usersState: (state: IUsersState) => ({
    loading: state.loading,
    error: state.error,
    message: state.message,
  }),
};

const actions = {
  editUser(context: ActionContext<IUsersState, IState>, editedUser: IStateUser) {
    context.commit(
      'setLoadingUser',
      context.state.list.find((user) => editedUser.id === user.id),
    );

    editUser(editedUser)
      .then(() => {
        context.commit('editUser', editedUser);
      })
      .catch((err) => {
        context.commit('setUsersError', err.message);
      });
  },
};

const mutations = {
  setUsersError: (state: IUsersState, message: string) => {
    state.loading = false;
    state.error = true;
    state.message = message;
  },

  setUsersLoading: (state: IUsersState) => {
    state.loading = true;
    state.error = false;
  },

  setLoadingUser: (state: IUsersState, user: IStateUser) => {
    state.list[state.list.indexOf(user)] = {
      ...state.list[state.list.indexOf(user)],
      loading: true,
      error: false,
    };
    state.error = false;
  },

  setUsers: (state: IUsersState, users: IStateUser[]) => {
    state.loading = false;
    state.list = users;
  },

  editUser: (state: IUsersState, editedUser: IStateUser) => {
    state.list = state.list.map((user) =>
      user.id === editedUser.id
        ? {
            ...editedUser,
            loading: false,
            error: false,
          }
        : user,
    );
  },

  newUser: (state: IUsersState, user: IStateUser) => {
    state.loading = false;
    state.error = false;
    state.list.unshift(user);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
