import { IPhoneInputTranslations } from '.';

const phone_input: IPhoneInputTranslations = {
  country_selector_label: 'Código',
  country_selector_error: 'Escolha um país',
  phone_number_label: 'Número de telefone',
  phone_number_error: 'Formato de número de telefone errado.',
};

export default phone_input;
