import axios from 'axios';
import { IUser } from '@/models/user';

import { Resolve } from '.';
import config from './config';

interface IData {
  results: IUser[];
  item_count: number;
  page_count: number;
  current: number;
  next: number;
  previous: number;
}

const url = process.env.VUE_APP_SERVER_PATH;

export const addUser = (user: IUser) =>
  new Promise((resolve: Resolve<IUser>, reject) => {
    axios
      .post(`${url}/sign-up`, user, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const fetchUsers = () =>
  new Promise((resolve: Resolve<IData>, reject) => {
    axios
      .get(`${url}/v2/beamian-users?page_size=200`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const createUser = (user: IUser) =>
  new Promise((resolve: Resolve<IUser>, reject) => {
    axios
      .post(`${url}/v2/beamian-users`, user, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const checkUser = (user: {
  email: string;
  event: string;
  event_id: number;
  send_confirmation_email: boolean;
}) =>
  new Promise(
    (
      resolve: Resolve<{
        id: number;
        email: string;
        name: string;
        form_is_paid: boolean;
        form_price: number;
        ticket_is_validated: boolean;
        tickets: [];
        can_use_discount: boolean;
        registration_status: string;
        status: string;
      }>,
      reject,
    ) => {
      axios
        .post(`${url}/check-user`, user, config())
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    },
  );

export const fetchUserByEmail = (email: string) =>
  new Promise((resolve: Resolve<IData>, reject) => {
    axios
      .get(`${url}/v2/beamian-users?email=${encodeURIComponent(email)}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const fetchUserById = (id: number) =>
  new Promise((resolve: Resolve<IUser>, reject) => {
    axios
      .get(`${url}/v2/beamian-users/${id}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const editUser = (user: IUser & { ct?: string }) =>
  new Promise((resolve: Resolve<IData>, reject) => {
    axios
      .patch(`${url}/v2/beamian-users/${user.id}`, user, config(false, '', !!user.ct))
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const uploadProfilePic = (user: IUser & { ct?: string }, formData: FormData) =>
  new Promise((resolve: Resolve<{ profile_picture: string }>, reject) => {
    axios
      .patch(`${url}/v2/beamian-users/${user.id}`, formData, config(true, '', !!user.ct))
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const editPassword = (newPassword: string) =>
  new Promise((resolve: Resolve<IData>, reject) => {
    const passwordChangeUrl = url.replace(/\/api([^/api]*)$/, '$1');

    axios
      .post(
        `${passwordChangeUrl}/accounts/change-password`,
        { new_password: newPassword },
        config(),
      )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
