import exhibitors from './exhibitors/es';
import visitors from './visitors/es';
import managers from './managers/es';
import common from './common/es';

export default {
  common,
  exhibitors,
  visitors,
  managers,
};
