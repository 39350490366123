import { ActionContext } from 'vuex';
import { loginUser, getRoles } from '@/api/auth';
import { IAuth, IV2 } from '@/models/auth';
import { IUser } from '@/models/user';

import { IState, IObjectState } from '..';

export interface IAuthState extends IObjectState, IV2 {
  user: IUser;
  token: string;
  userIsLoggedIn: boolean;
  userRolesSet: boolean;
}

const state: IAuthState = {
  user: {
    name: '',
    email: '',
    profile_picture: '',
  },
  links: {
    manages: {
      href: '',
      ids: [],
    },
    manages_session: {
      href: '',
      ids: [],
    },
    exhibits: {
      href: '',
      ids: [],
    },
    admins: {
      href: '',
      ids: [],
    },
    visits: {
      href: '',
      ids: [],
    },
  },
  related: {
    events: [],
    exhibitors: [],
    visitors: [],
    event_managers: [],
    session_managers: [],
  },
  token: '',
  userIsLoggedIn: false,
  userRolesSet: false,
  loading: false,
  message: '',
  error: false,
};

const getters = {
  userAuthState: (state: IAuthState) => ({
    userIsLoggedIn: state.userIsLoggedIn,
    token: state.token,
    loading: state.loading,
    message: state.message,
    error: state.error,
  }),
  currentUser: (state: IAuthState) => state.user,
  userIsLoggedIn: (state: IAuthState) => state.userIsLoggedIn,
  userManages: (state: IAuthState) => state.links.manages.ids,
  userManagesSession: (state: IAuthState) => state.links.manages_session.ids,
  userExhibits: (state: IAuthState) => state.links.exhibits.ids,
  userVisits: (state: IAuthState) => state.links.visits.ids,
  relatedVisitors: (state: IAuthState) => state.related.visitors,
  relatedEvents: (state: IAuthState) => state.related.events,
  relatedManagers: (state: IAuthState) => state.related.event_managers,
  relatedExhibitors: (state: IAuthState) => state.related.exhibitors,
  userIsAdmin: (state: IAuthState) => state.links.admins.href.length > 0,
  userRolesSet: (state: IAuthState) => state.userRolesSet,
};

const actions = {
  loginUser(context: ActionContext<IAuthState, IState>, user: IUser) {
    context.commit('setUserLoading');
    const data = {
      username: user.email ? user.email : '',
      password: user.password ? user.password : '',
      strategy: 'hc',
    };

    loginUser(data)
      .then((response) => {
        if (response.status === 200) {
          user.id = response.data.user;
          const auth = {
            token: response.data.token,
            user,
          };
          context.commit('setUserLogin', auth);
        } else {
          context.commit('setUserAuthError', 'User not found');
        }
      })
      .catch((err) => {
        if (err.response.status === 400) {
          context.commit('setUserAuthError', 'wrong-password');
        } else if (err.response.status === 409) {
          context.commit('setUserAuthError', 'no-account');
        } else if (err.response.status === 429) {
          context.commit(
            'setUserAuthError',
            'Too many login attempts. Please try again in a minute or so.',
          );
        } else {
          context.commit('setUserAuthError', err.message);
        }
      });
  },
  getRoles(context: ActionContext<IAuthState, IState>) {
    context.commit('setUserLoading');

    getRoles()
      .then((response) => {
        context.commit('setRoles', response.data);
      })
      .catch((err) => {
        if (err.response.status === 400) {
          context.commit('setUserAuthError', 'Email or password are incorrect');
        } else {
          context.commit('setUserAuthError', err.message);
        }
      });
  },
};

const mutations = {
  setUserLoading: (state: IAuthState) => {
    state.loading = true;
    state.error = false;
  },

  setUserLogin: (state: IAuthState, auth: IAuth) => {
    state.userIsLoggedIn = !!auth.token;
    state.loading = false;
    state.error = false;
    state.token = auth.token;
    state.user = auth.user;
  },

  setUserProfilePic: (state: IAuthState, profilePic: string) => {
    state.user.profile_picture = profilePic;
  },

  setRoles(state: IAuthState, v2: IV2) {
    state.userRolesSet = true;
    if (v2.links) {
      if (v2.links.admins) {
        state.links.admins = v2.links.admins;
      } else {
        state.links.admins = {
          href: '',
          ids: [],
        };
      }
      if (v2.links.manages) {
        state.links.manages = v2.links.manages;
      } else {
        state.links.manages = {
          href: '',
          ids: [],
        };
      }
      if (v2.links.exhibits) {
        state.links.exhibits = v2.links.exhibits;
      } else {
        state.links.exhibits = {
          href: '',
          ids: [],
        };
      }
      if (v2.links.visits) {
        state.links.visits = v2.links.visits;
      } else {
        state.links.visits = {
          href: '',
          ids: [],
        };
      }
      if (v2.links.manages_session) {
        state.links.manages_session = v2.links.manages_session;
      } else {
        state.links.manages_session = {
          href: '',
          ids: [],
        };
      }
    }

    state.related = {
      events: [],
      exhibitors: [],
      visitors: [],
      event_managers: [],
      session_managers: [],
    };

    if (v2.related) {
      state.related.events = v2.related.events;
      state.related.exhibitors = v2.related.exhibitors;
      state.related.visitors = v2.related.visitors;
      state.related.event_managers = v2.related.event_managers;
      state.related.session_managers = v2.related.session_managers;
    }
  },
  setUserAccessToken: (state: IAuthState, token: string) => {
    state.userIsLoggedIn = true;
    state.loading = false;
    state.error = false;
    state.token = token;
  },
  setUserAuthError: (state: IAuthState, message: string) => {
    state.message = message;
    state.error = true;
    state.userIsLoggedIn = false;
    state.loading = false;
  },

  setUserLogout: (state: IAuthState) => {
    state.links = {
      manages: {
        href: '',
        ids: [],
      },
      exhibits: {
        href: '',
        ids: [],
      },
      admins: {
        href: '',
        ids: [],
      },
      visits: {
        href: '',
        ids: [],
      },
      manages_session: {
        href: '',
        ids: [],
      },
    };
    state.related = {
      events: [],
      exhibitors: [],
      visitors: [],
      event_managers: [],
      session_managers: [],
    };
    state.token = '';
    state.userIsLoggedIn = false;
    state.userRolesSet = false;
    state.loading = false;
    state.message = '';
    state.error = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
