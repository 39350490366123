import { ActionContext } from 'vuex';
import { fetchInteractions } from '@/api/interactions';
import { IInteraction, IInteractionsExport } from '@/models/interaction';

import { IState, IObjectState, IStateObject } from '..';
import { IParams } from '@/api/index';

export interface IStateInteraction extends IInteraction, IObjectState {}

export interface IStateInteractionsExport extends IInteractionsExport, IObjectState {}

export type IInteractionsState = IStateObject<IStateInteraction>;

export type IInteractionsExportState = IStateObject<IStateInteractionsExport>;

export interface IFetchInteractionsParams extends IParams {
  exhibitor?: number;
  visitor?: number;
  mode?: string;
  favorite?: boolean;
  search?: string;
  event?: number;
}

const state: IInteractionsState = {
  list: [],
  page: 1,
  page_count: 0,
  item_count: 0,
  loading: false,
  message: '',
  error: false,
};

const addStateProps = (items: (IInteraction | IInteractionsExport)[]) =>
  items.map((item) => ({
    ...item,
    loading: false,
    success: false,
    error: false,
    message: '',
  }));

const getters = {
  interactions: (state: IInteractionsState) => state.list,
  interactionsState: (state: IInteractionsState) => state,
};

const actions = {
  fetchInteractions(
    context: ActionContext<IInteractionsState, IState>,
    params: IFetchInteractionsParams,
  ) {
    context.commit('setInteractionsLoading');
    fetchInteractions(params)
      .then((response) => {
        const interactions = {
          list: addStateProps(response.data.results),
          page: response.data.current,
          page_count: response.data.page_count,
          item_count: response.data.item_count,
        };

        context.commit('setInteractions', interactions);
      })
      .catch((err) => {
        context.commit('setInteractionsError', err.message);
      });
  },
};

const mutations = {
  setInteractions: (state: IInteractionsState, interactions: IInteractionsState) => {
    state.loading = false;
    state.list = interactions.list;
    state.page = interactions.page;
    state.page_count = interactions.page_count;
    state.item_count = interactions.item_count;
  },

  resetInteractions: (state: IInteractionsState) => {
    state.loading = false;
    state.error = false;
    state.message = '';
  },

  setInteractionsError: (state: IInteractionsState, message: string) => {
    state.loading = false;
    state.error = true;
    state.message = message;
  },

  setInteractionsLoading: (state: IInteractionsState) => {
    state.loading = true;
    state.error = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
