import {
  IVisitorOnboardingTranslations,
  IVisitorExhibitorTranslations,
  IVisitorSettingsTranslations,
  IVisitorSidebar,
  IVisitorsDashboardTranslations,
  IVisitorNetworkingTranslations,
  IVisitorExhibitorSessions,
  IVisitorSessions,
  IVisitorProfile,
  IVisitorProducts,
  IVisitorStore,
  IVisitorChatMessages,
} from '.';

const dashboard: IVisitorsDashboardTranslations = {
  home: 'Home',
  information: 'Información',
  name: 'Nombre del visitante:',
  edit: 'Editar',
  exhibitors: 'Empresas',
  nrOfInteractions: 'Número de interacciones:',
  seeAll: 'Ver todas las empresas',
  download: 'Descargar',
  networking: 'Networking',
  networking_description: 'Habla con otros visitantes',
  sessions: 'Sesiones',
  sessions_description: 'Próximas sesiones:',
  sessions_btn: 'Ver todas las sesiones',
  join_networking: 'Únete al espacio de networking',
  event_finished: 'El evento terminó',
  event_not_start: 'El evento no ha comenzado todavía',
  featured_products: 'Productos destacados',
  featured_sessions: 'Sesiones destacadas',
  featured_exhibitors: 'Empresas',
  upcoming_exhibitors: 'Próximas sesiones',
};

const sidebar: IVisitorSidebar = {
  title: 'Visitando',
  home: 'Home',
  exhibitors: 'Empresas',
  exhibitor_sessions: 'Mi agenda',
  event_sessions: 'Sesiones del evento',
  settings: 'Formulario',
  sessions: 'Agenda',
  myCalendar: 'Mi agenda',
  eventCalendar: 'Agenda del evento',
};

const store: IVisitorStore = {
  title: 'Tienda',
  subtitle: 'Añadir sesiones y/o artículos adicionales',
  add_to_cart: 'Añadir a la cesta',
  other_addons: 'Otros add-ons',
};

const settings: IVisitorSettingsTranslations = {
  title: 'Configuración del visitante',
  basicInfo: 'Información básica',
  save: 'Guardar',
  additionalInfo: 'Información adicional',
  formError: 'No hay expositores para mostrar.',
};

const exhibitors: IVisitorExhibitorTranslations = {
  title: 'Expositores',
  subtitle: 'Ver empresas',
  download: 'Descargar',
  noExhibitors: 'No hay expositores disponibles.',
  exhibitor_information: 'Información del expositor',
  contact_success: 'Solicitud de contacto exitosa!',
  all_exhibitors: 'Ver todas las empresas',
  interacted_exhibitors: 'Empresas con las que interactué',
  not_interacted_exhibitors: 'Empresas con las que no interactué',
  filter_exhibitors: 'Filtrar empresas',
  contact_request: 'Intercambiar contactos',
  company_info: 'Información de la empresa',
  job_offers: 'Productos',
  apply_to_job_offer: 'Estoy interesado',
  job_offer_info: 'Información del producto',
  cover_letter: 'Mensaje para la empresa',
  cover_letter_text:
    'Dinos por qué te interesa este producto pregúntanos cualquier duda que puedas tener',
  your_info_will_be_shared: 'Tu información de registro será compartida con la organización',
  submit_my_application: 'Mostrar interés',
  successfully_submited: 'Su solicitud fue exitosa',
  already_applied: 'Ya has interactuado con este producto/servicio',
  schedule_interview: 'Programe su entrevista',
  select_time_slot: 'Selecciona una franja horaria para programar una entrevista con nosotros',
  all_visitors: 'Todos los visitantes',
};

const onboarding: IVisitorOnboardingTranslations = {
  basicInformation: {
    title: 'Datos del visitante',
    createVisitor: 'Registrarse como visitante',
    select_type: 'Seleccione el tipo de registro',
    modal: {
      title: 'Aviso',
      subtitle: 'Si quieres gestionar tu visitante, por favor, haz login primero.',
      skip: 'Saltar',
      createAccount: 'Crear cuenta',
      login: 'Login',
    },
    already_registered: '¡Ya estás registrado en este evento!',
    already_registered_pending: '¡Ya hay una inscripción pendiente para usted en este evento!',
    already_registered_awaiting_payment:
      'Ya hay una inscripción para usted en este evento que está a la espera de que se complete el pago.',
    pre_registered_pending: '¡Ya estás preinscrito para este evento!',
    send_payment_details:
      'Haga clic aquí para recibir un correo electrónico con los detalles del pago.',
    send_login_link:
      'Haga clic aquí para recibir un correo electrónico con el link de confirmación.',
    send_rsvp_link:
      'Haga clic aquí para recibir un correo electrónico con un link para activar su inscripción.',
    no_payment_info: 'No se ha podido encontrar información sobre este pago.',
    no_pre_registration: 'No se ha podido encontrar información sobre esta preinscripción.',
    payment_info_sent:
      'Comprueba tu bandeja de entrada, ¡te hemos enviado información para completar tu pago!',
    rsvp_link_sent:
      'Comprueba tu bandeja de entrada, ¡te hemos enviado información para activar tu inscripción!',
    pre_registered_email_message:
      'La dirección de correo electrónico no puede modificarse hasta que se haya activado el registro.',
  },
  tickets: {
    title: 'Billetes',
    subtitle: 'Comprar entradas o utilizar un código de entrada',
    subtitle_store: 'Comprar entradas',
    message_head: 'Si necesita comprar entradas al por mayor y registrarse más tarde, por favor',
    to_buy_tickets: 'para comprar boletos;',
    copy_ticket_code:
      'Copia el código del ticket que te enviaremos a tu correo electrónico y regístrate con él.',
    ticket_code_invalid: 'El código de la entrada no es válido o ya se ha utilizado',
    ticket_code_label: 'Código de registro',
    promo_code_label: 'Código promocional',
    ticket_code_placeholder: 'Ingrese el código de su billete',
    promo_code_placeholder: 'Ingrese el código promocional',
    use_ticket_code: 'Utilizar un código de billete',
    code_message:
      'Utilice este campo para canjear los códigos de las entradas, los códigos de los expositores o los códigos promocionales',
    promo_code_message: 'Utilice este campo para canjear los códigos promocionales',
    promo_code_invalid: 'El código promocional no es válido o ya se ha utilizado',
    non_applicable_promo_code:
      'Su código promocional no se aplica a billetes seleccionados. Por favor elije otro.',
    promo_code_success:
      '¡Código promocional activado con éxito! <br> Por favor seleccione un billete',
  },
  addons: {
    subtitle: 'Comprar artículos adicionales',
  },
  managedFields: {
    title: 'Información adicional',
  },
  review: {
    title: 'Revisar información',
    name: 'Nombre',
    proceed_to_payment: 'Proceder al pago',
    register_someone_else: 'Registrar a otra persona',
  },
  checkout: {
    vat_label: 'IVA',
    country_label: 'País',
    country_default: 'Seleccione su país',
    city_label: 'Ciudad',
    address_label: 'Dirección',
    zip_code: 'Código postal',
    payment_method: 'Método de pago',
    payment_method_card_label: 'Tarjeta de crédito o débito',
    payment_processed: 'Pago procesado',
    billing_information: 'Información de facturación',
    check_your_phone: 'Por favor, compruebe su teléfono.',
    payment_failed: 'Pago fallido. Por favor, inténtelo de nuevo',
    payment_declined: 'El número MB WAY utilizado no tiene servicio.',
    payment_details_sent: 'Hemos enviado los detalles del pago a su correo electrónico.',
    mbref_payment_message: 'Te enviamos una referencia MB a tu correo electrónico.',
    credit_card_label: 'Tarjeta de crédito o débito',
    bank_transfer_label: 'Transferencia bancaria',
    bank_transfer_info: `
    <p>Pago por transferencia bancaria</p>
    <ul>
      <li>
       Después de enviar este formulario, verifique su correo electrónico para obtener la información de pago;
      </li>
      <li>Transfiera el pago a la cuenta que aparece en el correo electrónico;</li>
      <li>
        Envíe un comprobante de pago a la dirección de correo electrónico mencionada y incluya la ID - identificación del pago.
      </li>
    </ul>
    `,
    postal_code_error: 'Código postal con formato incorrecto',
  },
  completed: {
    title: 'Gracias por su inscripción!',
    title_store: 'Gracias por su compra!',
    subtitle:
      'Por favor, revisa tu bandeja de entrada, te hemos enviado un correo de confirmación.',
    subtitle_store:
      'Por favor, revisa tu bandeja de entrada, te hemos enviado un correo con los billetes.',
    manage: 'Gerir',
  },

  back: 'Atrás',
  next: 'Adelante',
  submit: 'Enviar',
  not_allowed_title: 'Su cuenta no ha sido aprobada',
  not_allowed_subtitle: 'Espere la aprobación del administrador del evento',
  registration_slow: 'El registro está tardando más de lo habitual. Por favor, espere.',
  order_summary: 'Resumen del pedido',
};

const networking: IVisitorNetworkingTranslations = {
  title: 'Networking',
  subtitle: 'Habla con otros visitantes',
  chat: 'Chat',
  profile: 'Perfil',
  signup_title: '¿Unirse a la conversación?',
  signup_message:
    'Estás a punto de unirte al espacio de networking. Tu información será visible para otros visitantes y ellos podrán hablar contigo.',
};

const sessions: IVisitorSessions = {
  subtitle: 'Consulte aquí su inscripción para las sesiones del evento',
  anonymous_subtitle: 'Consulte aquí e inscríbase en las sesiones del evento',
  event_sessions: 'Sesiones del evento',
  exhibitor_sessions: 'Sesiones de expositores',
  all_sessions: 'Todas las sesiones',
  join_in_15: 'Esta sesión no está en curso',
  need_help: '¿Necesitas ayuda?',
  refresh: 'Actualizar',
  troubleshoot: 'Solucionar problemas',
  help_title: 'Tienes problemas?',
  help_content: `
    <p>
    Si tienes problemas con el chat o el audio, asegúrate de que no estás usando una conexión proxy restringida o tener una VPN
    habilitada en este momento. <br />
    </p>
    <p>
      Consulte
      <a
        href="https://www.easypcmod.com/how-to-fix-jitsi-meet-no-audio-problem-11976"
        target="_blank"
        style="text-decoration: underline"
        >esta guía</a
      > para ayudar a solucionar cualquier problema no resuelto mediante la actualización de la configuración de la red.
    </p>
    <p>
     Los siguientes puertos de red deben estar abiertos en su cortafuegos para permitir el tráfico del servidor:
    </p>
    <ul>
     <li>80 TCP – para la verificación del certificado SSL con Let's Encrypt. </li>
     <li>443 TCP – para el acceso general del proveedor de videollamadas. </li>
     <li>10000 UDP - para las comunicaciones de vídeo y audio en red. </li>
     <li>TCP/4443</li>
    </ul>
  `,
};

const exhibitorSessions: IVisitorExhibitorSessions = {
  subtitle: 'Reuniones a las que fuiste invitado',
};

const profile: IVisitorProfile = {
  visitor_information: 'Información del visitante',
};

const products: IVisitorProducts = {
  title: 'Productos',
  subtitle: 'Navegar e interactuar con los productos de los expositores',
  products_same_brand: 'Offers from the same brand',
};

const chat: IVisitorChatMessages = {
  rooms_empty: 'No hay conversación',
  room_empty: 'No se ha seleccionado ninguna conversación',
  new_messages: 'Nuevos mensajes',
  message_deleted: 'Mensaje eliminado',
  messages_empty: 'Sin mensajes',
  conversation_started: 'Conversación iniciada en: ',
  type_message: 'Escribir mensaje',
  search: 'Procurar',
  is_online: 'está online',
  last_seen: 'visto por última vez',
  is_typing: 'está escribiendo...',
  cancel_select_message: 'Cancelar selección',
};

export default {
  dashboard,
  settings,
  exhibitors,
  onboarding,
  sidebar,
  networking,
  sessions,
  exhibitorSessions,
  profile,
  products,
  store,
  chat,
};
