import auth from './auth/pt';
import common from './common/pt';
import dashboard from './dashboard/pt';
import account from './account/pt';
import exhibitors from './exhibitors/pt';
import visitors from './visitors/pt';
import sessions from './sessions/pt';
import managers from './managers/pt';
import root from './root/pt';
import notifications from './notifications/pt';
import phone_input from './phone_input/pt';
import live from './live/pt';

export default {
  auth,
  common,
  sessions,
  managers,
  dashboard,
  account,
  exhibitors,
  visitors,
  root,
  notifications,
  phone_input,
  live,
};
