import {
  IManagerLegacyNavTranslations,
  IEventManagerHomeTranslations,
  IEventManagerExhibitorsTranslations,
  IEventManagerProductsTranslations,
  IEventManagerSessionsTranslations,
  IEventManagerExportsTranslations,
  IEventManagerEventsTranslations,
  IManagerTranslations,
} from '@/translations/default/managers';

const legacy_nav: Partial<IManagerLegacyNavTranslations> = {
  product_form: 'Formulário de ofertas de emprego',
  product_list: 'Lista de ofertas de emprego',
  product_categories: 'Categorias de ofertas de emprego',
};

const home: Partial<IEventManagerHomeTranslations> = {
  products_subtitle: 'Número de ofertas de emprego criadas',
};

const exhibitors: Partial<IEventManagerExhibitorsTranslations> = {
  products_limit_label: 'Limite de ofertas de emprego',
  products_limit_message: 'Número máximo de ofertas de emprego que este expositor pode criar',
};

const products: Partial<IEventManagerProductsTranslations> = {
  empty_list_form_subtitle: 'Criar o formulário de oferta de emprego',
  empty_list_categories_subtitle: 'Adicionar categorias de ofertas de emprego',
  empty_list_product_title: 'Oferta de emprego',
  empty_list_product_subtitle: 'Criar uma nova oferta de emprego',
  title: 'Ofertas de emprego disponíveis',
  subtitle: 'Número de ofertas de emprego criadas',
};

const sessions: Partial<IEventManagerSessionsTranslations> = {
  // leave empty until needed
};

const exports: Partial<IEventManagerExportsTranslations> = {
  // leave empty until needed
};

const events: Partial<IEventManagerEventsTranslations> = {
  // leave empty until needed
};

const managers: DeepPartial<IManagerTranslations> = {
  legacy_nav,
  home,
  exhibitors,
  products,
  sessions,
  exports,
  events,
};

export default managers;
