import { ActionContext } from 'vuex';
import { IRocketUserLoggedIn } from '@/models/chat';
import { IUser } from '@/models/user';

import { loginRocketUser } from '@/api/chat';
import { IState, IObjectState } from '..';

export interface IRocketAuthState extends IObjectState {
  user: IRocketUserLoggedIn | Record<string, unknown>;
}

const state: IRocketAuthState = {
  user: {},
  loading: false,
  message: '',
  error: false,
};

const getters = {
  rocketAuth: (state: IRocketAuthState) => state.user,
};

const actions = {
  loginRocketUser(context: ActionContext<IRocketAuthState, IState>, user: IUser) {
    context.commit('setRocketUserLoading');

    const data = {
      user: user.email ? user.email : '',
      password: user.password ? user.password : '',
    };
    loginRocketUser(data)
      .then((response) => {
        if (response.status === 200) {
          context.commit('setRocketUserLogin', response.data);
        } else {
          context.commit('setRocketUserAuthError', 'User not found');
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          context.commit('setRocketUserAuthError', 'Email or password are incorrect');
        } else {
          context.commit('setRocketUserAuthError', err.message);
        }
      });
  },
};

const mutations = {
  setRocketUserLoading: (state: IRocketAuthState) => {
    state.loading = true;
    state.error = false;
  },

  setRocketUserLogin: (state: IRocketAuthState, rocketLogin: IRocketUserLoggedIn) => {
    state.user = rocketLogin;
    state.loading = false;
    state.error = false;
  },
  setRocketUserAuthError: (state: IRocketAuthState, message: string) => {
    state.message = message;
    state.error = true;
    state.loading = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
