import { computed, ComputedRef } from 'vue';
import { Route } from 'vue-router';
import { IExhibitor } from '@/models/exhibitor';
import { ILiveVisitor, IVisitor, IVisitorSearchFields } from '@/models/visitor';
import { ITranslations } from '@/translations/index';
import { IEventV2, IManager } from '@/models/event';
import store from '@/store';
import router from '@/router';
import { ISession } from '../models/session';
import useAuth from './auth';
import { IPaymentInfo } from '@/models/payments';

const customEvent = process.env.VUE_APP_CUSTOM_EVENT;

export interface IUseContext {
  userExhibits: ComputedRef<number[]>;
  relatedVisitors: ComputedRef<IVisitor>;
  relatedExhibitors: ComputedRef<IExhibitor>;
  userManages: ComputedRef<number[]>;
  userManagesSession: ComputedRef<number[]>;
  translations: ComputedRef<ITranslations>;
  language: ComputedRef<string>;
  userRolesSet: ComputedRef<boolean>;
  privateModeEnabled: ComputedRef<boolean>;
  userIsExhibitor: ComputedRef<boolean>;
  userIsVisitor: ComputedRef<boolean>;
  userManager: ComputedRef<IManager>;
  userExhibitor: ComputedRef<IExhibitor>;
  userVisitor: ComputedRef<IVisitor>;
  userIsManager: ComputedRef<boolean>;
  contextManager: ComputedRef<IManager>;
  contextEvent: ComputedRef<IEventV2>;
  contextVisitor: ComputedRef<IVisitor>;
  contextExhibitor: ComputedRef<IExhibitor>;
  userIsAdmin: ComputedRef<boolean>;
  relatedEvents: ComputedRef<IEventV2[]>;
  userVisits: ComputedRef<number[]>;
  contextSession: ComputedRef<ISession>;
  contextCheckinSession: ComputedRef<ISession>;
  contextEmbedUrl: ComputedRef<IVisitor>;
  currentRoute: ComputedRef<Route>;
  eventContextRoute: ComputedRef<string>;
  contextLiveVisitor: ComputedRef<ILiveVisitor>;
  liveMenuLocked: ComputedRef<boolean>;
  contextPaymentInfo: ComputedRef<IPaymentInfo>;
  visitorSearchFields: ComputedRef<IVisitorSearchFields>;
}

export default function useContext() {
  // GETTERS

  const userRolesSet = computed(() => <boolean>store.getters.userRolesSet);
  const userIsAdmin = computed(() => <boolean>store.getters.userIsAdmin);
  const userExhibits = computed(() => store.getters.userExhibits as number[]);
  const eventLanguages = computed(() => store.getters.eventLanguages as string[]);
  const relatedVisitors = computed(() => store.getters.relatedVisitors as IVisitor[]);
  const relatedManagers = computed(() => store.getters.relatedManagers as IManager[]);
  const relatedExhibitors = computed(() => store.getters.relatedExhibitors as IExhibitor[]);
  const userManages = computed(() => store.getters.userManages as number[]);
  const userManagesSession = computed(() => store.getters.userManagesSession as number[]);
  const translations = computed(() => store.getters.translations as ITranslations);
  const language = computed(() => store.getters.language as 'en' | 'pt' | 'es' | 'fr');
  const liveMenuLocked = computed(() => <boolean>store.getters.liveMenuLocked);
  const contextEvent = computed(() => store.getters.contextEvent as IEventV2);
  const contextManager = computed(() => store.getters.contextManager as IManager);
  const contextVisitor = computed(() => store.getters.contextVisitor as IVisitor);
  const contextExhibitor = computed(() => store.getters.contextExhibitor as IExhibitor);
  const relatedEvents = computed(() => store.getters.relatedEvents as IEventV2[]);
  const userVisits = computed(() => store.getters.userVisits as number[]);
  const contextSession = computed(() => store.getters.contextSession as ISession);
  const contextCheckinSession = computed(() => store.getters.contextCheckinSession as ISession);
  const contextLiveVisitor = computed(() => store.getters.contextLiveVisitor as ILiveVisitor);
  const routeIsExhibitor = computed(() => router.currentRoute.path.includes('/manage-exhibitor'));
  const currentRoute = computed(() => router.currentRoute as Route);
  const contextPaymentInfo = computed(() => store.getters.contextPaymentInfo as IPaymentInfo);
  const contextVisitorSearchFields = computed(
    () => store.getters.contextVisitorSearchFields as IVisitorSearchFields,
  );

  const contextCurrency = computed(() => {
    switch (contextEvent.value.currency) {
      case 'usd':
        return '$';
      case 'gbp':
        return '£';
      default:
        return '€';
    }
  });

  // COMPUTED

  const userIsExhibitor = computed(
    () =>
      relatedExhibitors.value &&
      relatedExhibitors.value.some((exhibitor) => exhibitor.event === contextEvent.value.id),
  );
  const userIsVisitor = computed(
    () =>
      relatedVisitors.value &&
      relatedVisitors.value.some((visitor) => visitor.event === contextEvent.value.id),
  );
  const userManager = computed(
    () =>
      relatedManagers.value &&
      relatedManagers.value.find((manager) => manager.event === contextEvent.value.id),
  );
  const userExhibitor = computed(
    () =>
      relatedExhibitors.value &&
      relatedExhibitors.value.find((exhibitor) => exhibitor.event === contextEvent.value.id),
  );
  const userVisitor = computed(
    () =>
      relatedVisitors.value &&
      relatedVisitors.value.find((visitor) => visitor.event === contextEvent.value.id),
  );
  const userIsManager = computed(
    () =>
      userIsAdmin.value ||
      (userManages.value && userManages.value.some((event) => event === contextEvent.value.id)),
  );
  const contextEmbedUrl = computed(() => {
    let sessionUrl = contextSession.value.preview_link;

    if ((!sessionUrl || sessionUrl === '') && contextSession.value.id) {
      sessionUrl = `https://meet.beamian.com/${contextSession.value.id}`;
    }
    return sessionUrl;
  });

  const eventContextRoute = computed(() => {
    if (customEvent === '') {
      return `/${contextEvent.value.slug}`;
    } else {
      return '';
    }
  });

  const { userIsLoggedIn } = useAuth();
  const events = [587];
  const privateModeEnabled = computed(() => {
    if (events.includes(contextEvent.value.id) && !userIsLoggedIn.value) {
      return true;
    }
    return false;
  });
  return {
    privateModeEnabled,
    userManager,
    relatedManagers,
    contextManager,
    eventContextRoute,
    userExhibits,
    relatedVisitors,
    relatedExhibitors,
    userManages,
    translations,
    language,
    userIsExhibitor,
    userIsVisitor,
    userExhibitor,
    userVisitor,
    userIsManager,
    userManagesSession,
    contextEvent,
    contextVisitor,
    contextExhibitor,
    userIsAdmin,
    relatedEvents,
    userVisits,
    contextSession,
    contextCheckinSession,
    contextEmbedUrl,
    currentRoute,
    userRolesSet,
    routeIsExhibitor,
    eventLanguages,
    contextCurrency,
    contextLiveVisitor,
    liveMenuLocked,
    contextPaymentInfo,
    contextVisitorSearchFields,
  };
}
