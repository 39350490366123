import _ from 'lodash';
import default_en from '@/translations/default/en';
import default_pt from '@/translations/default/pt';
import default_es from '@/translations/default/es';
import default_de from '@/translations/default/de';
import default_fr from '@/translations/default/fr';
import jobfair_en from '@/translations/jobfair/en';
import jobfair_pt from '@/translations/jobfair/pt';
import jobfair_es from '@/translations/jobfair/es';
import jobfair_de from '@/translations/jobfair/de';
import jobfair_fr from '@/translations/jobfair/fr';

import tr_en from '@/translations/trade/en';
import tr_pt from '@/translations/trade/pt';
import tr_es from '@/translations/trade/es';
import tr_de from '@/translations/trade/de';
import tr_fr from '@/translations/trade/fr';

import vt_pt from '@/translations/voting/pt';
import vt_en from '@/translations/voting/en';

import tr2_en from '@/translations/trade-2/en';
import tr2_pt from '@/translations/trade-2/pt';
import tr2_es from '@/translations/trade-2/es';
import tr2_de from '@/translations/trade-2/de';
import tr2_fr from '@/translations/trade-2/fr';

import sc_en from '@/translations/science/en';
import sc_pt from '@/translations/science/pt';
import sc_es from '@/translations/science/es';
import sc_de from '@/translations/science/de';
import sc_fr from '@/translations/science/fr';
import store from '@/store';

import { IEventV2 } from '@/models/event';
import { AvailableLanguages } from '@/translations/index';

const defaultTranslations = {
  en: default_en,
  pt: default_pt,
  es: default_es,
  de: default_de,
  fr: default_fr,
};

const jobfairTranslations = {
  en: jobfair_en,
  pt: jobfair_pt,
  es: jobfair_es,
  de: jobfair_de,
  fr: jobfair_fr,
};

const trTranslations = {
  en: tr_en,
  pt: tr_pt,
  es: tr_es,
  de: tr_de,
  fr: tr_fr,
};

const tr2Translations = {
  en: tr2_en,
  pt: tr2_pt,
  es: tr2_es,
  de: tr2_de,
  fr: tr2_fr,
};
const scienceTranslations = {
  en: sc_en,
  pt: sc_pt,
  es: sc_es,
  de: sc_de,
  fr: sc_fr,
};

const votingTranslations = {
  pt: vt_pt,
  en: vt_en,
  es: default_es,
  de: default_de,
  fr: default_fr,
};

export interface ITranslationsState {
  language: AvailableLanguages;
  event_languages: string[];
  lang: Record<symbol, unknown>;
}

const state: ITranslationsState = {
  language: 'en',
  event_languages: [],
  lang: defaultTranslations.en,
};

const getters = {
  eventLanguages: (state: ITranslationsState) => state.event_languages,
  language: (state: ITranslationsState) => state.language,
  translations: (state: ITranslationsState) => state.lang,
};

const mutations = {
  setLanguage: (state: ITranslationsState, language: AvailableLanguages) => {
    state.language = language;
    state.lang = defaultTranslations[language];
    const contextEvent = store.getters.contextEvent as IEventV2;
    if (contextEvent && contextEvent.settings) {
      if ([470, 559, 488, 575, 487, 585, 583].includes(contextEvent.id)) {
        // Exponor custom taxonomy
        state.lang = _.merge(state.lang, tr2Translations[language]);
      } else if (contextEvent.settings.event_type === 'jf') {
        state.lang = _.merge(state.lang, jobfairTranslations[language]);
      } else if (contextEvent.settings.event_type === 'tr') {
        state.lang = _.merge(state.lang, trTranslations[language]);
      } else if (contextEvent.settings.event_type === 'sc') {
        state.lang = _.merge(state.lang, scienceTranslations[language]);
      } else if (contextEvent.settings.event_type === 'vt') {
        state.lang = _.merge(state.lang, votingTranslations[language]);
      }
    }
  },
  setEventLanguages: (state: ITranslationsState, languages: string[]) => {
    state.event_languages = languages;
  },
};

export default {
  state,
  getters,
  mutations,
};
