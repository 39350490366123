import { IPhoneInputTranslations } from '.';

const phone_input: IPhoneInputTranslations = {
  country_selector_label: 'Prefijo',
  country_selector_error: 'Elejir prefijo',
  phone_number_label: 'Teléfono',
  phone_number_error: 'Formato de número de teléfono incorrecto.',
};

export default phone_input;
