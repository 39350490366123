import Vue from 'vue';
import PortalVue from 'portal-vue';
import store from '@/store';
import App from '@/App.vue';
import router from '@/router';
import VueRouter from 'vue-router';
import './registerServiceWorker';
import VueCookies from 'vue-cookies';

// const { VUE_APP_GA_ID } = process.env;

Vue.use(VueRouter);

Vue.use(PortalVue);

Vue.use(VueCookies, { expires: '1d' });

// Vue.use(VueGtag, {
//   config: {
//     id: VUE_APP_GA_ID,
//   },
// });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
