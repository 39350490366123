import {
  ISessionDetailsTranslations,
  ISessionsAssignmentTranslations,
  ISessionsCommonTranslations,
  ISessionsTranslations,
} from '.';

const details: ISessionDetailsTranslations = {
  session_has_ended: 'Esta sesión ha terminado.',
  register_title: 'Registrarse para esta sesión',
  online_seats: 'Asientos online',
  in_person_seats: 'Asientos presenciales',
};

const inputs = {
  other_languages: 'Otros idiomas',
  session_type: 'Tipo de sesión',
  session_type_placeholder: 'Seleccione el tipo de sesión',
  privacy_label: '¿Quién puede ver esta sesión?',
  privacy_open: 'Cualquiera',
  privacy_registration: 'Requerir registro',
  privacy_closed: 'Requerir invitación directa',
  video_call_url: 'URL de videollamada',
  video_call_url_placeholder: 'Inserta la URL de la videollamada o déjala vacía',
  select_channel: 'Seleccionar canal',
  stage: 'Escenario',
  stage_placeholder: 'Seleccione una escenario',
  allow_tag_not_registered: 'Permitir tag no registrada',
};

const common: ISessionsCommonTranslations = {
  in_person: 'En persona',
  online: 'En línea',
  stream: 'Stream',
  video_call: 'Videollamada',
  in_person_registration: 'Registro En persona',
  online_registration: 'Registro Online',
  moderation_question: 'Hacer una pregunta al moderador',
  moderation_chat: 'Chatea con el moderador',
  moderation_chat_error: 'Imposible acceder al chat de moderación.',
  moderation_question_created: 'Pregunta enviada con éxito a moderador.',
  moderation_question_error: 'La pregunta no pudo ser enviada.',
};

const assignment: ISessionsAssignmentTranslations = {
  heading: 'Elija un visitante para asignarlo como gestor de sesión',
  success: 'Visitante asignado con éxito como gestor de sesión.',
  error: 'Este visitante no ha podido ser asignado como gestor de sesión.',
  empty_list: '¡Sin resultados!',
  assign: 'Añadir gestor',
  remove_from: 'Eliminar el gestor de',
  unassign_1: 'Do you wish to unassign',
  unassign_2: 'as a manager to this session?',
};

const sessions: ISessionsTranslations = {
  assignment,
  details,
  common,
  inputs,
};

export default sessions;
