import { Addon } from '@/models/addons';
import { IEventV2 } from '@/models/event';
import store from '..';

export interface ICartItem {
  quantity: number;
}

export interface ICartAddon extends ICartItem, Addon {}

export interface ICartState {
  addons: ICartAddon[];
  fees: 0;
}

const state: ICartState = {
  addons: [],
  fees: 0,
};

const lsPropName = process.env.VUE_APP_LOCAL_STORAGE_CART_PROP;

const getters = {
  cartState: (state: ICartState) => state,
  cartAddons: (state: ICartState) => {
    const contextEvent = store.getters.contextEvent as IEventV2;
    if (contextEvent.id) {
      return state.addons.filter
        ? state.addons.filter((addon) => contextEvent.id === addon.event_id)
        : [];
    }
    return state.addons;
  },
  cartPrice: (state: ICartState) => {
    const contextEvent = store.getters.contextEvent as IEventV2;

    return state.addons
      .filter((addon) => contextEvent.id === addon.event_id)
      .reduce((acc, addonType) => acc + addonType.price * (addonType.quantity || 1), 0);
  },
};

const mutations = {
  loadCart() {
    const cartStateString = localStorage.getItem(lsPropName);
    if (cartStateString) {
      const cartState = JSON.parse(cartStateString);
      state.addons = cartState.addons;
    }
  },
  addCartAddon: (state: ICartState, addon: Addon) => {
    const existingAddon = state.addons.find((existingAddon) => existingAddon.id === addon.id);

    if (existingAddon) {
      if (addon.sessions.length > 0 || addon.session_channels.length > 0) {
        store.commit('addPopup', {
          message: 'Só é possível comprar 1 item deste tipo.',
          type: 'success',
          autohide: true,
        });
      } else {
        state.addons = state.addons.map((existingAddon) => {
          if (existingAddon.id === addon.id) {
            return {
              ...existingAddon,
              quantity: existingAddon.quantity + 1,
            };
          }
          return existingAddon;
        });
      }
    } else {
      state.addons.push({ ...addon, quantity: 1 });
    }
    localStorage.setItem(lsPropName, JSON.stringify(state));
  },
  removeCartAddon: (state: ICartState, addon: Addon) => {
    const existingAddon = state.addons.find((existingAddon) => existingAddon.id === addon.id);
    if (existingAddon) {
      state.addons = state.addons
        .map((existingAddon) => {
          if (existingAddon.id === addon.id) {
            return {
              ...existingAddon,
              quantity: existingAddon.quantity - 1,
            };
          }
          return existingAddon;
        })
        .filter((existingAddon) => existingAddon.quantity !== 0);
    }
    localStorage.setItem(lsPropName, JSON.stringify(state));
  },
  clearCartAddon: (state: ICartState, addon: Addon) => {
    const existingAddon = state.addons.find((existingAddon) => existingAddon.id === addon.id);
    if (existingAddon) {
      state.addons = state.addons.filter((existingAddon) => existingAddon.id !== addon.id);
    }
    localStorage.setItem(lsPropName, JSON.stringify(state));
  },
  clearCart: (state: ICartState) => {
    state.addons = [];
    state.fees = 0;
    localStorage.setItem(lsPropName, JSON.stringify(state));
  },
};

export default {
  state,
  getters,
  // actions,
  mutations,
};
