export interface IAppState {
  notificationsOpen: boolean;
  messagesOpen: boolean;
  loginPromptOpen: boolean;
  scannerOpen: boolean;
  checkoutOpen: boolean;
  helpCenterOpen: boolean;
  notifications: number;
}

const state: IAppState = {
  notificationsOpen: false,
  helpCenterOpen: false,
  messagesOpen: false,
  checkoutOpen: false,
  loginPromptOpen: false,
  scannerOpen: false,
  notifications: 0,
};

export interface ISetChatRoom {
  rid: string;
  type: string;
  roomName: string;
}

const getters = {
  appState: (state: IAppState) => state,
};

// const actions = {
// };

const mutations = {
  openLoginPrompt: (state: IAppState) => {
    state.loginPromptOpen = true;
  },
  openScanner: (state: IAppState) => {
    state.scannerOpen = true;
  },
  openNotifications: (state: IAppState) => {
    state.notificationsOpen = true;
  },
  openMessages: (state: IAppState) => {
    state.messagesOpen = true;
  },
  openHelpCenter: (state: IAppState) => {
    state.helpCenterOpen = true;
  },
  openCheckout: (state: IAppState) => {
    state.checkoutOpen = true;
  },
  setChatRoom: () => {
    // this exist only to serve as a generic global event, to be refactored
  },
  closeNotifications: (state: IAppState) => {
    state.notificationsOpen = false;
  },
  setPopups: (state: IAppState, notifications: number) => {
    state.notifications = notifications;
  },
  addPopups: (state: IAppState, notifications: number) => {
    state.notifications += notifications;
  },
};

export default {
  state,
  getters,
  // actions,
  mutations,
};
