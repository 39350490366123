
import { defineComponent, PropType, reactive } from 'vue';
import { IDropdown } from '.';

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<IDropdown>,
      required: true,
    },
    value: String,
  },

  setup(props) {
    const state = reactive({
      isActive: false,
      openClick: true,
    });
    state.isActive = !!props.config.isActive;
    if (props.config.isActive === undefined) {
      props.config.isActive = false;
    }

    const open = () => {
      state.isActive = true;
    };

    const close = () => {
      state.openClick = false;
      if (props.config.close) {
        props.config.close();
      } else {
        if (props.config.onClose) {
          props.config.onClose();
        }

        state.isActive = false;
      }
    };

    return { open, close, state };
  },
  watch: {
    $route() {
      this.state.isActive = false;
    },
  },
});
