<template>
  <ChatWindow
    :theme="config.theme ? config.theme : theme"
    :current-user-id="config.userId"
    :room-id="config.selectedRoom"
    :rooms="loadedRooms"
    :loading-rooms="loadingRooms"
    :messages="messages"
    :messages-loaded="messagesLoaded"
    :rooms-loaded="roomsLoaded"
    :menu-actions="menuActions"
    :room-message="roomMessage"
    @fetch-more-rooms="fetchMoreRooms"
    @fetch-messages="fetchMessages"
    @send-message="sendMessage"
    @edit-message="editMessage"
    @delete-message="deleteMessage"
    @open-file="openFile"
    @add-room="addRoom"
    @menu-action-handler="menuActionHandler"
    @message-action-handler="messageActionHandler"
    @send-message-reaction="sendMessageReaction"
    @typing-message="typingMessage"
    class="vue-advanced-chat"
  />
</template>
<style lang="scss">
.vue-advanced-chat {
  .vac-icon-check {
    display: none !important;
  }
  .vac-line-new {
    display: none;
  }

  .vac-card-window {
    border-radius: $radius;
    overflow: hidden;
  }
  .vac-offset-current {
    margin-left: 0;
  }
  .vac-message-box {
    max-width: 100%;
  }
  .vac-textarea {
    background: #cecece;
    color: #000;
    caret-color: #000;
  }
}
</style>
<script>
import ChatWindow from 'vue-advanced-chat';
import 'vue-advanced-chat/dist/vue-advanced-chat.css';
import { parseTimestamp, isSameDay } from '@/utils/dates';
import store from '@/store';
// import useContext from '@/composition/context';
// import translations from '@/store/modules/translations';

export default {
  name: 'Chat',
  components: {
    ChatWindow,
  },
  props: ['config', 'messagesLoaded'],
  computed: {
    loadedRooms() {
      return this.config.rooms && this.config.rooms.slice(0, this.roomsLoadedCount);
    },
    messages() {
      return this.config.messages && this.config.messages.length > 0
        ? this.config.messages
        : undefined;
    },
    currentUser() {
      return store.getters.currentUser;
    },
  },
  // setup() {
  //   const { translations } = useContext();
  //   return { translations };
  // },
  data() {
    return {
      drag: false,
      theme: 'light',
      roomsListeners: [() => false],
      listeners: [() => false],
      roomsPerPage: 15,
      startRooms: null,
      endRooms: null,
      roomsLoaded: true,
      loadingRooms: false,
      allUsers: [],
      loadingLastMessageByRoom: 0,
      roomsLoadedCount: this.config.roomsLoadedCount,
      selectedRoom: null,
      messagesPerPage: 20,
      roomMessage: '',
      startMessages: null,
      endMessages: null,
      typingMessageCache: '',
      disableForm: false,
      addNewRoom: null,
      addRoomUsername: '',
      inviteRoomId: null,
      invitedUsername: '',
      removeRoomId: null,
      removeUserId: '',
      removeUsers: [],
      menuActions: [],
      // translated_messages: {
      //   ROOMS_EMPTY: translations.visitors.chat.rooms_empty,
      //   ROOM_EMPTY: translations.visitors.chat.room_empty,
      //   NEW_MESSAGES: translations.visitors.chat.new_messages,
      //   MESSAGE_DELETED: translations.visitors.chat.message_deleted,
      //   MESSAGES_EMPTY: translations.visitors.chat.messages_empty,
      //   CONVERSATION_STARTED: translations.visitors.chat.conversation_started,
      //   TYPE_MESSAGE: translations.visitors.chat.type_message,
      //   SEARCH: translations.visitors.chat.search,
      //   IS_ONLINE: translations.visitors.chat.is_online,
      //   LAST_SEEN: translations.visitors.chat.last_seen,
      //   IS_TYPING: translations.visitors.chat.is_typing,
      //   CANCEL_SELECT_MESSAGE: translations.visitors.chat.cancel_select_message,
      // },
    };
  },
  created() {
    // console.log(this.translated_messages);
  },
  methods: {
    fetchMoreRooms() {
      console.log();
    },

    fetchMessages(prop) {
      const rid = prop.room.roomId;
      this.$emit('selectChannel', rid);
    },

    sendMessage(message) {
      this.$emit('sendMessage', message);
    },

    editMessage(message) {
      this.$emit('editMessage', message);
    },

    deleteMessage() {
      console.log();
    },

    openFile() {
      console.log();
    },

    addRoom() {
      console.log();
    },

    menuActionHandler() {
      console.log();
    },

    messageActionHandler() {
      console.log();
    },

    sendMessageReaction() {
      console.log();
    },

    typingMessage() {
      console.log();
    },
    resetRooms() {
      this.loadingRooms = true;
      this.loadingLastMessageByRoom = 0;
      this.roomsLoadedCount = 0;
      this.rooms = [];
      this.roomsLoaded = false;
      this.startRooms = null;
      this.endRooms = null;
      this.roomsListeners.forEach((listener) => listener());
      this.roomsListeners = [];
      this.resetMessages();
    },

    resetMessages() {
      this.messages = [];
      this.messagesLoaded = false;
      this.startMessages = null;
      this.endMessages = null;
      this.listeners.forEach((listener) => listener());
      this.listeners = [];
    },

    fetchRooms() {
      this.resetRooms();
    },

    formatLastMessage(message) {
      if (!message.timestamp) return false;
      const date = new Date(message.timestamp.seconds * 1000);
      const timestampFormat = isSameDay(date, new Date()) ? 'HH:mm' : 'DD/MM/YY';
      let timestamp = parseTimestamp(message.timestamp, timestampFormat);
      if (timestampFormat === 'HH:mm') timestamp = `Today, ${timestamp}`;
      let { content } = message;

      if (message.file) {
        content = `${message.file.name}.${message.file.extension || message.file.type}`;
      }

      return {
        ...message,
        ...{
          content,
          timestamp,
          date: message.timestamp.seconds,
          seen: message.sender_id === this.currentUser.id ? message.seen : null,
          new:
            this.currentUser.id &&
            message.sender_id !== this.currentUser.id &&
            (!message.seen || !message.seen[this.currentUser.id]),
        },
      };
    },
  },
};
</script>
