import axios from 'axios';
import { IForm, IFormAnswer, IFormField, IFormQuestion } from '@/models/form';
import { Resolve, IData } from '.';
import config from './config';

const url = process.env.VUE_APP_SERVER_PATH;

export const getProductForms = (event: number | null = null) =>
  new Promise((resolve: Resolve<IData<IForm>>, reject) => {
    let requestUrl = `${url}/v2/product-forms`;

    if (event) {
      requestUrl += `?event=${event}`;
    }

    axios
      .get(`${requestUrl}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getFormFields = (form: number) =>
  new Promise((resolve: Resolve<IData<IFormField>>, reject) => {
    const requestUrl = `${url}/v2/form-fields?form=${form}`;

    axios
      .get(`${requestUrl}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getFormQuestions = (form: number) =>
  new Promise((resolve: Resolve<IData<IFormQuestion>>, reject) => {
    axios
      .get(`${url}/v2/form-questions?form=${form}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getFormAnswers = (product: number) =>
  new Promise((resolve: Resolve<IData<IFormAnswer>>, reject) => {
    axios
      .get(`${url}/v2/form-answers?form_query=${product}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const answerFormQuestion = (formAnswer: IFormAnswer) =>
  new Promise((resolve: Resolve<IData<IFormQuestion>>, reject) => {
    axios
      .post(`${url}/v2/form-answers`, formAnswer, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const updateFormQuestion = (formAnswer: IFormAnswer) =>
  new Promise((resolve: Resolve<IData<IFormQuestion>>, reject) => {
    axios
      .put(`${url}/v2/form-answers/${formAnswer.id}`, formAnswer, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const deleteFormAnswer = (formAnswer: IFormAnswer) =>
  new Promise((resolve: Resolve<IData<IFormQuestion>>, reject) => {
    axios
      .delete(`${url}/v2/form-answers/${formAnswer.id}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const updateForm = (form: Partial<IForm>) =>
  new Promise((resolve: Resolve<IForm>, reject) => {
    axios
      .put(`${url}/v2/forms/${form.id}`, form, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const deleteForm = (form: number) =>
  new Promise((resolve: Resolve<IForm>, reject) => {
    axios
      .delete(`${url}/v2/forms/${form}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
