import { IDashboardTranslations } from '.';

const dashboard: IDashboardTranslations = {
  welcome: 'Plataforma para eventos presenciales, híbridos y virtuales',
  whatsup:
    'Beamian ofrece una solución de gestión de eventos todo en uno que funciona para todos los escenarios de eventos.',
  contact_us: 'Contáctenos',
};

export default dashboard;
