import {
  IExhibitorsDashboardTranslations,
  IExhibitorTickets,
  IExhibitorExports,
  IExhibitorVisitors,
  IExhibitorSidebar,
  IExhibitorSettingsTranslations,
  IExhibitorProducsTranslations,
  IExhibitorInteractionsTranslations,
  IExhibitorOnboardingTranslations,
  IExhibitorSessions,
  IExhibitorProductInteractions,
  IExhibitorProfile,
} from '@/translations/default/exhibitors';

const dashboard: Partial<IExhibitorsDashboardTranslations> = {
  // leave empty until needed
};

const tickets: Partial<IExhibitorTickets> = {
  // leave empty until needed
};

const exports: Partial<IExhibitorExports> = {
  // leave empty until needed
};

const visitors: DeepPartial<IExhibitorVisitors> = {
  filters: {
    interacted_with_product: 'Interacted with product',
  },
};

const sidebar: Partial<IExhibitorSidebar> = {
  exhibitor_interactions: 'Spontaneous applications',
  interactions: 'Candidates',
  products: 'Offers',
  product_interactions: 'Applications for offers',
};

const settings: Partial<IExhibitorSettingsTranslations> = {
  // leave empty until needed
};

const products: Partial<IExhibitorProducsTranslations> = {
  title: 'Angebote',
  subtitle: 'Angebote erstellen, bearbeiten und löschen',
  no_products: 'Keine Angebote verfügbar',
  new_product: 'Neues Angebot',
  edit_product: 'Angebot bearbeiten',
  delete_confirm: 'Sind Sie sicher, dass Sie dieses Angebot löschen möchten?',
  interactions_exist_cannot_delete: 'Es gibt bereits Interaktionen. Kann nicht gelöscht werden.',
};

const interactions: Partial<IExhibitorInteractionsTranslations> = {
  // leave empty until needed
};

const onboarding: Partial<IExhibitorOnboardingTranslations> = {
  // leave empty until needed
};

const sessions: Partial<IExhibitorSessions> = {
  private_session_warning:
    'Erstellen Sie eine private Sitzung um bestimmte Besucher zu einem Meeting einzuladen. Wenn es eine öffentliche Sitzung ist, können alle Besucher die Sitzung einsehen und sich für sie anmelden.',
};

const productInteractions: Partial<IExhibitorProductInteractions> = {
  title: 'Besucher, die an Angeboten interessiert sind',
  subtitle: 'Die Liste der interessierten Besucher überprüfen und Meetings planen',
  product_name: 'Angebot',
};

const profile: Partial<IExhibitorProfile> = {
  products: 'Angebote',
};

export default {
  dashboard,
  settings,
  interactions,
  onboarding,
  sidebar,
  sessions,
  products,
  productInteractions,
  exports,
  visitors,
  profile,
  tickets,
};
