const activityCenter = {
  title: 'Centro de atividade',
  noRecentActivity: 'Sem atividade recente',
  inProgress: 'A gerar ficheiro',
};

const messages = {
  title: 'Mensagens',
};

const scanner = {
  title: 'Leitor de código QR',
};

const helpdesk = {
  title: 'Help desk',
};

const loginPrompt = {
  title: 'É necessário iniciar sessão',
  text: 'Por favor inicie sessão como visitante deste evento para aceder a esta funcionalidade',
};

const sessionEnded = {
  title: 'Sais-te da sessão!',
  subtitle: 'Continuar navegando?',
  backToCalendar: 'Voltar ao calendário',
  backToEvent: 'Voltar para a home',
};

const cookiesConsent = {
  title: 'Cookie consent',
  text: 'Cookies help us deliver the best experience on our website. By using our website, you agree to the use of cookies.',
};

const nfc_reader = {
  title: 'Leitor NFC',
};

const nfc_success_reading = {
  title: 'Tag NFC lida com sucesso. Por favor aguarde enquanto a validamos.',
};

const nfc_tag_not_found = {
  title: 'A tag não está vinculada a nenhum visitante do evento.',
};

const nfc_paired = {
  title: 'Leitor NFC emparelhado.',
};

const select_option_register_interactions = {
  title: 'Selecione uma opção para registar interações',
};

export default {
  activityCenter,
  messages,
  scanner,
  helpdesk,
  loginPrompt,
  sessionEnded,
  cookiesConsent,
  nfc_reader,
  nfc_success_reading,
  nfc_tag_not_found,
  nfc_paired,
  select_option_register_interactions,
};
