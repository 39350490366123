import axios from 'axios';
import { IFetchNetworkingParams } from '@/store/modules/networking';
import { Resolve, IData } from '.';
import config from './config';
import { INetworking } from '../models/networking';

const url = process.env.VUE_APP_SERVER_PATH;

export const getNetworkingVisitors = (params: IFetchNetworkingParams) =>
  new Promise((resolve: Resolve<IData<INetworking>>) => {
    let requestUrl = `${url}/v2/networking-subscriptions?page_size=${params.page_size}&page=${params.page}&event=${params.event}`;
    if (params.search) {
      requestUrl += `&search=${params.search}`;
    }
    if (params.event) {
      requestUrl += `&event=${params.event}`;
    }
    if (params.visitor) {
      requestUrl += `&visitor=${params.visitor}`;
    } else {
      requestUrl += '&notme=true';
    }
    if (params.field !== undefined) {
      requestUrl += `&field=${JSON.stringify(params.field)}`;
    }
    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });
