import { ActionContext } from 'vuex';
import { IExhibitor } from '@/models/exhibitor';

import { getExhibitors } from '@/api/exhibitors';
import { IState, IObjectState, IStateObject } from '..';
import { IParams } from '../../api/index';

/*
  In a real scenario, all these interfaces would be under the "model" folder.
*/
export interface IStateExhibitor extends IObjectState, IExhibitor {}

export type IExhibitorsState = IStateObject<IStateExhibitor>;

const state: IExhibitorsState = {
  list: [],

  page: 1,
  page_count: 0,
  item_count: 0,
  loading: false,
  message: '',
  error: false,
};

const addStateProps = (exhibitors: IExhibitor[]) =>
  exhibitors.map((exhibitor) => ({
    ...exhibitor,
    loading: false,
    error: false,
    message: '',
  }));

const getters = {
  exhibitorsState: (state: IExhibitorsState) => state,
  exhibitors: (state: IExhibitorsState) => state.list,
};

export interface IExhibitorsParams extends IParams {
  event?: number;
  name?: string;
  search?: string;
  notvisitor?: number;
  visitor?: number;
  hidden?: boolean;
  category?: number;
  status?: string;
  sponsor_level?: 'ds' | 't1' | 't2';
  home_page?: boolean;
  favorite?: boolean;
  field?: { [key: string]: string };
}

const actions = {
  fetchExhibitors(context: ActionContext<IExhibitorsState, IState>, params: IExhibitorsParams) {
    context.commit('setExhibitorsLoading');

    getExhibitors(params)
      .then((response) => {
        const exhibitors = {
          list: addStateProps(response.data.results),
          page: response.data.current,
          page_count: response.data.page_count,
          item_count: response.data.item_count,
        };

        if (params.reset) {
          context.commit('resetExhibitors');
        }

        context.commit('setExhibitors', exhibitors);
      })
      .catch((err) => {
        context.commit('setExhibitorsError', err.request);
      });
  },
};

const mutations = {
  setExhibitorsError: (state: IExhibitorsState, message: string) => {
    state.loading = false;
    state.error = true;
    state.message = message;
  },

  setExhibitorError: (
    state: IExhibitorsState,
    { failedExhibitor, message }: { failedExhibitor: IStateExhibitor; message: string },
  ) => {
    state.list = state.list.map((exhibitor) =>
      exhibitor.id === failedExhibitor.id
        ? {
            ...exhibitor,
            loading: false,
            error: true,
            message,
          }
        : exhibitor,
    );
  },

  setExhibitorsLoading: (state: IExhibitorsState) => {
    state.loading = true;
    state.error = false;
  },

  setExhibitors: (state: IExhibitorsState, exhibitors: IExhibitorsState) => {
    state.error = false;
    state.loading = false;
    state.list = [...state.list, ...exhibitors.list];

    state.page = exhibitors.page;
    state.page_count = exhibitors.page_count;
    state.item_count = exhibitors.item_count;
  },

  resetExhibitors: (state: IExhibitorsState) => {
    state.error = false;
    state.loading = false;
    state.list = [];
    state.page = 0;
    state.page_count = 0;
    state.item_count = 0;
  },

  setLoadingExhibitors: (state: IExhibitorsState, ids: number[]) => {
    state.list = state.list.map((exhibitor) => ({
      ...exhibitor,
      loading: !!(exhibitor.id && ids.indexOf(exhibitor.id) > -1),
      error: false,
    }));
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
