import {
  ISessionDetailsTranslations,
  ISessionsAssignmentTranslations,
  ISessionsCommonTranslations,
  ISessionsTranslations,
} from '.';

const details: ISessionDetailsTranslations = {
  session_has_ended: 'Esta sessão acabou.',
  register_title: 'Registe-se para esta sessão',
  online_seats: 'Lugares online',
  in_person_seats: 'Lugares presenciais',
};

const inputs = {
  other_languages: 'Outros idiomas',
  session_type: 'Tipo de sessão',
  session_type_placeholder: 'Selecione o tipo de sessão',
  privacy_label: 'Quem pode assistir a esta sessão?',
  privacy_open: 'Qualquer um',
  privacy_registration: 'Requer registo',
  privacy_closed: 'Requer convite direto',
  video_call_url: 'URL da videochamada',
  video_call_url_placeholder: 'Insira o URL da videochamada ou deixe em branco',
  select_channel: 'Selecionar canal',
  stage: 'Palco',
  stage_placeholder: 'Selecione um palco',
  allow_tag_not_registered: 'Permitir tag não registada',
};

const common: ISessionsCommonTranslations = {
  in_person: 'Presencial',
  online: 'Online',
  stream: 'Stream',
  video_call: 'Vídeo chamada',
  in_person_registration: 'Inscrição Presencial',
  online_registration: 'Inscrição Online',
  moderation_question: 'Coloque uma questão ao moderador',
  moderation_chat: 'Converse com o moderador',
  moderation_chat_error: 'Não foi possível aceder ao chat de moderação.',
  moderation_question_created: 'Questão colocada com sucesso ao moderador.',
  moderation_question_error: 'Não foi possível submeter a questão.',
};

const assignment: ISessionsAssignmentTranslations = {
  heading: 'Escolha um visitante para assinalar como gestor da sessão',
  success: 'Visitante assinalado como gestor da sessão com sucesso.',
  error: 'Não foi possível assinala este visitante como gestor da sessão.',
  empty_list: 'Sem resultados!',
  assign: 'Adicionar gestor',
  remove_from: 'Remover gestor de',
  unassign_1: 'Deseja remove',
  unassign_2: 'como gestor desta sessão?',
};

const sessions: ISessionsTranslations = {
  assignment,
  details,
  common,
  inputs,
};

export default sessions;
