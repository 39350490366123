import { IPhoneInputTranslations } from '.';

const phone_input: IPhoneInputTranslations = {
  country_selector_label: 'Länderkennzeichen',
  country_selector_error: 'Kode wählen',
  phone_number_label: 'Telefonnummer',
  phone_number_error: 'Falsches Telefonnummernformat',
};

export default phone_input;
