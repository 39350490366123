import { IAuthLoginTranslations, IAuthRegisterTranslations } from '.';

const login: IAuthLoginTranslations = {
  welcome: 'Bem vindo',
  email: 'Email',
  email_placeholder: 'Digite o seu email',
  password: 'Password',
  password_placeholder: 'Digite a sua palavra passe',
  login: 'Iniciar sessão',
  log_back: 'Voltar a iniciar sessão?',
  logged_out: 'Sessão terminada',
  or: 'ou',
  logout: 'Sair',
  other_account: 'Outra conta',
  forgot_password: 'Esqueceu-se da sua palavra passe?',
  newAccount: 'Nova conta',
  wrong_password: 'Não conseguimos fazer login com estas credênciais.',
  link_sent: 'Verifique sua caixa de entrada, enviamos um link direto para aceder o evento!',
  send_link: 'Enviar link de login',
  send_me_link: 'Envie-me um email com o link de login',
  use_password: 'Usar senha para fazer o login',
  recover_password: 'Recuperar senha',
  recover_password_link_sent:
    'Verifique sua caixa de entrada! Enviamos um link para redefinir sua senha.',
};

const register: IAuthRegisterTranslations = {
  newAccount: 'Nova conta',
  error: 'There was an error creating your user. Please try again later.',
  cancel: 'Cancel',
  signUp: 'Registar',
  welcome: 'Welcome to Beamian!',
  confirmationEmail:
    "We've sent you an email for confirming your account. Please check your inbox.",
  login: 'Iniciar sessão',
  password_not_match: 'As palavras passe não coincidem.',
};

export default { login, register };
