import { ActionContext } from 'vuex';
import { fetchProductInteractions } from '@/api/productInteractions';
import { IProductInteractionsExport } from '@/models/product';
import { IProductInteraction } from '@/models/productInteraction';

import { IParams } from '@/api/index';
import { IState, IObjectState, IStateObject } from '..';

export interface IStateProductInteractionsExport extends IProductInteractionsExport, IObjectState {}
export type IProductInteractionsExportState = IStateObject<IStateProductInteractionsExport>;
export interface IStateProductInteraction extends IProductInteraction, IObjectState {}

export type IProductInteractionsState = IStateObject<IStateProductInteraction>;

export interface IProductInteractionsParams extends IParams {
  exhibitor?: number;
  event?: number;
  mode?: string;
  search?: string;
  visitor?: number;
  product?: number;
  favorite?: boolean;
}

const state: IProductInteractionsState = {
  list: [],
  page: 1,
  item_count: 0,
  page_count: 0,
  loading: false,
  message: '',
  error: false,
};

const addStateProps = (items: (IProductInteraction | IProductInteractionsExport)[]) =>
  items.map((item) => ({
    ...item,
    loading: false,
    success: false,
    error: false,
    message: '',
  }));

const getters = {
  productInteractions: (state: IProductInteractionsState) => state.list,
  productInteractionsState: (state: IProductInteractionsState) => state,
};

const actions = {
  fetchProductInteractions(
    context: ActionContext<IProductInteractionsState, IState>,
    params: IProductInteractionsParams,
  ) {
    context.commit('setProductInteractionsLoading');
    fetchProductInteractions(params)
      .then((response) => {
        const productProductInteractions = {
          list: addStateProps(response.data.results),
          page: response.data.current,
          page_count: response.data.page_count,
          item_count: response.data.item_count,
        };
        context.commit('setProductInteractions', productProductInteractions);
      })
      .catch((err) => {
        context.commit('setProductInteractionsError', err.message);
      });
  },
};

const mutations = {
  setProductInteractions: (
    state: IProductInteractionsState,
    productProductInteractions: IProductInteractionsState,
  ) => {
    state.loading = false;
    state.list = productProductInteractions.list;
    state.page = productProductInteractions.page;
    state.page_count = productProductInteractions.page_count;
    state.item_count = productProductInteractions.item_count;
  },

  setProductInteractionsError: (state: IProductInteractionsState, message: string) => {
    state.loading = false;
    state.error = true;
    state.message = message;
  },

  setProductInteractionsLoading: (state: IProductInteractionsState) => {
    state.loading = true;
    state.error = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
