import auth from './auth/de';
import common from './common/de';
import dashboard from './dashboard/de';
import account from './account/de';
import exhibitors from './exhibitors/de';
import visitors from './visitors/de';
import sessions from './sessions/de';
import managers from './managers/de';
import root from './root/de';
import notifications from './notifications/de';
import phone_input from './phone_input/de';
import live from './live/de';

export default {
  auth,
  common,
  sessions,
  managers,
  dashboard,
  account,
  exhibitors,
  visitors,
  root,
  notifications,
  phone_input,
  live,
};
