import {
  IVisitorsDashboardTranslations,
  IVisitorSidebar,
  IVisitorSettingsTranslations,
  IVisitorExhibitorTranslations,
  IVisitorOnboardingTranslations,
  IVisitorNetworkingTranslations,
  IVisitorSessions,
  IVisitorProfile,
  IVisitorProducts,
} from '@/translations/default/visitors';

const dashboard: Partial<IVisitorsDashboardTranslations> = {
  featured_products: 'Produtos destacados',
};

const sidebar: Partial<IVisitorSidebar> = {
  // Leave empty until needed
};

const settings: Partial<IVisitorSettingsTranslations> = {
  // Leave empty until needed
};

const exhibitors: Partial<IVisitorExhibitorTranslations> = {
  contact_success: 'Partilha de contactos efetuada com sucesso!',
  contact_request: 'Partilhar contactos',
  job_offers: 'Produtos',
  apply_to_job_offer: 'Registar interesse',
  job_offer_info: 'Informação do produto',
  cover_letter: 'Pedido de informação',
  submit_my_application: 'Submeter',
  successfully_submited: 'Efetuado com sucesso',
  already_applied: 'Já efetuou este registo de interesse',
  schedule_interview: 'Reserve o seu lugar',
  select_time_slot: 'Selecione um horário para participar',
};

const onboarding: DeepPartial<IVisitorOnboardingTranslations> = {
  // Leave empty until needed
};

const networking: Partial<IVisitorNetworkingTranslations> = {
  // Leave empty until needed
};

const sessions: Partial<IVisitorSessions> = {
  // Leave empty until needed
};

const profile: Partial<IVisitorProfile> = {
  // Leave empty until needed
};

const products: IVisitorProducts = {
  title: 'Produtos',
  subtitle: 'Procurar e interagir com produtos',
  products_same_brand: 'Produtos do mesmo expositor',
};

export default {
  dashboard,
  settings,
  exhibitors,
  onboarding,
  sidebar,
  networking,
  sessions,
  profile,
  products,
};
