import { ICommonTranslations } from '@/translations/default/common';

const common: Partial<ICommonTranslations> = {
  exhibitors: 'Empresas',
  exhibitor: 'Empresa',
  contact_request: 'Solicitud espontánea',
  product_interactions: 'Solicitud de empleo',
  session_privacy: 'Privacidad en sesiones',
  all: 'Todos',
  private: 'Privado',
  public: 'Público',
  filter: 'Filtros',
  my_addons: 'Mis add-ons/Extras',
  see_addons_bought: 'Ver los add-ons/extras que has comprado',
  form: 'Formulario',
};

export default common;
