import { ActionContext } from 'vuex';
import { IParams } from '@/api';
import { getNetworkingVisitors } from '@/api/networking';

import { INetworking } from '@/models/networking';
import { IState, IObjectState, IStateObject } from '..';

/*
  In a real scenario, all these interfaces would be under the "model" folder.
*/
export interface IStateNetworking extends IObjectState, INetworking {}

export type INetworkingState = IStateObject<IStateNetworking>;

const state: INetworkingState = {
  list: [],
  page: 1,
  page_count: 0,
  item_count: 0,
  loading: false,
  message: '',
  error: false,
};

const addStateProps = (networking: INetworking[]) =>
  networking.map((visitor) => ({
    ...visitor,
    loading: false,
    error: false,
    message: '',
  }));

const getters = {
  networking: (state: INetworkingState) => state.list,
  networkingState: (state: INetworkingState) => state,
};

export interface IFetchNetworkingParams extends IParams {
  event: number;
  search?: string;
  field?: { [key: string]: string };
  visitor?: number;
  reset?: boolean;
}

const actions = {
  fetchNetworking(
    context: ActionContext<INetworkingState, IState>,
    params: IFetchNetworkingParams,
  ) {
    context.commit('setNetworkingLoading');
    if (context.state.page) {
      getNetworkingVisitors(params)
        .then((response) => {
          const networking = {
            list: addStateProps(response.data.results),
            page: response.data.current,
            page_count: response.data.page_count,
            item_count: response.data.item_count,
          };
          if (params.reset) {
            context.commit('resetNetworking');
          }
          context.commit('setNetworking', networking);
        })
        .catch((err) => {
          context.commit('setNetworkingError', err.request);
        });
    }
  },
};

const mutations = {
  setNetworkingError: (state: INetworkingState, message: string) => {
    state.loading = false;
    state.error = true;
    state.message = message;
  },

  setVisitorError: (
    state: INetworkingState,
    { failedVisitor, message }: { failedVisitor: IStateNetworking; message: string },
  ) => {
    state.list = state.list.map((visitor) =>
      visitor.id === failedVisitor.id
        ? {
            ...visitor,
            loading: false,
            error: true,
            message,
          }
        : visitor,
    );
  },
  setNetworkingLoading: (state: INetworkingState) => {
    state.loading = true;
    state.error = false;
  },

  setNetworking: (state: INetworkingState, networking: INetworkingState) => {
    state.error = false;
    state.loading = false;
    state.list = [...state.list, ...networking.list];
    state.page = networking.page;
    state.page_count = networking.page_count;
    state.item_count = networking.item_count;
  },

  resetNetworking: (state: INetworkingState) => {
    state.error = false;
    state.loading = false;
    state.list = [];
    state.page = 0;
    state.page_count = 0;
    state.item_count = 0;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
