import { INotificationsTranslations } from '.';

const notifications: INotificationsTranslations = {
  exhibitor_invite_title: 'You have been invited to a session with exhibitor ',
  product_interaction_title: ' interacted with ',
  interaction_title: ' shared their contact with you.',
  moderation_question: ' has posted a question for you to moderate.',
};

export default notifications;
