
import { MutationPayload } from 'vuex';
import Offcanvas from '@/components/offcanvas.vue';
import store from '@/store';
import { IAppState } from '@/store/modules/app';
import { ITranslations } from '@/translations';
import { IVisitor } from '@/models/visitor';
import { INotification } from '@/models/notifications';
import { computed, defineComponent } from 'vue';
import { onlineSessionRegistrationUpdate, sessionRegistrationUpdate } from '@/api/sessions';
import { IRocketRoom } from '@/models/chat';
import router from '@/router';

const customEvent = process.env.VUE_APP_CUSTOM_EVENT;

export default defineComponent({
  components: { Offcanvas },

  data() {
    return {
      notificationsOffcanvas: {
        isActive: false,
      },
      interval: 0,
      event: 0,
      exhibitor: 0,
      acceptLoading: [false],
      rejectLoading: [false],
    };
  },
  setup() {
    const translations = computed(() => store.getters.translations as ITranslations);
    const contextVisitor = computed(() => store.getters.contextVisitor as IVisitor);
    const userIsLoggedIn = computed(() => store.getters.userIsLoggedIn as boolean);
    const contextEvent = computed(() => store.getters.contextEvent);
    const notifications = computed(() => store.getters.notifications as INotification[]);
    const appState = computed(() => store.getters.appState as IAppState);

    const invitations = computed(() =>
      notifications.value.filter(
        (notification) =>
          notification.notification_type.includes('private_exhibitor_session_invitation') ||
          notification.notification_type.includes('private_exhibitor_online_session_invitation'),
      ),
    );

    const requests = computed(() =>
      notifications.value.filter((notification) =>
        notification.notification_type.includes('contact_request'),
      ),
    );

    const productInteractions = computed(() =>
      notifications.value.filter((notification) =>
        notification.notification_type.includes('product_interaction'),
      ),
    );

    const sessionModerationQuestions = computed(() =>
      notifications.value.filter((notification) =>
        notification.notification_type.includes('session_moderation_question'),
      ),
    );

    const eventContextRoute = computed(() => {
      if (customEvent === '') {
        return `/${contextEvent.value.slug}`;
      } else {
        return '';
      }
    });

    return {
      eventContextRoute,
      translations,
      contextVisitor,
      userIsLoggedIn,
      notifications,
      appState,
      contextEvent,
      invitations,
      requests,
      productInteractions,
      sessionModerationQuestions,
    };
  },
  created() {
    const contextEvent = store.getters.contextEvent;
    this.event = contextEvent.id;
    this.exhibitor = parseInt(this.$route.params.exhibitor, 10);
    if (this.userIsLoggedIn) {
      store.dispatch('fetchNotifications', { event: this.event });
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        store.dispatch('fetchNotifications', { event: this.event });
      }, 120000);
    }
    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setUserLogin') {
        store.dispatch('fetchNotifications', { event: this.event });

        clearInterval(this.interval);

        this.interval = setInterval(() => {
          store.dispatch('fetchNotifications', { event: this.event });
        }, 120000);
      }
      if (mutation.type === 'openNotifications') {
        this.notificationsOffcanvas = {
          isActive: this.appState.notificationsOpen,
        };
      }
      if (mutation.type === 'closeNotifications') {
        this.notificationsOffcanvas = {
          isActive: false,
        };
      }
    });
    if (this.$route.query.s) {
      // TODO remove timeout
      // Wait until component is loaded
      setTimeout(() => {
        this.notificationsOffcanvas.isActive = true;
      }, 1000);
    }
  },
  methods: {
    openSession(notification: INotification) {
      router.push(`${this.eventContextRoute}/session-details/${notification.session_source}`);
    },

    inviteInteraction(notification: INotification) {
      router.push(
        `${this.eventContextRoute}/manage-exhibitor/${notification.receiver_exhibitor}/visitor/${notification.sender_id}/invite/`,
      );
      store.dispatch('updateNotification', {
        id: notification.id,
        status: 'seen',
      });
    },

    dismissNotification(notification: INotification) {
      store.dispatch('deleteNotification', notification);
    },

    inviteProductInteraction(notification: INotification) {
      router.push(
        `${this.eventContextRoute}/manage-exhibitor/${notification.receiver_exhibitor}/visitor/${notification.sender_id}/invite/`,
      );
      store.dispatch('updateNotification', {
        id: notification.id,
        status: 'seen',
      });
    },

    manageInteraction(notification: INotification) {
      router.push(
        `${this.eventContextRoute}/manage-exhibitor/${notification.receiver_exhibitor}/visitors`,
      );
      store.dispatch('updateNotification', {
        id: notification.id,
        status: 'seen',
      });
    },

    manageProductInteraction(notification: INotification) {
      router.push(
        `${this.eventContextRoute}/manage-exhibitor/${notification.receiver_exhibitor}/visitors`,
      );
      store.dispatch('updateNotification', {
        id: notification.id,
        status: 'seen',
      });
    },

    openChat(room: IRocketRoom) {
      store.commit('setChatRoom', {
        rid: room.rid,
        type: room.t === 'c' ? 'c' : 'im',
        roomName: `${room.fname}`,
      });
      store.commit('openMessages');
      this.notificationsOffcanvas.isActive = false;
    },

    async acceptInvitation(notification: INotification, index: number) {
      this.acceptLoading[index] = true;
      this.$forceUpdate();
      if (notification.notification_type === 'private_exhibitor_online_session_invitation') {
        onlineSessionRegistrationUpdate({
          id: notification.source_id,
          status: 6,
        })
          .then(() => {
            this.acceptLoading[index] = false;
          })
          .catch((err) => {
            const errors = err.response.data;
            if (errors && errors.length > 0) {
              errors.forEach((error: string) => {
                store.commit('addPopup', {
                  message: error,
                  type: 'danger',
                  autohide: true,
                });
              });
            } else {
              store.commit('addPopup', {
                message: err.nessage,
                type: 'danger',
                autohide: true,
              });
            }
            this.acceptLoading[index] = false;
            this.$forceUpdate();
          });

        store.dispatch('updateNotification', {
          id: notification.id,
          status: 'seen',
        });
        this.$forceUpdate();
      } else {
        sessionRegistrationUpdate({
          id: notification.source_id,
          status: 6,
        })
          .then(() => {
            this.acceptLoading[index] = false;
          })
          .catch((err) => {
            const errors = err.response.data;
            if (errors && errors.length > 0) {
              this.acceptLoading[index] = false;

              errors.forEach((error: string) => {
                store.commit('addPopup', {
                  message: error,
                  type: 'danger',
                  autohide: true,
                });
              });
            } else {
              store.commit('addPopup', {
                message: err.nessage,
                type: 'danger',
                autohide: true,
              });
            }
            this.acceptLoading[index] = false;
            this.$forceUpdate();
          });

        store.dispatch('updateNotification', {
          id: notification.id,
          status: 'seen',
        });
        this.$forceUpdate();
      }

      return true;
    },

    async rejectInvitation(notification: INotification, index: number) {
      this.rejectLoading[index] = true;
      this.$forceUpdate();
      if (notification.notification_type === 'private_exhibitor_online_session_invitation') {
        await onlineSessionRegistrationUpdate({
          id: notification.source_id,
          status: 7,
        }).catch((err) => {
          const errors = err.response.data;
          if (errors && errors.length > 0) {
            errors.forEach((error: string) => {
              store.commit('addPopup', {
                message: error,
                type: 'danger',
                autohide: true,
              });
            });
          } else {
            store.commit('addPopup', {
              message: err.nessage,
              type: 'danger',
              autohide: true,
            });
          }
          this.rejectLoading[index] = false;
          this.$forceUpdate();
        });
      } else {
        await onlineSessionRegistrationUpdate({
          id: notification.source_id,
          status: 7,
        }).catch((err) => {
          const errors = err.response.data;
          if (errors && errors.length > 0) {
            errors.forEach((error: string) => {
              store.commit('addPopup', {
                message: error,
                type: 'danger',
                autohide: true,
              });
            });
          } else {
            store.commit('addPopup', {
              message: err.nessage,
              type: 'danger',
              autohide: true,
            });
          }
          this.rejectLoading[index] = false;
          this.$forceUpdate();
        });
      }
      store.dispatch('updateNotification', {
        id: notification.id,
        status: 'seen',
      });
      this.rejectLoading[index] = false;
      this.$forceUpdate();

      return true;
    },
  },
});
