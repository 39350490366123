import axios from 'axios';
import { IProductInteraction } from '@/models/productInteraction';

import { IProductInteractionsExport } from '@/models/product';
import {
  IProductInteractionsParams,
  IStateProductInteractionsExport,
} from '@/store/modules/productInteractions';
import { Resolve, IData } from '.';
import config from './config';

const url = process.env.VUE_APP_SERVER_PATH;

// export const fetchProductInteractions = (eventId: number) => new Promise((resolve: Resolve<IProductInteraction[]>, reject) => {
//   axios
//     .get(`${url}/organizers/event/${eventId}/productInteractions`, config())
//     .then(response => {
//       resolve(response);
//     })
//     .catch(err => {
//       reject(err);
//     });
// });

export const editProductInteraction = (interaction: IProductInteraction) =>
  new Promise((resolve: Resolve<IData<IProductInteraction>>, reject) => {
    axios
      .patch(`${url}/v2/product-interactions/${interaction.id}`, interaction, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const fetchProductInteractions = (params: IProductInteractionsParams) =>
  new Promise((resolve: Resolve<IData<IProductInteraction>>, reject) => {
    if (!params.page_size) {
      params.page_size = 100;
    }
    if (!params.page) {
      params.page = 1;
    }
    if (!params.ordering) {
      params.ordering = '-id';
    }
    let requestUrl = `${url}/v2/product-interactions?page_size=${params.page_size}&page=${params.page}`;

    if (params.exhibitor) {
      requestUrl += `&exhibitor=${params.exhibitor}`;
    }
    if (params.ordering) {
      requestUrl += `&ordering=${params.ordering}`;
    }
    if (params.mode !== undefined) {
      requestUrl += `&mode=${params.mode}`;
    }
    if (params.search) {
      requestUrl += `&search=${params.search}`;
    }
    if (params.event) {
      requestUrl += `&event=${params.event}`;
    }
    if (params.product) {
      requestUrl += `&product=${params.product}`;
    }
    if (params.favorite) {
      requestUrl += `&favorite=${params.favorite}`;
    }

    if (params.visitor !== undefined) {
      requestUrl += `&visitor=${params.visitor}`;
    }

    if (params.product !== undefined) {
      requestUrl += `&product=${params.product}`;
    }

    axios
      .get(`${requestUrl}`, config(false))
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const fetchProductInteractionById = (productInteractionId: number) =>
  new Promise((resolve: Resolve<IProductInteraction>, reject) => {
    const requestUrl = `${url}/v2/product-interactions/${productInteractionId}`;

    axios
      .get(`${requestUrl}`, config(false))
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const addProductInteractionsExport = (productsExport: IProductInteractionsExport) =>
  new Promise((resolve: Resolve<IProductInteractionsExport>, reject) => {
    axios
      .post(
        `${url}/v2/product-interaction-exports?exhibitor=${productsExport.exhibitor}`,
        {
          filename: productsExport.filename,
          exhibitor: productsExport.exhibitor,
          page_size: 1,
          building: true,
        },
        config(false),
      )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const editProductInteractionsExport = (productsExport: IProductInteractionsExport) =>
  new Promise((resolve: Resolve<IProductInteractionsExport>, reject) => {
    axios
      .patch(`${url}/v2/product-interaction-exports/${productsExport.id}`, productsExport, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const fetchProductInteractionsExports = (exhibitor: number) =>
  new Promise((resolve: Resolve<IData<IProductInteractionsExport>>, reject) => {
    axios
      .get(`${url}/v2/product-interaction-exports?exhibitor=${exhibitor}&ordering=-id`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const deleteProductInteractionsExport = (productsExport: IStateProductInteractionsExport) =>
  new Promise((resolve: Resolve<IData<IProductInteractionsExport>>, reject) => {
    axios
      .delete(`${url}/v2/product-interaction-exports/${productsExport.id}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
