import { ICommonTranslations } from '@/translations/default/common';

const common: Partial<ICommonTranslations> = {
  events: 'Eventos',
  exhibitors: 'Expositores',
  exhibitor: 'Expositor',
  edit: 'Editar',
  create: 'Criar',
  delete: 'Apagar',
  remove: 'Remover',
  name: 'Nome',
  name_placeholder: 'Digite o seu nome',
  email: 'Email',
  invalid_email: 'Este e-mail é inválido',
  description: 'Descrição',
  category: 'Categoria',
  model: 'Modelo',
  save: 'Guardar',
  download: 'Descarregar',
  upload: 'Upload',
  change: 'Mudar',
  apply: 'Guardar',
  empty_field_not_allowed: 'Este campo não pode estar vazio',
  next: 'Seguinte',
  previous: 'Anterior',
  close: 'Fechar',
  cancel: 'Cancelar',
  confirm: 'Confirmar',
  search: 'Procurar',
  mandatory_field: 'Este campo é obrigatório.',
  name_in_use: 'Este nome já está a ser usado.',
  crop: 'Cortar',
  published: 'Publicado',
  not_published: 'Não publicados',
  all: 'Todos',
  empty_list: 'Sem dados para mostrar',
  user_chat_failed: 'Este utilizador ainda não está online',
  activity_center: 'Centro de atividade',
  no_recent_activity: 'Sem atividade recente',
  unread_messages: 'Mensagens por ler',
  reply: 'Responder',
  session_invitations: 'Convites por responder',
  accept: 'Aceitar',
  refuse: 'Recusar',
  in_progress: 'Em progresso',
  recent_files: 'Ficheiros recentes',
  open_conversations: 'Conversas em aberto',
  all_fields_required: 'Todos os campos são necessários.',
  something_went_wrong: 'Aconteceu algo de errado',
  already_exists: 'Já existe',
  profile: 'A minha conta',
  filter_by_status: 'Filtrar por estado',
  open_meeting: 'Entrar na reunião',
  visitors: 'Visitantes',
  saved: 'Guardado',
  back: 'Voltar',
  session_full: 'Cheia',
  file_not_image: 'Este formato não é suportado',
  new_export: 'Novo exporte',
  state: 'Estado',
  details: 'Detalhes',
  see_more: 'Ver mais',
  manage: 'Gerir',
  manage_event: 'Gerir evento',
  see_as_visitor: 'Ver como visitante',
  see_as_exhibitor: 'Ver como expositor',
  manage_visitors: 'Gerir visitantes',
  manage_exhibitors: 'Gerir expositores',
  registered: 'registado',
  refused: 'recusado',
  pending: 'pendente',
  accepted: 'aceite',
  favorites: 'Favoritos',
  add_to_favorites: 'Adicionar aos favoritos',
  remove_from_favorites: 'Remover dos favoritos',
  contact_request: 'Pedido de contacto',
  invited: 'Convidado/a',
  note: 'Nota',
  discard: 'Descartar',
  no_comment: 'Sem comentário',
  click_here: 'Clique aqui',
  sessions: 'Sessões',
  event_sessions: 'Sessões de eventos',
  exhibitor_sessions: 'Sessões de expositores',
  networking: 'Rede',
  total: 'Total',
  update_success: 'Informação atualizada com sucesso',
  agree_to_terms:
    'Aceito os <a href="https://beamian.com/terms/" target="_blank"><b>termos e condições da beamian</b></a>',
  add_to_calendar: 'Adicione ao seu calendário',
  event_already_started: 'Este evento já começou',
  countdown: 'Contagem decrescente',
  please_check_answers: 'Por favor, verifique as suas respostas',
  sort_by: 'Ordenar por',
  order: 'Ordem',
  date: 'Data',
  ascending: 'Ascendente',
  descending: 'Descendente',
  no_data_title: 'Nada para ver aqui!',
  no_data_subtitle: 'Por favor, volte mais tarde.',
  exhibitor_interactions: 'Interações com o expositor',
  interactions: 'Interações',
  product_interactions: 'Interações com ofertas',
  sessions_subtitle: 'Últimos dados sobre sessões e estatísticas',
  online_registrations: 'Inscrições para acesso online',
  in_person_registrations: 'Inscrições para acesso presencial',
  contacts: 'Contactos',
  created: 'Criados',
  seeing_as_visitor: 'A ver evento como visitante',
  total_sessions: 'Total de sessões',
};

export default common;
