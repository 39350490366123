import auth from './auth/es';
import common from './common/es';
import dashboard from './dashboard/es';
import account from './account/es';
import exhibitors from './exhibitors/es';
import visitors from './visitors/es';
import sessions from './sessions/es';
import managers from './managers/es';
import root from './root/es';
import notifications from './notifications/es';
import phone_input from './phone_input/es';
import live from './live/es';

export default {
  auth,
  common,
  sessions,
  managers,
  dashboard,
  account,
  exhibitors,
  visitors,
  root,
  notifications,
  phone_input,
  live,
};
