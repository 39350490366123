import { IPhoneInputTranslations } from '.';

const phone_input: IPhoneInputTranslations = {
  country_selector_label: 'Code pays',
  country_selector_error: 'Choisir un pays',
  phone_number_label: 'Numéro de téléphone',
  phone_number_error: 'Format du numéro de téléphone incorrect.',
};

export default phone_input;
