import { INotificationsTranslations } from '.';

const notifications: INotificationsTranslations = {
  exhibitor_invite_title: 'Sie sind zu einer Sitzung mit einem Aussteller eingeladen worden ',
  product_interaction_title: ' interagiert mit ',
  interaction_title: ' hat ihren/seinen Kontakt mit Ihnen geteilt.',
  moderation_question: ' hat eine Frage zur Moderation eingereicht.',
};

export default notifications;
