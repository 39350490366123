import {
  IExhibitorsDashboardTranslations,
  IExhibitorTickets,
  IExhibitorExports,
  IExhibitorVisitors,
  IExhibitorSidebar,
  IExhibitorSettingsTranslations,
  IExhibitorProducsTranslations,
  IExhibitorInteractionsTranslations,
  IExhibitorOnboardingTranslations,
  IExhibitorSessions,
  IExhibitorProductInteractions,
  IExhibitorProfile,
} from '@/translations/default/exhibitors';

const dashboard: Partial<IExhibitorsDashboardTranslations> = {
  // leave empty until needed
};

const tickets: Partial<IExhibitorTickets> = {
  // leave empty until needed
};

const exports: Partial<IExhibitorExports> = {
  // leave empty until needed
};

const visitors: DeepPartial<IExhibitorVisitors> = {
  filters: {
    interacted_with_product: 'Interacted with product',
  },
};

const sidebar: Partial<IExhibitorSidebar> = {
  products: 'Products',
  product_interactions: 'Interested in products',
};

const settings: Partial<IExhibitorSettingsTranslations> = {
  // leave empty until needed
};

const products: Partial<IExhibitorProducsTranslations> = {
  title: 'Products',
  subtitle: 'Create, edit and delete products',
  no_products: 'No products available',
  new_product: 'New product',
  edit_product: 'Edit product',
  delete_confirm: 'Are you sure you want to delete this product?',
  interactions_exist_cannot_delete: 'Already has interactions. Cannot be deleted.',
};

const interactions: Partial<IExhibitorInteractionsTranslations> = {
  // leave empty until needed
};

const onboarding: Partial<IExhibitorOnboardingTranslations> = {
  // leave empty until needed
};

const sessions: Partial<IExhibitorSessions> = {
  private_session_warning:
    "Create a private session to invite specific visitors to a meeting. If it's a public session, any visitor will be able to see and register for that session.",
};

const productInteractions: Partial<IExhibitorProductInteractions> = {
  title: 'Visitors interested in products',
  subtitle: 'Check the list of visitors interested in your products and schedule meetings',
  product_name: 'Product',
};

const profile: Partial<IExhibitorProfile> = {
  products: 'Products',
};

export default {
  dashboard,
  settings,
  interactions,
  onboarding,
  sidebar,
  sessions,
  products,
  productInteractions,
  exports,
  visitors,
  profile,
  tickets,
};
