import {
  IManagerLegacyNavTranslations,
  IEventManagerHomeTranslations,
  IEventManagerExhibitorsTranslations,
  IEventManagerProductsTranslations,
  IEventManagerSessionsTranslations,
  IEventManagerExportsTranslations,
  IEventManagerEventsTranslations,
  IManagerTranslations,
} from '@/translations/default/managers';

const legacy_nav: Partial<IManagerLegacyNavTranslations> = {
  product_form: 'Formulario de producto',
  product_list: 'Listados de productos',
  product_categories: 'Categorías de productos',
};

const home: Partial<IEventManagerHomeTranslations> = {
  products_subtitle: 'Número de productos creados',
};

const exhibitors: Partial<IEventManagerExhibitorsTranslations> = {
  products_limit_label: 'Límite de trabajo',
  products_limit_message: 'Número máximo de trabajos que puede crear este expositor',
};

const products: Partial<IEventManagerProductsTranslations> = {
  empty_list_form_subtitle: 'Crear el formulario de produto',
  empty_list_categories_subtitle: 'Añadir categorías de producto',
  empty_list_product_title: 'Productos',
  empty_list_product_subtitle: 'Crear un nuevo producto',
  title: 'Productos disponibles',
  subtitle: 'Número de productos creados',
};

const sessions: Partial<IEventManagerSessionsTranslations> = {
  // leave empty until needed
};

const exports: Partial<IEventManagerExportsTranslations> = {
  // leave empty until needed
};

const events: Partial<IEventManagerEventsTranslations> = {
  // leave empty until needed
};

const managers: DeepPartial<IManagerTranslations> = {
  legacy_nav,
  home,
  exhibitors,
  products,
  sessions,
  exports,
  events,
};

export default managers;
