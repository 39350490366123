import { ICommonTranslations } from '.';

const common: ICommonTranslations = {
  events: 'Événements',
  exhibitors: 'Exposants',
  exhibitor: 'Exposant',
  edit: 'Éditer',
  add_spacer: 'Ajouter un onglet',
  add_stage: 'Add Stage',
  stages_message: 'These will be available in your session forms',
  edit_checkin: 'Éditer check-in',
  create: 'Créer',
  delete: 'Supprimer',
  remove: 'Retirer',
  name: 'Nom',
  first_last_name: 'Prénom et nom',
  name_placeholder: 'Entrer votre nom',
  email: 'Adresse électronique',
  invalid_email: "Cet e-mail n'est pas valide",
  email_already_associated: 'Le mail saisi est déjà associé à un autre abonnement',
  description: 'Description',
  category: 'Catégorie',
  categories: 'Catégories',
  model: 'Modèle',
  save: 'Sauvegarder',
  download: 'Télécharger',
  upload: 'Télécharger',
  change: 'Modifier',
  apply: 'Appliquer',
  empty_field_not_allowed: 'Ce champ doit être rempli',
  next: 'Suivant',
  previous: 'Précédent',
  close: 'Fermer',
  cancel: 'Annuler',
  confirm: 'Confirmer',
  items: 'articles',
  search: 'Rechercher',
  mandatory_field: 'Ce champ est obligatoire.',
  name_in_use: 'Nom déjà utilisé.',
  crop: 'Recadrer',
  published: 'Publié',
  not_published: 'Non publié',
  all: 'Tout',
  empty_list: 'Aucune donnée disponible',
  user_chat_failed: "Impossible d'ouvrir le chat avec cet utilisateur",
  activity_center: "Centre d'activités",
  no_recent_activity: 'Aucune activité récente',
  unread_messages: 'Messages non lus',
  reply: 'Répondre',
  session_invitations: 'Invitations en attente',
  accept: 'Accepter',
  refuse: 'Refuser',
  in_progress: 'En cours',
  recent_files: 'Fichiers récents',
  open_conversations: 'Ouvrir des conversations',
  all_fields_required: 'Tous les champs sont requis',
  something_went_wrong: 'Un problème est survenu',
  already_exists: 'Existe déjà',
  profile: 'Mon compte',
  filter_by_status: 'Filtrer par statut',
  open_meeting: 'Ouvrir une réunion',
  visitors: 'Visiteurs',
  saved: 'Enregistré',
  back: 'Retour',
  session_full: 'La séance est complète',
  file_not_image: "Ce fichier n'est pas une image",
  new_export: 'Nouvelle exportation',
  state: 'État',
  details: 'Détails',
  see_more: 'En savoir plus',
  more: 'Plus',
  manage: 'Gérer',
  see_as_visitor: 'Voir en tant que visiteur',
  see_as_exhibitor: "Voir en tant qu'exposant",
  manage_visitors: 'Gérer les visiteurs',
  manage_exhibitors: 'Gérer les exposants',
  manage_event: 'Gérer un événement',
  registered: 'inscrit',
  refused: 'refusé',
  pending: 'en attente',
  session_manager: 'Gestionnaire de session',
  export_questions: 'Exporter des questions',
  my_mod_questions: 'Dernières questions',
  mod_questions: 'Questions pour la modération',
  pending_plural: 'En attente',
  read: 'Lus',
  mark_as_read: 'Marquer comme lu',
  accepted: 'accepté',
  add_to_favorites: 'Ajouter aux favoris',
  remove_from_favorites: 'Supprimer des favoris',
  contact_request: 'Demande de contact',
  invited: 'Invité',
  note: 'Note',
  discard: 'Descarte',
  no_comment: 'Sin comentarios',
  click_here: 'Haga clic aquí',
  sessions: 'Séances',
  event_sessions: "Sessions d'événement",
  exhibitor_sessions: 'Séances exposants',
  networking: 'Réseautage',
  total: 'Total',
  update_success: 'Informations mises à jour avec succès',
  favorites: 'Favoris',
  agree_to_terms:
    'Accepter les <a href="https://innovatorsforum.fyre.website/wp-content/uploads/2024/07/Terms-and-Conditions.pdf" target="_blank"><b>termes et conditions de Innovators Fórum\' 24: Circularity</b></a>',
  add_to_calendar: 'Ajouter à votre calendrier',
  event_already_started: 'Cet événement a déjà commencé',
  countdown: 'Compte à rebours',
  please_check_answers: 'Veuillez vérifier vos réponses',
  sort_by: 'Trier par',
  order: 'Ordre',
  date: 'Date',
  ascending: 'Ascendant',
  descending: 'Descendant',
  no_data_title: 'Rien à voir ici!',
  no_data_subtitle: 'Revenez plus tard.',
  exhibitor_interactions: 'Interactions avec les exposants',
  interactions: 'Interactions',
  product_interactions: 'Interactions avec les produits',
  sessions_subtitle: 'Dernières données sur les numéros de session et les statistiques',
  online_registrations: 'Inscriptions en ligne',
  in_person_registrations: 'Inscriptions en personne',
  contacts: 'Contacts',
  created: 'Créé',
  seeing_as_visitor: 'Ver evento como visitante',
  total_sessions: 'Total de sessões',
  ticket_types: 'Ticket type',
  image: 'Image',
  add: 'Ajouter',
  tickets: 'Bilhetes',
  sold: 'Vendido',
  price: 'Precio',
  select_exhibitor: "Sélectionner l'exposant",
  code: 'Código',
  type: 'Tipo',
  discount: 'Descuento',
  status: 'Estado',
  from: 'De',
  to: 'Pour',
  filter: 'Filtro',
  option: 'Opção',
  session_privacy: 'Session privacy',
  private: 'Private',
  public: 'Public',
  my_addons: 'My add-ons',
  see_addons_bought: 'See add-ons/extras you bought',
  form: 'Form',
  checkout: 'Checkout',
  product_status: 'Status',
  price_includes_tax: 'Price includes tax',
  filter_visitor_contacts: 'Filtrer les contacts des visiteurs',
  filter_exhibitors: 'Filtrer les exposants',
  check_sources: "Filtre par l'origine d'interaction et les réponses des visiteurs",
  filter_visitor_multiple_select:
    'En sélectionnant plusieurs éléments, les contacts seront filtrés en fonction de toutes les options sélectionnées',
  content_not_allowed: 'You are not allowed to access this content.',
  fill_payment_information: 'Inform taxpayer number',
  only_letters_numbers_hyphen_underscore:
    'Only letters, numbers, hyphen and underscore are allowed',
  registration_closed: 'The accreditation for :event_name is now closed',
  return_home: 'Go back home',
  character_limit_1: 'limited to',
  character_limit_2: 'characters',
  booklet_sample: 'Booklet sample',
  invite_managers: 'Ajouter/supprimer des responsables',
  scan_for_interactions: "Recherche d'interactions",
  invite_managers_cta: 'Ajouter des responsables',
  grant_permissions: 'Gestion des droits',
  grant_permissions_error: 'Erreur survenue lors du changement des droits',
  invitation_send_error: "Erreur lors de l'envoi d'une invitation",
  permissions_changed: 'Droits modifiés avec succès',
  pre_registered: 'En préinscription',
  evaluate_session: 'Évaluer la session',
  session_evaluation_title: 'Évaluation de session',
  confirm_vote: 'Êtes-vous certain de vouloir confirmer votre vote?',
  vote_already_submitted: 'Votre réponse à cette question a déjà été soumise',
  vote_cannot_be_update: 'Votre vote ne peut pas être mis à jour après soumission',
  evaluate_event: "Évaluer l'événement",
  event_evaluation_title: "Évaluation d'événements",
  sold_out: 'Épuisé',
};

export default common;
