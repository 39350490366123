const activityCenter = {
  title: 'Activity center',
  noRecentActivity: 'No recent activity',
  inProgress: 'In progress',
  recentFiles: 'Recent files',
};

const messages = {
  title: 'Messages',
};

const scanner = {
  title: 'QR code scanner',
};

const helpdesk = {
  title: 'Help desk',
};

const loginPrompt = {
  title: 'Login required',
  text: 'Please log in as a visitor of this event to access this feature',
};

const sessionEnded = {
  title: "You've left the session!",
  subtitle: 'Keep browsing?',
  backToCalendar: 'Back to calendar',
  backToEvent: 'Back home',
};

const cookiesConsent = {
  title: 'Cookie consent',
  text: 'Cookies help us deliver the best experience on our website. By using our website, you agree to the use of cookies.',
};

const nfc_reader = {
  title: 'NFC Reader',
};

const nfc_success_reading = {
  title: 'NFC tag scanned successfully. Please wait while we validate it.',
};

const nfc_tag_not_found = {
  title: 'The tag is not linked to any event visitor.',
};

const nfc_paired = {
  title: 'NFC reader paired.',
};

const select_option_register_interactions = {
  title: 'Select a option to register interactions',
};

export default {
  activityCenter,
  messages,
  scanner,
  helpdesk,
  loginPrompt,
  sessionEnded,
  cookiesConsent,
  nfc_reader,
  nfc_success_reading,
  nfc_tag_not_found,
  nfc_paired,
  select_option_register_interactions,
};
