import { ICommonTranslations } from '.';

const common: ICommonTranslations = {
  events: 'Events',
  exhibitors: 'Exhibitors',
  exhibitor: 'Exhibitor',
  edit: 'Edit',
  add_spacer: 'Add spacer',
  add_stage: 'Add Stage',
  stages_message: 'These will be available in your session forms',
  edit_checkin: 'Edit check-in',
  discard: 'Discard',
  create: 'Create',
  delete: 'Delete',
  remove: 'Remove',
  name: 'Name',
  first_last_name: 'First and last name',
  name_placeholder: 'Enter your name',
  email: 'Email',
  invalid_email: 'This email is invalid',
  email_already_associated: 'The email entered is already associated with another subscription',
  description: 'Description',
  category: 'Category',
  categories: 'Categories',
  items: 'Items',
  model: 'Model',
  save: 'Save',
  download: 'Download',
  upload: 'Upload',
  change: 'Change',
  apply: 'Apply',
  empty_field_not_allowed: 'This field must not be empty',
  next: 'Next',
  previous: 'Previous',
  close: 'Close',
  cancel: 'Cancel',
  confirm: 'Confirm',
  search: 'Search',
  mandatory_field: 'This field is mandatory.',
  name_in_use: 'Name already taken.',
  crop: 'Crop',
  published: 'Published',
  not_published: 'Unpublished',
  all: 'All',
  empty_list: 'No data available',
  user_chat_failed: 'Could not open chat with this user',
  activity_center: 'Activity center',
  no_recent_activity: 'No recent activity',
  unread_messages: 'Unread messages',
  reply: 'Reply',
  session_invitations: 'Pending invitations',
  accept: 'Accept',
  refuse: 'Refuse',
  in_progress: 'In progress',
  recent_files: 'Recent files',
  open_conversations: 'Open conversations',
  all_fields_required: 'All fields required',
  something_went_wrong:
    'Something went wrong. <br> If the issue persists please <a href="mailto:support@beamian.com">contact us</a>.',
  already_exists: 'Already exists',
  profile: 'My account',
  filter_by_status: 'Filter by status',
  open_meeting: 'Open meeting',
  visitors: 'Visitors',
  saved: 'Saved',
  back: 'Back',
  session_full: 'Full',
  file_not_image: 'This file is not an image',
  new_export: 'New export',
  state: 'State',
  details: 'Details',
  see_more: 'See more',
  more: 'More',
  manage: 'Manage',
  see_as_visitor: 'See as visitor',
  see_as_exhibitor: 'See as exhibitor',
  manage_visitors: 'Manage candidates',
  manage_exhibitors: 'Manage exhibitors',
  manage_event: 'Manage event',
  registered: 'registered',
  refused: 'refused',
  pending: 'pending',
  session_manager: 'Session manager',
  export_questions: 'Export questions',
  my_mod_questions: 'Latest questions',
  mod_questions: 'Moderation questions',
  pending_plural: 'Pending',
  read: 'Read',
  mark_as_read: 'Mark as read',
  accepted: 'accepted',
  favorites: 'Favorites',
  add_to_favorites: 'Add to favorites',
  remove_from_favorites: 'Remove from favorites',
  contact_request: 'Contact request',
  invited: 'Invited',
  note: 'Note',
  no_comment: 'No comment',
  click_here: 'Click here',
  sessions: 'Sessions',
  event_sessions: 'Event sessions',
  exhibitor_sessions: 'Exhibitor sessions',
  networking: 'Networking',
  total: 'Total',
  update_success: 'Information successfuly updated',
  agree_to_terms:
    'Agree to <a href="https://innovatorsforum.fyre.website/wp-content/uploads/2024/07/Terms-and-Conditions.pdf" target="_blank"><b>Innovators Fórum\' 24: Circularity terms and conditions</b></a>',
  add_to_calendar: 'Add to your calendar',
  event_already_started: 'This event has already started',
  countdown: 'Countdown',
  please_check_answers: 'Please check your answers',
  sort_by: 'Sort by',
  order: 'Order',
  date: 'Date',
  ascending: 'Ascending',
  descending: 'Descending',
  no_data_title: 'Nothing to see here!',
  no_data_subtitle: 'Please come back later.',
  exhibitor_interactions: 'Exhibitor interactions',
  interactions: 'Interactions',
  product_interactions: 'Product interactions',
  sessions_subtitle: 'Latest data on session numbers and statistics',
  online_registrations: 'Online access registrations',
  in_person_registrations: 'In-person access registrations',
  contacts: 'Contacts',
  created: 'Created',
  seeing_as_visitor: 'Seeing event as visitor',
  total_sessions: 'Total sessions',
  ticket_types: 'Ticket type',
  image: 'Image',
  add: 'Add',
  tickets: 'Tickets',
  sold: 'Sold',
  price: 'Price',
  select_exhibitor: 'Select exhibitor',
  code: 'Code',
  type: 'Type',
  discount: 'Discount',
  status: 'Visibility',
  from: 'From',
  to: 'To',
  filter: 'Filter',
  option: 'Option',
  session_privacy: 'Session privacy',
  private: 'Private',
  public: 'Public',
  my_addons: 'My add-ons',
  see_addons_bought: 'See add-ons/extras you bought',
  form: 'Form',
  checkout: 'Checkout',
  product_status: 'Status',
  price_includes_tax: 'Price includes tax',
  filter_visitor_contacts: 'Filter visitor contacts',
  filter_exhibitors: 'Filter exhibitors',
  check_sources: 'Filter by source of interaction and visitors answers.',
  filter_visitor_multiple_select:
    'By selecting multiple items, the contacts will be filtered by all the selected options.',
  content_not_allowed: 'You are not allowed to access this content.',
  fill_payment_information: 'Inform taxpayer number',
  only_letters_numbers_hyphen_underscore:
    'Only letters, numbers, hyphen and underscore are allowed',
  registration_closed: 'The accreditation for :event_name is now closed.',
  return_home: 'Go back home',
  character_limit_1: 'limited to',
  character_limit_2: 'characters',
  booklet_sample: 'Booklet sample',
  invite_managers: 'Invite/remove managers',
  scan_for_interactions: 'Scan for interactions',
  invite_managers_cta: 'Invite managers',
  grant_permissions: 'Management permissions',
  grant_permissions_error: 'Error while changing permissions',
  invitation_send_error: 'Error while sending an invite',
  permissions_changed: 'Permissions updated successfully',
  pre_registered: 'Pre-registered',
  evaluate_session: 'Evaluate session',
  session_evaluation_title: 'Session Evaluation',
  confirm_vote: 'Are you sure you want to confirm your vote?',
  vote_already_submitted: 'Your answer to this question has already been submitted',
  vote_cannot_be_update: 'Your vote cannot be updated after submission.',
  evaluate_event: 'Evaluate event',
  event_evaluation_title: 'Event Evaluation',
  sold_out: 'Sold out',
};
export default common;
