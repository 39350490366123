import axios from 'axios';
import {
  IEvent,
  IEventLanguage,
  IEventSettings,
  IEventV2,
  IPackage,
  IWorkingPermissions,
} from '@/models/event';
import { IBooklet } from '@/models/booklet';
import { IEventsParams } from '@/store/modules/events';
import { Resolve, IData } from '.';
import config from './config';
import { IEventInfo } from '@/models/visitor';

const url = process.env.VUE_APP_SERVER_PATH;

export const resendRegistrationEmail = (payload: { attending_id: number; slug: string }) =>
  new Promise((resolve: Resolve<IEventInfo>) => {
    axios
      .post(`${url}/event-live/${payload.slug}/resend_registration_email`, payload, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const resendPaymentEmail = (payload: { attending_id: number; slug: string }) =>
  new Promise((resolve: Resolve<IEventInfo>, reject) => {
    axios
      .post(`${url}/event-live/${payload.slug}/resend_payment_email`, payload, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const resendRsvpEmail = (payload: { attending_id: number; slug: string }) =>
  new Promise((resolve: Resolve<IEventInfo>, reject) => {
    axios
      .post(`${url}/event-live/${payload.slug}/resend_rsvp_email`, payload, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const deleteEvent = (eventId: number) =>
  new Promise((resolve: Resolve<IEvent[]>, reject) => {
    axios
      .delete(`${url}/events/${eventId}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getEventInfo = (event: IEventV2) =>
  new Promise((resolve: Resolve<IEventInfo>) => {
    axios
      .get(`${url}/event/${event.slug}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export const getEvents = (params: IEventsParams) =>
  new Promise((resolve: Resolve<IData<IEventV2>>, reject) => {
    let requestUrl = `${url}/v2/events?page_size=${params.page_size}&page=${params.page}&ordering=-id`;

    if (params.search) {
      requestUrl += `&search=${params.search}`;
    }

    if (params.type) {
      requestUrl += `&type=${params.type}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getEventV1 = (slug: string) =>
  new Promise((resolve: Resolve<IEvent>, reject) => {
    axios
      .get(`${url}/event/${slug}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getEventById = (event: number) =>
  new Promise((resolve: Resolve<IEventV2>, reject) => {
    axios
      .get(`${url}/v2/events/${event}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const getEventBySlug = (event: string) =>
  new Promise((resolve: Resolve<IData<IEventV2>>, reject) => {
    axios
      .get(`${url}/v2/events?slug=${event}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getEventLanguage = (event: number) =>
  new Promise((resolve: Resolve<IData<IEventLanguage>>, reject) => {
    axios
      .get(`${url}/v2/event-languages?event=${event}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getEventSettings = (event: number) =>
  new Promise((resolve: Resolve<IData<IEventSettings>>, reject) => {
    axios
      .get(`${url}/v2/event-settings?event=${event}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getEventsById = (events: number[]) =>
  new Promise((resolve: Resolve<IEventV2>, reject) => {
    axios
      .get(`${url}/v2/events?id=${events.join(',')}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const addEvent = (event: IEvent) =>
  new Promise((resolve: Resolve<IEvent>, reject) => {
    axios
      .post(`${url}/events`, event, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getBooklet = (eventId: number) =>
  new Promise((resolve: Resolve<IData<IBooklet>>, reject) => {
    axios
      .get(`${url}/v2/booklets?event=${eventId}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getTermsAndConditions = (eventId: number) =>
  new Promise(
    (
      resolve: Resolve<
        IData<{
          id: number;
          link: string;
          link_text: string;
        }>
      >,
      reject,
    ) => {
      axios
        .get(`${url}/v2/terms-and-conditions?event=${eventId}&ordering=-id`, config())
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    },
  );

export const getPackages = (params: { event: number; exhibitor: number }) =>
  new Promise((resolve: Resolve<IData<IPackage>>, reject) => {
    axios
      .get(`${url}/v2/packages?event=${params.event}&exhibitor=${params.exhibitor}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const addBooklet = (booklet: IBooklet) =>
  new Promise((resolve: Resolve<IBooklet>, reject) => {
    axios
      .post(`${url}/v2/booklets`, booklet, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const editBooklet = (bookletId: number, booklet: IBooklet) =>
  new Promise((resolve: Resolve<IData<IBooklet>>, reject) => {
    axios
      .put(`${url}/v2/booklets/${bookletId}`, booklet, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const addEventManager = (eventManager: { user_account: number; event: number }) =>
  new Promise((resolve: Resolve<any>, reject) => {
    axios
      .post(`${url}/v2/event-managers`, eventManager, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const deleteEventManager = (id: number) =>
  new Promise((resolve: Resolve<any>, reject) => {
    axios
      .delete(`${url}/v2/event-managers/${id}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const editEvent = (event: IEvent) =>
  new Promise((resolve: Resolve<IEvent[]>, reject) => {
    axios
      .put(`${url}/event/${event.id}`, event, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getMyPermissions = (event: number) =>
  new Promise((resolve: Resolve<IWorkingPermissions>, reject) => {
    const requestUrl = `${url}/organizers/events/${event}/permissions`;

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

// export const deleteEvent = (event: IEvent) => new Promise((resolve: Resolve<IEvent[]>, reject) => {
//   // axios
//   // .delete(`${url}/v2/event/${deletedEvent.id}`, config())
//   // .then(response => {
//   //   context.commit('removeEvent', deletedEvent);
//   // })
//   // .catch(err => {
//   //   context.commit('setEventError', { failedEvent: deletedEvent, message: err.message });
//   // });
// });

export const uploadEventImage = (formData: FormData) =>
  new Promise((resolve: Resolve<{ id: number }>) => {
    axios
      .post(`${url}/v2/event-images`, formData, config(true))
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        resolve(err);
      });
  });

export interface IFile {
  file: string;
  id: number;
  name?: string;
  original_file_name?: string;
  url?: string;
  errors?: any;
}
