import common from './common/de';
import exhibitors from './exhibitors/de';
import visitors from './visitors/de';
import managers from './managers/de';

export default {
  common,
  exhibitors,
  visitors,
  managers,
};
