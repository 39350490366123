import { computed } from 'vue';
import store from '@/store';
import { IPopup } from '@/components/index';
import useContext from './context';

export default () => {
  const popups = computed(() => store.getters.popups as IPopup[]);
  const { translations } = useContext();

  const addPopup = (popup: IPopup) => {
    store.commit('addPopup', popup);
  };

  const setPopups = (popups: IPopup[]) => {
    store.commit('setPopups', popups);
  };

  const triggerPopupErrors = (err: any | string) => {
    const { response } = err;
    if (!response && typeof err === 'string') {
      store.commit('addPopup', {
        message: err,
        type: 'danger',
        autohide: true,
      });
    } else {
      const { data } = response;
      if (data && data.length > 0) {
        data.forEach((error: string) => {
          store.commit('addPopup', {
            message: error,
            type: 'danger',
            autohide: true,
          });
        });
      } else if (data && data.detail) {
        store.commit('addPopup', {
          message: data.detail,
          type: 'danger',
          autohide: true,
        });
      } else {
        store.commit('addPopup', {
          message: translations.value.common.something_went_wrong,
          type: 'danger',
          autohide: true,
        });
      }
    }
  };
  return {
    popups,
    triggerPopupErrors,
    addPopup,
    setPopups,
  };
};
