
// @ is an alias to /src
import axios from 'axios';
import { MutationPayload } from 'vuex';
import { getExhibitorManagerInviteAccessToken, getVisitorAccessToken } from '@/api/interactions';
import Navbar from '@/components/navbar.vue';
import Modal from '@/components/modal.vue';
import HelpCenter from '@/views/User/HelpCenter.vue';
import Messages from '@/views/User/Messages/Messages.vue';
import router from '@/router';
import store from '@/store';
import Notifications from '@/views/User/Notifications.vue';
import Scanner from '@/views/Scanner.vue';
import Popup from '@/components/popup.vue';
import { fetchUserByEmail, fetchUserById } from '@/api/users';

import { IAuth } from '@/models/auth';
import { defineComponent } from 'vue';
import { getEventById } from './api/events';
import useContext from './composition/context';
import useAuth from './composition/auth';
import usePopups from './composition/popups';
import Footer from '@/components/custom-footer.vue';

import Vue from 'vue';
import VueGtm from '@gtm-support/vue2-gtm';
import VueGtag from 'vue-gtag';

const development =
  window.location.href.includes('beta') || window.location.href.includes('staging');

export default defineComponent({
  components: {
    Navbar,
    HelpCenter,
    Notifications,
    Popup,
    Messages,
    Scanner,
    Modal,
    Footer,
  },
  data() {
    return {
      heroHidden: false,
      navigationHidden: false,
      footerHidden: false,
      redirectUrl: process.env.VUE_APP_REDIRECT_URL,
      serverPath: process.env.VUE_APP_SERVER_PATH,
      userManaging: false,
      nfcReader: false,
      loginPromptModal: {
        isActive: false,
        isCard: true,
      },
      cookiesConsentAlert: true,
      tokensInit: false,
    };
  },

  setup() {
    const {
      translations,
      contextEvent,
      contextExhibitor,
      userIsAdmin,
      eventContextRoute,
      liveMenuLocked,
    } = useContext();

    const { userIsLoggedIn, currentUser } = useAuth();
    const { popups } = usePopups();

    return {
      eventContextRoute,
      translations,
      contextEvent,
      userIsLoggedIn,
      currentUser,
      popups,
      contextExhibitor,
      userIsAdmin,
      liveMenuLocked,
    };
  },

  created() {
    const { translations } = useContext();

    const beamian_cookies = this.$cookies.get('beamian-cookies');
    this.cookiesConsentAlert = !(beamian_cookies === 'true');

    this.getAuthCreds();
    if (development) {
      this.changeFavicon();
    }

    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'openLoginPrompt') {
        this.loginPromptModal.isActive = true;
      }
    });
    axios.interceptors.response.use(undefined, (err) => {
      if (err.request.responseURL.includes(this.serverPath)) {
        if (err.request.status === 401) {
          // if you ever get an unauthorized, logout the user
          localStorage.setItem(
            process.env.VUE_APP_LOCAL_STORAGE_REDIRECT_PROP,
            router.currentRoute.fullPath,
          );

          if (this.currentUser.id) {
            const localStorageProp = process.env.VUE_APP_LOCAL_STORAGE_AUTH_PROP;
            localStorage.setItem(
              localStorageProp,
              JSON.stringify({
                user: this.currentUser,
                userIsLoggedIn: false,
              }),
            );

            store.commit('setUserLogout');
            store.commit('setContextRole', 'unset');
            store.commit('setContextExhibitor', {});
            store.commit('setContextVisitor', {});
          }
          router.push('/login');
        }
        if (err.request.status === 403) {
          // User should not see permissions error
          store.commit('addPopup', {
            message: translations.value.common.content_not_allowed,
            type: 'danger',
            autohide: true,
          });
        } else if (err.request.status === 429) {
          store.commit('addPopup', {
            message:
              'Looks like you have been doing that a lot. Please try again in a few minutes.',
            type: 'danger',
            autohide: true,
          });
        } else if ([500].includes(err.request.status)) {
          store.commit('addPopup', {
            message: translations.value.common.something_went_wrong,
            type: 'danger',
            autohide: true,
          });
        }
      }
      throw err;
    });

    // Send to beamian Google Analytics by default
    Vue.use(VueGtag, {
      config: {
        id: process.env.VUE_APP_GA_ID,
      },
    });
  },
  methods: {
    redirectToLogin() {
      localStorage.setItem(
        process.env.VUE_APP_LOCAL_STORAGE_REDIRECT_PROP,
        router.currentRoute.fullPath,
      );
      store.commit('setUserLogout');
      store.commit('setContextRole', 'unset');
      store.commit('setContextEvent', {});
      store.commit('setContextExhibitor', {});
      store.commit('setContextVisitor', {});
      router.push('/');
      this.loginPromptModal.isActive = false;
    },

    checkRoute() {
      const { currentRoute } = router;
      if (
        currentRoute.name === 'Setup' ||
        currentRoute.name === 'Login' ||
        currentRoute.name === 'Register' ||
        currentRoute.name === 'Home' ||
        currentRoute.name === 'Logout' ||
        currentRoute.name === 'VisitorSessionEnded' ||
        currentRoute.name === 'OnboardingEmbed' ||
        currentRoute.name === 'OnboardingGuestEmbed'
      ) {
        this.heroHidden = true;
        this.navigationHidden = true;
        this.footerHidden = true;
      } else if (
        currentRoute.name === 'ExhibitorOnboarding' ||
        currentRoute.name === 'VisitorOnboarding' ||
        currentRoute.name === 'TicketStore'
      ) {
        this.heroHidden = true;
        this.footerHidden = true;
        this.navigationHidden = false;
      } else {
        this.heroHidden = false;
        this.navigationHidden = false;
        this.footerHidden = false;
      }

      if (router.currentRoute.query.activity_center) {
        if (this.userIsLoggedIn) {
          setTimeout(() => {
            store.commit('openNotifications');
          }, 2000);
        } else {
          localStorage.setItem(
            process.env.VUE_APP_LOCAL_STORAGE_REDIRECT_PROP,
            router.currentRoute.fullPath,
          );
          router.push('/login');
        }
      }
    },

    logVisitorFromInvite() {
      // Visitor entered the page with invitation link
      const eventId = parseInt(router.currentRoute.query.e as string, 10);
      const token = router.currentRoute.query.t as string;
      const userId = parseInt(router.currentRoute.query.user as string, 10);
      const edit_profile = router.currentRoute.query.edit_profile as string;
      const visitorId = parseInt(router.currentRoute.query.visitor_id as string, 10);

      getVisitorAccessToken(token).then((response) => {
        const { token, name, email } = response.data;
        const auth: IAuth = {
          token,
          user: {
            email,
            name,
          },
        };
        // Set authentication token before accessing user information
        store.commit('setUserAccessToken', token);

        // Get userId and confirm credentials
        fetchUserById(userId).then(
          (response) => {
            const user = response.data;
            auth.user.profile_picture = user.profile_picture;
            auth.user.id = user.id;
            auth.user.name = user.name;
            auth.user.email = user.email;

            // After getting user id, log the user in
            store.commit('setUserLogin', auth);
            const localStorageProp = process.env.VUE_APP_LOCAL_STORAGE_AUTH_PROP;

            localStorage.setItem(
              localStorageProp,
              JSON.stringify({
                auth,
                userIsLoggedIn: true,
              }),
            );

            store.dispatch('getRoles');
            getEventById(eventId).then((response) => {
              const event = response.data;
              store.commit('setContextEvent', event);

              if (router.currentRoute.path.includes('session-details')) {
                router.push(router.currentRoute.path);
              } else {
                if (edit_profile === 'true') {
                  router.push(`${this.eventContextRoute}/profile/${visitorId}/edit`);
                } else {
                  router.push(`${this.eventContextRoute}`);
                }
              }
            });
          },
          (response) => {
            // Something went wrong. Logout
            console.log(response);
            router.push('/login');
          },
        );
      });
    },
    initGTMTracking() {
      // Add client gtm id to list of receivers if enabled and defined
      if (
        this.contextEvent.settings?.allow_google_tracking &&
        this.contextEvent.settings?.google_tag_manager_id !== ''
      ) {
        Vue.use(VueGtm, {
          id: [process.env.VUE_APP_GTM_ID, this.contextEvent.settings?.google_tag_manager_id],
          defer: false,
          enabled: true,
          debug: false,
          loadScript: true,
          vueRouter: router,
          trackOnNextTick: false,
        });
      } else {
        // Always send to our google tag manager by default
        Vue.use(VueGtm, {
          id: process.env.VUE_APP_GTM_ID,
          defer: false,
          enabled: true,
          debug: false,
          loadScript: true,
          vueRouter: router,
          trackOnNextTick: false,
        });
      }
    },
    logExhibitorFromInvite() {
      // Exhibitor manager entered the page with invitation link
      const token = router.currentRoute.query.exhibitor_t as string;
      const exhibitor = parseInt(router.currentRoute.query.exhibitor as string, 10);
      const event = parseInt(router.currentRoute.query.event as string, 10);

      if (isNaN(exhibitor) || !exhibitor) {
        router.push(`/404`);
      } else {
        // Get invitation token information
        getExhibitorManagerInviteAccessToken(token)
          .then((response) => {
            const { email, auth_token } = response.data;

            // Set authentication token before accessing user information
            store.commit('setUserAccessToken', auth_token);

            // Get user information
            fetchUserByEmail(email)
              .then((response) => {
                // This most likely won't happen
                if (response.data.results.length < 1) {
                  store.commit('addPopup', {
                    message: 'User does not exist',
                    type: 'danger',
                    autohide: true,
                  });
                  router.push('/login');
                  return;
                }

                const user = response.data.results[0];

                const auth: IAuth = {
                  token: auth_token,
                  user: {
                    id: user.id,
                    email: user.email,
                    name: user.name,
                    profile_picture: user.profile_picture,
                  },
                };

                store.commit('setUserLogin', auth);
                const localStorageProp = process.env.VUE_APP_LOCAL_STORAGE_AUTH_PROP;

                localStorage.setItem(
                  localStorageProp,
                  JSON.stringify({
                    auth,
                    userIsLoggedIn: true,
                  }),
                );
                store.dispatch('getRoles');

                getEventById(event).then((response) => {
                  const event = response.data;
                  if (event.id) {
                    store.commit('setContextEvent', event);
                    router.push(`${this.eventContextRoute}/manage-exhibitor/${exhibitor}`);
                  }
                });
              })
              .catch((err) => {
                const errors = err.response.data;
                if (errors && errors.length > 0) {
                  errors.forEach((error: string) => {
                    store.commit('addPopup', {
                      message: error,
                      type: 'danger',
                      autohide: true,
                    });
                  });
                } else {
                  store.commit('addPopup', {
                    message: err.message,
                    type: 'danger',
                    autohide: true,
                  });
                }
              });
          })
          .catch((err) => {
            const errors = err.response.data;
            if (errors && errors.length > 0) {
              errors.forEach((error: string) => {
                store.commit('addPopup', {
                  message: error,
                  type: 'danger',
                  autohide: true,
                });
              });
            } else {
              store.commit('addPopup', {
                message: err.message,
                type: 'danger',
                autohide: true,
              });
            }
          });
      }
    },

    getAuthCreds() {
      const localStorageLang = process.env.VUE_APP_LOCAL_STORAGE_LANG_PROP;
      let lang = localStorage.getItem(localStorageLang);

      const sysLang = (<any>window.navigator).userLanguage || window.navigator.language;

      if (!lang) {
        if (sysLang.includes('pt')) {
          lang = 'pt';
        }
        if (sysLang.includes('en')) {
          lang = 'en';
        }
        if (sysLang.includes('es')) {
          lang = 'es';
        }
        if (sysLang.includes('fr')) {
          lang = 'fr';
        }
        if (sysLang.includes('de')) {
          lang = 'de';
        }
      }

      store.commit('setLanguage', lang || 'en');

      const localStorageAuthProp = process.env.VUE_APP_LOCAL_STORAGE_AUTH_PROP;
      const authString = localStorage.getItem(localStorageAuthProp);
      if (authString) {
        const { userIsLoggedIn, auth } = JSON.parse(authString);
        if (userIsLoggedIn) {
          store.commit('setUserLogin', auth);
          store.dispatch('getRoles');
        } else {
          store.commit('setRoles', {});
          localStorage.setItem(
            process.env.VUE_APP_LOCAL_STORAGE_REDIRECT_PROP,
            router.currentRoute.fullPath,
          );
        }
      }
    },

    changeFavicon() {
      let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = 'images/favicon-beta.ico';
    },
    confirmCookiesConsent() {
      // Save user confirmation
      this.cookiesConsentAlert = false;
      this.$cookies.set('beamian-cookies', 'true', '90d');
    },
  },
  watch: {
    $route() {
      store.commit('closeNotifications');
      this.checkRoute();
      if (!this.tokensInit) {
        if (router.currentRoute.query.exhibitor_t) {
          store.commit('setUserLogout');
          store.commit('setContextRole', 'unset');
          store.commit('setContextEvent', {});
          store.commit('setContextExhibitor', {});
          store.commit('setContextVisitor', {});
          this.logExhibitorFromInvite();
          this.tokensInit = true;
        } else if (router.currentRoute.query.t) {
          store.commit('setUserLogout');
          store.commit('setContextRole', 'unset');
          store.commit('setContextEvent', {});
          store.commit('setContextExhibitor', {});
          store.commit('setContextVisitor', {});
          this.logVisitorFromInvite();
          this.tokensInit = true;
        }
      }
      if (
        router.currentRoute.fullPath.includes('manage-event') ||
        router.currentRoute.fullPath.includes('manage-exhibitor/')
      ) {
        this.userManaging = true;
      } else {
        this.userManaging = false;
      }
    },
    'contextEvent.settings'() {
      if (this.contextEvent.settings) {
        this.initGTMTracking();
      }
    },
  },
});
