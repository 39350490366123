import {
  ISessionDetailsTranslations,
  ISessionInputsTranslations,
  ISessionsAssignmentTranslations,
  ISessionsCommonTranslations,
  ISessionsTranslations,
} from '.';

const details: ISessionDetailsTranslations = {
  session_has_ended: 'This session is over.',
  register_title: 'Register for this session',
  online_seats: 'Online seats',
  in_person_seats: 'In-person seats',
};

const inputs: ISessionInputsTranslations = {
  other_languages: 'Other languages',
  session_type: 'Session type',
  session_type_placeholder: 'Select the type of session',
  privacy_label: 'Who can watch this session?',
  privacy_open: 'Anyone',
  privacy_registration: 'Require registration',
  privacy_closed: 'Require direct invitation',
  video_call_url: 'Video call URL',
  video_call_url_placeholder: 'Insert the video call URL or leave empty',
  select_channel: 'Select channel',
  stage: 'Stage',
  stage_placeholder: 'Select a stage',
  allow_tag_not_registered: 'Allow tag not registered',
};

const common: ISessionsCommonTranslations = {
  in_person: 'In-person',
  online: 'Online',
  stream: 'Stream',
  video_call: 'Video call',
  in_person_registration: 'In-person Registration',
  online_registration: 'Online Registration',
  moderation_question: 'Ask a question to the moderator',
  moderation_chat: 'Chat to the moderator',
  moderation_chat_error: 'Could not access the moderation chat.',
  moderation_question_created: 'Question successfully submitted to the moderator.',
  moderation_question_error: 'Could not submit the question.',
};

const assignment: ISessionsAssignmentTranslations = {
  heading: 'Choose a visitor to assign as a session manager',
  success: 'Visitor successfully assigned as session manager.',
  error: 'This visitor could not be assigned as a session manager.',
  empty_list: 'No results!',
  assign: 'Assign manager',
  remove_from: 'Remove manager from',
  unassign_1: 'Do you wish to unassign',
  unassign_2: 'as a manager to this session?',
};

const sessions: ISessionsTranslations = {
  assignment,
  details,
  common,
  inputs,
};

export default sessions;
