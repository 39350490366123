import { ICommonTranslations } from '@/translations/default/common';

const common: Partial<ICommonTranslations> = {
  exhibitors: 'Exhibitors',
  exhibitor: 'Exhibitor',
  contact_request: 'Spontaneous application',
  product_interactions: 'Applications to job offers',
  filter_visitor_contacts: 'Filter candidates',
  filter_exhibitors: 'Filter brands',
  check_sources: "Filter by source of interaction and candidates' answers.",
  filter_visitor_multiple_select:
    'By selecting multiple items, the candidates will be filtered by all the selected options.',
};

export default common;
