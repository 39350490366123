
import { defineComponent, PropType } from 'vue';

import { IModal } from '.';

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<IModal>,
      required: true,
    },
    value: String,
  },

  setup(props, { emit }) {
    const close = () => {
      if (props.config.close) {
        props.config.close();
      } else {
        if (props.config.onClose) {
          props.config.onClose();
        }

        props.config.isActive = false;
      }
      emit('close');
    };

    return { close };
  },
});
