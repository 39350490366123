import { ActionContext } from 'vuex';
import { deleteNotification, getNotifications, updateNotification } from '@/api/notifications';
import { IRocketRoom } from '@/models/chat';
import { INotification, INotificationsParams } from '@/models/notifications';
import { ISession } from '@/models/session';
import { IState } from '..';

export interface INotificationsState {
  messages: IRocketRoom[];
  sessions: ISession[];
  notifications: INotification[];
}

const state: INotificationsState = {
  messages: [],
  sessions: [],
  notifications: [],
};

const getters = {
  notificationsNr: (state: INotificationsState) =>
    state.notifications.filter((notification) => notification.status === 'sent').length,
  notifications: (state: INotificationsState) =>
    state.notifications.filter((notification) => notification.status === 'sent'),
  messages: (state: INotificationsState) => state.messages,
};

const mutations = {
  setMessagesNotifications: (state: INotificationsState, messages: IRocketRoom[]) => {
    state.messages = messages;
  },
  setNotifications: (state: INotificationsState, notifications: INotification[]) => {
    state.notifications = notifications;
  },
  updateNotification: (state: INotificationsState, editedNotification: INotification) => {
    state.notifications = state.notifications.map((notification) =>
      editedNotification.id === notification.id
        ? {
            ...editedNotification,
            loading: false,
            error: false,
          }
        : notification,
    );
  },
  deleteNotification: (state: INotificationsState, deletedNotification: INotification) => {
    state.notifications = state.notifications.filter(
      (notification) => deletedNotification.id !== notification.id,
    );
  },
};

const actions = {
  fetchNotifications(
    context: ActionContext<INotificationsState, IState>,
    params: INotificationsParams,
  ) {
    // context.commit('setNnotificationsLoading');
    getNotifications(params)
      .then((response) => {
        context.commit('setNotifications', response.data.results);
      })
      .catch((err) => {
        context.commit('setNotificationsError', err.request);
      });
  },
  updateNotification(
    context: ActionContext<INotificationsState, IState>,
    notification: INotification,
  ) {
    // context.commit('setNnotificationsLoading');
    updateNotification(notification)
      .then((response) => {
        context.commit('updateNotification', response.data);
      })
      .catch((err) => {
        context.commit('setNotificationsError', err.request);
      });
  },
  deleteNotification(
    context: ActionContext<INotificationsState, IState>,
    notification: INotification,
  ) {
    // context.commit('setNnotificationsLoading');
    deleteNotification(notification)
      .then(() => {
        context.commit('deleteNotification', notification);
      })
      .catch((err) => {
        context.commit('setNotificationsError', err.request);
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
