import {
  IExhibitorsDashboardTranslations,
  IExhibitorTickets,
  IExhibitorExports,
  IExhibitorVisitors,
  IExhibitorSidebar,
  IExhibitorSettingsTranslations,
  IExhibitorProducsTranslations,
  IExhibitorInteractionsTranslations,
  IExhibitorOnboardingTranslations,
  IExhibitorSessions,
  IExhibitorProductInteractions,
  IExhibitorProfile,
} from '@/translations/default/exhibitors';

const dashboard: Partial<IExhibitorsDashboardTranslations> = {
  // leave empty until needed
};

const tickets: Partial<IExhibitorTickets> = {
  // leave empty until needed
};

const exports: Partial<IExhibitorExports> = {
  // leave empty until needed
};

const visitors: Partial<IExhibitorVisitors> = {
  // leave empty until needed
};

const sidebar: Partial<IExhibitorSidebar> = {
  exhibitor_interactions: 'Candidatures spontanées',
  interactions: 'Candidats',
  products: "Offres d'emplois",
  product_interactions: "Candidatures aux offres d'emploi",
};

const settings: Partial<IExhibitorSettingsTranslations> = {
  // leave empty until needed
};

const products: Partial<IExhibitorProducsTranslations> = {
  title: "Offres d'emplois",
  subtitle: "Créer, modifier et supprimer des offres d'emploi",
  no_products: "Aucune offre d'emploi disponible",
  new_product: "Nouvelle offre d'emploi",
  create_new_product: "Créer une nouvelle offre d'emploi",
  edit_product: "Modifier une offre d'emploi",
  interactions_exist_cannot_delete:
    "Cette offre d'emploi a déjà fait l’objet de candidatures, elle ne peut donc pas être supprimée",
};

const interactions: Partial<IExhibitorInteractionsTranslations> = {
  title: 'Candidatures spontanées',
  subtitle: 'Vérifier les candidatures et planifier des réunions',
  noInteractions: 'Cet exposant ne présente aucune interaction.',
};

const onboarding: Partial<IExhibitorOnboardingTranslations> = {
  // leave empty until needed
};

const sessions: Partial<IExhibitorSessions> = {
  private_session_warning:
    "Créer une séance privée pour inviter des candidats spécifiques à une réunion. S'il s'agit d'une séance publique, tout candidat pourra la voir et s'y inscrire.",
};

const productInteractions: Partial<IExhibitorProductInteractions> = {
  title: "Candidatures aux offres d'emploi",
  subtitle: 'Vérifier les candidatures et planifier des réunions',
  product_name: "Offre d'emploi",
  interaction_name: 'Nom du candidat',
  application: 'Appliquer',
};

const profile: Partial<IExhibitorProfile> = {
  products: "Offres d'emplois",
};

export default {
  dashboard,
  settings,
  interactions,
  onboarding,
  sidebar,
  sessions,
  products,
  productInteractions,
  exports,
  visitors,
  profile,
  tickets,
};
