import VueRouter, { RouteConfig } from 'vue-router';

const customEvent = process.env.VUE_APP_CUSTOM_EVENT;
let rootEventRoute = '/:eventSlug';
let outerRoutes = [] as RouteConfig[];

if (customEvent !== '') {
  rootEventRoute = '';
} else {
  outerRoutes = [
    {
      path: '',
      name: 'Home',
      component: () => import('@/views/Home.vue'),
      children: [
        {
          path: 'new-event',
          name: 'NewEvent',
          component: () => import('@/views/Manager/NewEvent.vue'),
        },
      ],
    },
    {
      path: '/account-settings',
      name: 'AccountSettings',
      component: () => import('@/views/User/Account.vue'),
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/views/User/Login.vue'),
    },
    {
      path: '/setup',
      name: 'Setup',
      component: () => import('@/views/User/Setup.vue'),
    },
    {
      path: '/register',
      name: 'Register',
      component: () => import('@/views/User/Register.vue'),
    },
    {
      path: '/reset-password',
      name: 'ResetPassword',
      component: () => import('@/views/User/ResetPassword.vue'),
    },
    {
      path: '/404',
      name: '404',
      component: () => import('@/views/PageNotFound.vue'),
    },
    {
      path: '/registration-closed/:event_slug',
      name: 'RegistrationClosed',
      component: () => import('@/views/RegistrationClosed.vue'),
    },
  ];
}

const routes: Array<RouteConfig> = [
  ...outerRoutes,
  {
    path: rootEventRoute,
    name: 'Event',
    component: () => import('@/views/Event.vue'),
    children: [
      {
        path: '/404',
        name: '404',
        component: () => import('@/views/PageNotFound.vue'),
      },
      {
        path: 'account-settings',
        name: 'AccountSettings',
        component: () => import('@/views/User/Account.vue'),
      },
      {
        path: 'pending',
        name: 'NotAllowed',
        component: () => import('@/views/Visitor/NotAllowed.vue'),
      },
      {
        path: 'review',
        name: 'ReviewEvent',
        component: () => import('@/views/Visitor/ReviewEvent.vue'),
      },
      {
        path: '',
        name: 'EventHome',
        component: () => import('@/views/Visitor/Home.vue'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/views/User/Login.vue'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/User/Register.vue'),
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: () => import('@/views/User/ResetPassword.vue'),
      },
      {
        path: 'profile/:visitor',
        name: 'VisitorNetworkingProfile',
        component: () => import('@/views/Visitor/Profile.vue'),
        children: [
          {
            path: 'qrcode',
            name: 'VisitorQRCode',
            component: () => import('@/views/Visitor/QRCode.vue'),
          },
          {
            path: 'edit',
            name: 'VisitorForm',
            component: () => import('@/views/Visitor/Form.vue'),
          },
        ],
      },
      {
        path: 'exhibitors',
        name: 'VisitorExhibitors',
        component: () => import('@/views/Visitor/Exhibitors/Exhibitors.vue'),
      },
      {
        path: 'exhibitor/:exhibitor',
        component: () => import('@/views/Visitor/Exhibitors/Actions/Exhibitor.vue'),
        name: 'VisitorExhibitor',
        children: [
          {
            path: 'vote',
            name: 'VisitorExhibitorVote',
            component: () => import('@/views/Visitor/Exhibitors/Actions/Vote.vue'),
          },
        ],
      },

      {
        path: 'checkout',
        name: 'Checkout',
        component: () => import('@/views/User/Checkout/Checkout.vue'),
      },
      {
        path: 'checkout-success',
        name: 'CheckoutSuccess',
        component: () => import('@/views/User/Checkout/Success.vue'),
      },
      {
        path: 'my-addons',
        name: 'VisitorAddons',
        component: () => import('@/views/Visitor/Addons/VisitorAddons.vue'),
      },
      {
        path: 'addons',
        name: 'Addons',
        component: () => import('@/views/Visitor/Addons/Addons.vue'),
      },
      {
        path: 'addon/:addon_id',
        name: 'VisitorAddon',
        component: () => import('@/views/Visitor/Addons/Addon.vue'),
      },
      {
        path: 'products',
        name: 'VisitorProducts',
        component: () => import('@/views/Visitor/Products/Products.vue'),
      },
      {
        path: 'product/:product',
        name: 'VisitorProduct',
        component: () => import('@/views/Visitor/Products/Product.vue'),
        children: [
          {
            path: 'detail',
            name: 'VisitorProductDetails',
            component: () => import('@/views/Visitor/Products/Actions/Detail.vue'),
          },
        ],
      },
      {
        path: 'trend/:trend',
        component: () => import('@/views/Visitor/Trends/Trend.vue'),
        name: 'VisitorTrend',
      },
      {
        path: 'sessions',
        name: 'VisitorSessions',
        component: () => import('@/views/Visitor/Sessions/Sessions.vue'),
      },
      {
        path: 'session-details/:session',
        name: 'SessionDetails',
        component: () => import('@/views/Visitor/Sessions/Actions/Details.vue'),
      },
      {
        path: 'session/:session',
        name: 'VisitorSession',
        component: () => import('@/views/Visitor/Sessions/Session.vue'),
      },
      {
        path: 'networking',
        name: 'VisitorNetworking',
        component: () => import('@/views/Visitor/Networking/Networking.vue'),
      },
      {
        path: 'roles',
        name: 'Roles',
        component: () => import('@/views/User/Roles.vue'),
      },
      {
        path: 'new-exhibitor',
        name: 'ExhibitorOnboarding',
        component: () => import('@/views/Exhibitor/Onboarding/Onboarding.vue'),
      },
      {
        path: 'new-visitor',
        name: 'VisitorOnboarding',
        component: () => import('@/views/Visitor/Onboarding/OnboardingEmbed.vue'),
      },
      {
        path: 'new-visitor-embed',
        name: 'OnboardingEmbed',
        component: () => import('@/views/Visitor/Onboarding/OnboardingEmbed.vue'),
      },
      {
        path: 'new-guest',
        name: 'OnboardingGuest',
        component: () => import('@/views/Visitor/Onboarding/OnboardingGuest.vue'),
      },
      {
        path: 'new-guest-embed',
        name: 'OnboardingGuestEmbed',
        component: () => import('@/views/Visitor/Onboarding/OnboardingGuestEmbed.vue'),
      },
      {
        path: 'tickets',
        name: 'TicketStore',
        component: () => import('@/views/Visitor/TicketStore/TicketStore.vue'),
      },
      {
        path: 'manage-exhibitors',
        name: 'ManageExhibitors',
        component: () => import('@/views/ManageExhibitors.vue'),
      },
      {
        path: 'manage-exhibitor/:exhibitor',
        name: 'ManageExhibitor',
        component: () => import('@/views/Exhibitor/ExhibitorManager.vue'),
        children: [
          {
            path: '',
            name: 'ManageExhibitorDashboard',
            component: () => import('@/views/Exhibitor/Dashboard.vue'),
          },
          {
            path: 'pending',
            name: 'ManageNotPublished',
            component: () => import('@/views/Exhibitor/NotPublished.vue'),
          },
          {
            path: 'visitors',
            name: 'ManageExhibitorVisitors',
            component: () => import('@/views/Exhibitor/Visitors.vue'),
            children: [
              {
                path: 'assign',
                name: 'ManageExhibitorVisitorsAssign',
                component: () => import('@/views/Exhibitor/VisitorContacts/Actions/Assign.vue'),
              },
              {
                path: 'scanner',
                name: 'ManageExhibitorVisitorsScanner',
                component: () => import('@/views/Exhibitor/VisitorContacts/Actions/Scanner.vue'),
              },
              {
                path: 'nfc-reader',
                name: 'ManageExhibitorVisitorsNFCReader',
                component: () => import('@/views/Exhibitor/VisitorContacts/Actions/NFCReader.vue'),
              },
              {
                path: 'details/:productInteraction',
                name: 'ManageExhibitorVisitorsAssign',
                component: () =>
                  import('@/views/Exhibitor/ProductInteractions/Actions/Details.vue'),
              },
            ],
          },
          {
            path: 'exports',
            name: 'ManageExhibitorExports',
            component: () => import('@/views/Exhibitor/Exports/Exports.vue'),
          },
          {
            path: 'product/:product',
            name: 'ManageExhibitorProduct',
            component: () => import('@/views/Visitor/Products/Product.vue'),
            children: [
              {
                path: 'edit',
                name: 'ManageExhibitorEditProduct',
                component: () => import('@/views/Exhibitor/Products/Actions/EditProduct.vue'),
              },
            ],
          },
          {
            path: 'products',
            name: 'ManageExhibitorProducts',
            component: () => import('@/views/Exhibitor/Products/Products.vue'),
            children: [
              {
                path: 'new',
                name: 'ManageExhibitorNewProduct',
                component: () => import('@/views/Exhibitor/Products/Actions/NewProduct.vue'),
              },
            ],
          },
          {
            path: 'team',
            name: 'ManageExhibitorTeam',
            component: () => import('@/views/Exhibitor/Team/Team.vue'),
            children: [
              {
                path: 'invite',
                name: 'ManageExhibitorTeamInvite',
                component: () => import('@/views/Exhibitor/Team/Actions/Invite.vue'),
              },
            ],
          },
          {
            path: 'sessions',
            name: 'ManageExhibitorSessions',
            component: () => import('@/views/Exhibitor/Sessions/Sessions.vue'),
            children: [
              {
                path: 'new-session',
                name: 'ManageExhibitorNewSession',
                component: () => import('@/views/Exhibitor/Sessions/Actions/NewSession.vue'),
              },
              {
                path: 'details/:session',
                name: 'ManageExhibitorSessionDetails',
                component: () => import('@/views/Exhibitor/Sessions/Actions/Details.vue'),
              },
              {
                path: 'edit/:session',
                name: 'ManageExhibitorEditSession',
                component: () => import('@/views/Exhibitor/Sessions/Actions/EditSession.vue'),
              },
            ],
          },

          {
            path: 'session-details/:session',
            name: 'ManageExhibitorSessionDetails',
            component: () => import('@/views/Visitor/Sessions/Actions/Details.vue'),
          },
          {
            path: 'session/:session',
            name: 'ManageExhibitorSession',
            component: () => import('@/views/Exhibitor/Sessions/Session.vue'),
          },

          {
            path: 'profile',
            name: 'ManageExhibitorProfile',
            component: () => import('@/views/Visitor/Exhibitors/Actions/Exhibitor.vue'),
            children: [
              {
                path: 'qrcode',
                name: 'ManageExhibitorQRCode',
                component: () => import('@/views/Exhibitor/QRCode.vue'),
              },
              {
                path: 'edit',
                name: 'ManageExhibitorForm',
                component: () => import('@/views/Exhibitor/Form/Form.vue'),
              },
            ],
          },
          {
            path: 'exhibitor/:exhibitor',
            component: () => import('@/views/Visitor/Exhibitors/Actions/Exhibitor.vue'),
            name: 'ManageExhibitorExhibitor',
            children: [
              {
                path: 'session/:session',
                name: 'ManageExhibitorExhibitorSession',
                component: () => import('@/views/Visitor/Sessions/Actions/Details.vue'),
              },

              {
                path: 'vote',
                name: 'ManageExhibitorExhibitorVote',
                component: () => import('@/views/Visitor/Exhibitors/Actions/Vote.vue'),
              },
            ],
          },
          {
            path: 'visitor/:visitor',
            name: 'ManageExhibitorVisitorProfile',
            component: () => import('@/views/Exhibitor/VisitorContacts/VisitorProfile.vue'),
            children: [
              {
                path: 'invite',
                name: 'ManageExhibitorVisitorsInvite',
                component: () => import('@/views/Exhibitor/VisitorContacts/Actions/Invite.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'manage-event',
        name: 'ManageEvent',
        component: () => import('@/views/Manager/EventManager.vue'),
        children: [
          {
            path: '',
            name: 'ManageEventDashboard',
            component: () => import('@/views/Manager/Dashboard.vue'),
          },
          {
            path: 'visitors',
            name: 'ManageEventVisitors',
            component: () => import('@/views/Manager/VisitorContacts/VisitorContacts.vue'),
            children: [
              {
                path: 'assign',
                name: 'ManageEventVisitorsAssign',
                component: () => import('@/views/Manager/VisitorContacts/Actions/Assign.vue'),
              },
            ],
          },
          {
            path: 'exports',
            name: 'ManageEventExports',
            component: () => import('@/views/Manager/Exports/Exports.vue'),
          },
          {
            path: 'check-in',
            name: 'ManageEventCheckIn',
            component: () => import('@/views/Manager/Check-In/Check-In.vue'),
          },
          {
            path: 'exhibitors',
            name: 'ManageEventExhibitors',
            component: () => import('@/views/Manager/Exhibitors/Exhibitors.vue'),
            children: [
              {
                path: 'details/:exhibitor',
                name: 'ManageEventExhibitorDetails',
                component: () => import('@/views/Manager/Exhibitors/Actions/TicketAllowances.vue'),
              },
              {
                path: 'booklet-sample',
                name: 'BookletSample',
                component: () => import('@/views/Manager/Exhibitors/Actions/BookletSample.vue'),
              },
            ],
          },
          {
            path: 'session/:session',
            name: 'ManageEventSession',
            component: () => import('@/views/Manager/Sessions/Session.vue'),
          },

          {
            path: 'product/:product',
            name: 'ManageEventProduct',
            component: () => import('@/views/Visitor/Products/Product.vue'),
            children: [
              {
                path: 'edit',
                name: 'ManageEventEditProduct',
                component: () => import('@/views/Manager/Products/Actions/EditProduct.vue'),
              },
            ],
          },
          {
            path: 'forms',
            name: 'ManageEventForms',
            component: () => import('@/views/Manager/Forms/Forms.vue'),
            children: [
              {
                path: 'create',
                name: 'ManageEventNewForm',
                component: () => import('@/views/Manager/Forms/Actions/NewForm.vue'),
              },
            ],
          },
          {
            path: 'promo-codes',
            name: 'ManageEventPromoCodes',
            component: () => import('@/views/Manager/PromoCodes/PromoCode.vue'),
            children: [
              {
                path: 'create',
                name: 'ManageEventNewPromoCode',
                component: () => import('@/views/Manager/PromoCodes/Actions/NewPromoCode.vue'),
              },
              {
                path: 'edit/:promoCode',
                name: 'ManageEventEditPromoCode',
                component: () => import('@/views/Manager/PromoCodes/Actions/EditPromoCode.vue'),
              },
            ],
          },
          {
            path: 'addons',
            name: 'ManageEventAddons',
            component: () => import('@/views/Manager/Addons/Addons.vue'),
            children: [
              {
                path: 'create',
                name: 'ManageEventNewAddon',
                component: () => import('@/views/Manager/Addons/Actions/NewAddon.vue'),
              },
              {
                path: 'edit/:addon',
                name: 'ManageEventEditAddon',
                component: () => import('@/views/Manager/Addons/Actions/EditAddon.vue'),
              },
              {
                path: 'categories',
                name: 'ManageEventAddonCategories',
                component: () => import('@/views/Manager/Addons/Actions/Categories.vue'),
              },
            ],
          },
          {
            path: 'addon/:addon',
            name: 'ManageEventAddon',
            component: () => import('@/views/Manager/Addons/Addon.vue'),
            children: [
              {
                path: 'edit',
                name: 'ManageEventEditAddon',
                component: () => import('@/views/Manager/Addons/Actions/EditAddon.vue'),
              },
            ],
          },
          {
            path: 'tickets',
            name: 'ManageEventTickets',
            component: () => import('@/views/Manager/Tickets/Tickets.vue'),
            children: [
              {
                path: 'create',
                name: 'ManageEventNewTicket',
                component: () => import('@/views/Manager/Tickets/Actions/NewTicket.vue'),
              },
              {
                path: 'edit/:ticket',
                name: 'ManageEventEditTicket',
                component: () => import('@/views/Manager/Tickets/Actions/EditTicket.vue'),
              },
            ],
          },
          {
            path: 'live',
            name: 'LiveMenu',
            component: () => import('@/views/Manager/Live/Live.vue'),
            children: [
              {
                path: 'edit/:visitor',
                name: 'LiveMenuEdit',
                component: () => import('@/views/Manager/Live/Actions/Edit.vue'),
              },
              {
                path: 'create',
                name: 'LiveMenuCreate',
                component: () => import('@/views/Manager/Live/Actions/Create.vue'),
              },
              {
                path: 'buy',
                name: 'LiveMenuTicket',
                component: () => import('@/views/Manager/Live/Actions/TicketPurchase.vue'),
              },
            ],
          },
          {
            path: 'ticket/:ticket',
            name: 'ManageEventTicket',
            component: () => import('@/views/Manager/Tickets/Ticket.vue'),
            children: [
              {
                path: 'edit',
                name: 'ManageEventEditTicket',
                component: () => import('@/views/Manager/Tickets/Actions/EditTicket.vue'),
              },
            ],
          },
          {
            path: 'form/:form',
            name: 'ManageEventForm',
            component: () => import('@/views/Manager/Forms/Form.vue'),
            children: [
              {
                path: 'new-field',
                name: 'ManageEventNewField',
                component: () => import('@/views/Manager/Forms/Actions/NewField.vue'),
              },
              {
                path: 'field/:field',
                name: 'ManageEventEditField',
                component: () => import('@/views/Manager/Forms/Actions/EditField.vue'),
              },
            ],
          },
          {
            path: 'products',
            name: 'ManageEventProducts',
            component: () => import('@/views/Manager/Products/Products.vue'),
            children: [
              {
                path: 'new',
                name: 'ManageEventNewProduct',
                component: () => import('@/views/Manager/Products/Actions/NewProduct.vue'),
              },
            ],
          },
          {
            path: 'sessions',
            name: 'ManageEventSessions',
            component: () => import('@/views/Manager/Sessions/Sessions.vue'),
            children: [
              {
                path: 'new-session',
                name: 'ManageEventNewSession',
                component: () => import('@/views/Manager/Sessions/Actions/NewSession.vue'),
              },
              {
                path: 'new-spacer',
                name: 'ManageEventNewSpacer',
                component: () => import('@/views/Manager/Sessions/Actions/NewSpacer.vue'),
              },
              {
                path: 'new-stage',
                name: 'ManageEventNewStage',
                component: () => import('@/views/Manager/Sessions/Actions/NewStage.vue'),
              },
              {
                path: 'details/:session',
                name: 'ManageEventSessionDetails',
                component: () => import('@/views/Manager/Sessions/Actions/Details.vue'),
              },

              {
                path: 'details/:session/assign-manager',
                name: 'ManageEventSessionDetailsAssignManager',
                component: () => import('@/views/Manager/Sessions/Actions/AssignManager.vue'),
              },
              {
                path: 'edit/:session',
                name: 'ManageEventEditSession',
                component: () => import('@/views/Manager/Sessions/Actions/EditSession.vue'),
              },
            ],
          },

          {
            path: 'exhibitor/:exhibitor',
            component: () => import('@/views/Manager/Exhibitors/Exhibitor.vue'),
            name: 'ManageEventExhibitor',
            children: [
              {
                path: 'session/:session',
                name: 'ManageEventExhibitorSession',
                component: () => import('@/views/Manager/Sessions/Actions/Details.vue'),
              },
              {
                path: 'settings',
                name: 'ManageEventExhibitorSettings',
                component: () => import('@/views/Manager/Exhibitors/Actions/Settings.vue'),
              },
              {
                path: 'allowances',
                name: 'ManageEventExhibitorAllowances',
                component: () => import('@/views/Manager/Exhibitors/Actions/TicketAllowances.vue'),
              },
            ],
          },
          {
            path: 'visitor/:visitor',
            name: 'ManageEventVisitorProfile',
            component: () => import('@/views/Manager/VisitorContacts/VisitorProfile.vue'),
            children: [
              {
                path: 'edit',
                name: 'ManageEventVisitorProfileEdit',
                component: () => import('@/views/Manager/VisitorContacts/Actions/EditFields.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'manage-visitors',
        name: 'ManageVisitors',
        component: () => import('@/views/ManageVisitors.vue'),
      },
    ],
  },

  {
    path: '*',
    name: 'PageNotFound',
    component: () => import('@/views/PageNotFound.vue'),
  },
];
const router = new VueRouter({
  routes,
  mode: process.env.VUE_APP_ROUTER_MODE, // this needs to change depending on whether it's a web app or native app
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-active',
});

export default router;
