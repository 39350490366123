export interface INavbarState {
  opened: boolean;
  bottom: number;
}

const state: INavbarState = {
  opened: false,
  bottom: 0,
};

const getters = {
  navbarOpened: (state: INavbarState) => state.opened,
  navbarBottom: (state: INavbarState) => state.bottom,
};

const mutations = {
  setOpened(state: INavbarState, opened: boolean) {
    state.opened = opened;
  },
  setBottom(state: INavbarState, bottom: number) {
    state.bottom = bottom;
  },
};

export default {
  state,
  getters,
  mutations,
};
