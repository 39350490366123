import { ICommonTranslations } from '@/translations/default/common';

const common: Partial<ICommonTranslations> = {
  exhibitors: 'Candidatos',
  exhibitor: 'Candidato',
  visitors: 'Visitantes',
  see_as_exhibitor: 'Ver como candidato',
  manage_exhibitors: 'Gerir candidatos',
  exhibitor_sessions: 'Sessões de candidatos',
};

export default common;
