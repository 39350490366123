import {
  IVisitorsDashboardTranslations,
  IVisitorSidebar,
  IVisitorSettingsTranslations,
  IVisitorExhibitorTranslations,
  IVisitorOnboardingTranslations,
  IVisitorNetworkingTranslations,
  IVisitorSessions,
  IVisitorExhibitorSessions,
  IVisitorProfile,
  IVisitorProducts,
} from '@/translations/default/visitors';

const dashboard: IVisitorsDashboardTranslations = {
  home: 'Accueil',
  information: 'Profil',
  networking: 'Réseautage',
  networking_description: "Rencontrer d'autres visiteurs",
  sessions: 'Calendrier',
  sessions_description: 'Nombre de séances dans le calendrier des événements :',
  sessions_btn: 'Calendrier des événements',
  name: 'Nom du visiteur :',
  edit: 'Éditer',
  exhibitors: 'Exposants',
  nrOfInteractions: "Nombre de fois où j'ai partagé mes contacts :",
  seeAll: 'Voir les exposants',
  download: 'Télécharger',
  join_networking: 'Rejoindre la zone de réseautage',
  event_finished: "L'événement est terminé",
  event_not_start: "L'événement n'a pas encore commencé",
  featured_products: 'Featured products',
  featured_sessions: 'Featured sessions',
  featured_exhibitors: 'Featured brands',
  upcoming_exhibitors: 'Next sessions',
};

const sidebar: IVisitorSidebar = {
  title: 'Visite',
  home: 'Accueil',
  exhibitors: 'Exposants',
  settings: 'Profil',
  sessions: 'Calendrier',
  exhibitor_sessions: 'Mon agenda',
  event_sessions: 'Calendrier des événements',
  myCalendar: 'Mon agenda',
  eventCalendar: 'Calendrier des événements',
};

const settings: IVisitorSettingsTranslations = {
  title: 'Paramètres du visiteur',
  basicInfo: 'Informations de base',
  save: 'Sauvegarder',
  additionalInfo: 'Informations complémentaires',
  formError: 'Aucune information trouvée, formulaire non disponible.',
};

const exhibitors: IVisitorExhibitorTranslations = {
  title: 'Exposants',
  subtitle: "Voir les exposants et consulter leurs offres d'emploi",
  download: 'Télécharger',
  noExhibitors: 'Aucun exposant à afficher.',
  exhibitor_information: 'Informations sur l’exposant',
  contact_success: 'Vos informations ont été partagées avec succès !',
  all_exhibitors: 'Tous les exposants',
  interacted_exhibitors: "Exposants avec lesquels j'ai interagi",
  not_interacted_exhibitors: "Exposants avec lesquels je n'ai pas interagi",
  filter_exhibitors: 'Filtrer les exposants',
  contact_request: 'Laisser mon contact',
  company_info: 'Informations sur l’exposant',
  job_offers: 'Offres',
  apply_to_job_offer: 'Je suis intéressé',
  job_offer_info: "Informations sur l'offre",
  cover_letter: 'Pourquoi êtes-vous intéressé ?',
  cover_letter_text:
    "Faites savoir à l'exposant pourquoi vous êtes intéressé et / ou posez-lui une question sur l'offre",
  your_info_will_be_shared: "Vos informations seront partagées avec l'exposant",
  submit_my_application: 'Soumettre',
  successfully_submited: 'Soumis avec succès ',
  already_applied: 'Vous avez déjà partagé votre intérêt pour cette offre.',
  schedule_interview: 'Réservez votre place',
  select_time_slot: 'Choisissez une plage horaire pour y accéder',
  all_visitors: 'Tous les visiteurs',
};

const onboarding: DeepPartial<IVisitorOnboardingTranslations> = {
  basicInformation: {
    title: 'Parlez-nous de votre visiteur',
    createVisitor: 'Créer un visiteur',
    modal: {
      title: 'Attention',
      subtitle: "Si vous souhaitez gérer votre visiteur, veuillez d'abord vous connecter.",
      skip: 'Sauter',
      createAccount: 'Créer un compte',
      login: 'Connexion',
    },
    already_registered: '¡Ya estás registrado en este evento!',
    send_login_link:
      'Haga clic aquí para recibir un correo electrónico con el link de inicio de sesión.',
  },
  tickets: {
    title: 'Billets',
    subtitle: 'Achetez des billets ou échangez votre code de billet',
    message_head:
      "Si vous avez besoin d'acheter des billets en gros et de vous inscrire ultérieurement, veuillez",
    to_buy_tickets: 'para comprar bilhetes;',
    copy_ticket_code: 'Copie o código que lhe enviaremos para o email e use-o para se registar.',
    ticket_code_invalid: 'El código de la entrada no es válido o ya se ha utilizado',
    ticket_code_label: "Code d'enregistrement",
    ticket_code_placeholder: 'Insira o seu código',
    use_ticket_code: 'Utilisez un code de billet',
  },
  managedFields: {
    title: 'Informations complémentaires',
  },
  review: {
    title: 'Vérifier les informations',
    name: 'Nom',
    proceed_to_payment: 'Procéder au paiement',
    register_someone_else: "Enregistrer quelqu'un d'autre",
  },
  checkout: {
    vat_label: 'TVA',
    country_label: 'Pays',
    country_default: 'Sélectionnez votre pays',
    city_label: 'Ville',
    address_label: 'Adresse',
    zip_code: 'Code postal',
    payment_method_card_label: 'Carte de crédit ou de débit',
    payment_method: 'Mode de paiement',
    payment_processed: 'Paiement traité.',
    check_your_phone: 'Veuillez vérifier votre téléphone.',
    payment_failed: 'Le paiement a échoué. Please try again.',
    payment_details_sent: 'Nous avons envoyé les détails du paiement à votre e-mail.',
    mbref_payment_message: 'Nous avons envoyé une référence MB à votre email.',
  },
  completed: {
    title: 'Merci pour votre inscription!',
    subtitle:
      'Veuillez vérifier votre boîte de réception, nous vous avons envoyé un courriel de confirmation.',
    manage: 'Gérer',
  },
  back: 'Retour',
  next: 'Suivant',
  submit: 'Soumettre',
  not_allowed_title: 'Su cuenta no ha sido aprobada',
  not_allowed_subtitle: 'Espere la aprobación del administrador del evento',
  registration_slow: "L'inscription prend plus de temps que d'habitude. Veuillez patienter.",
  order_summary: 'Résumé de la commande',
};

const networking: IVisitorNetworkingTranslations = {
  title: 'Réseautage',
  subtitle: 'Parler aux autres visiteurs',
  chat: 'Discussion',
  profile: 'Profil',
  signup_title: "Vous souhaitez rencontrer d'autres visiteurs ?",
  signup_message:
    'Vous êtes sur le point de rejoindre la zone de réseautage. Vos informations seront disponibles pour les autres visiteurs.',
};

const sessions: IVisitorSessions = {
  subtitle: "Vérifiez votre inscription aux sessions de l'événement ici",
  anonymous_subtitle: "Cliquez ici et inscrivez-vous aux sessions de l'événement",
  event_sessions: "Sessions d'événements",
  exhibitor_sessions: 'Sessions pour les exposants',
  all_sessions: 'Toutes les sessions',
  join_in_15: 'Vous pourrez rejoindre 15 minutes avant le début de la session.',
  need_help: "Besoin d'aide ?",
  refresh: 'Refresh',
  troubleshoot: 'Dépannage',
  help_title: 'Vous avez des problèmes?',
  help_content: `
    <p>
     Si vous rencontrez des problèmes avec le chat ou l'audio, vérifiez que vous n'utilisez pas une connexion proxy restreinte ou un VPN.
     restreinte ou qu'un VPN est activé pour le moment.<br />
    </p>
    <p>
    Vérifiez
      <a
        href="https://www.easypcmod.com/how-to-fix-jitsi-meet-no-audio-problem-11976"
        target="_blank"
        style="text-decoration: underline"
        >ce guide</a
      > pour vous aider à résoudre tout problème non résolu par la mise à jour de vos paramètres réseau.
    </p>
    <p>
      Les ports réseau suivants doivent être ouverts sur votre pare-feu pour permettre le trafic du serveur :
    </p>
    <ul>
     <li>80 TCP – pour la vérification des certificats SSL avec Let's Encrypt. </li>
     <li>443 TCP – pour un accès général par le fournisseur d'appels vidéo.</li>
     <li>10000 UDP - pour les communications vidéo et audio sur réseau.</li>
     <li>TCP/4443</li>
    </ul>
  `,
};

const exhibitorSessions: IVisitorExhibitorSessions = {
  subtitle: 'Séances auxquelles vous êtes inscrit',
};

const profile: IVisitorProfile = {
  visitor_information: 'Informations sur le visiteur',
};

const products: IVisitorProducts = {
  title: 'Produits',
  subtitle: 'Parcourir et interagir avec les produits des exposants',
  products_same_brand: 'Offers from the same brand',
};

export default {
  dashboard,
  settings,
  exhibitors,
  onboarding,
  sidebar,
  networking,
  sessions,
  exhibitorSessions,
  profile,
  products,
};
