import { INotificationsTranslations } from '.';

const notifications: INotificationsTranslations = {
  exhibitor_invite_title: 'Ha sido invitado a una sesión con el expositor ',
  product_interaction_title: ' interactuó con ',
  interaction_title: ' compartió sus datos de contacto.',
  moderation_question: ' ha sometido una pregunta a moderación.',
};

export default notifications;
