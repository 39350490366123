import { IVisitorExhibitorTranslations, IVisitorSidebar } from '@/translations/default/visitors';

const sidebar: Partial<IVisitorSidebar> = {
  exhibitors: 'Candidates',
};

const exhibitors: Partial<IVisitorExhibitorTranslations> = {
  title: 'Candidates',
  subtitle: "See the candidates' profiles and vote",
  company_info: 'Candidate Information',
};
export default {
  exhibitors,
  sidebar,
};
