import common from './common/fr';
import exhibitors from './exhibitors/fr';
import visitors from './visitors/fr';
import managers from './managers/fr';

export default {
  common,
  exhibitors,
  visitors,
  managers,
};
