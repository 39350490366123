
import { defineComponent, PropType } from 'vue';
import { IOffcanvas } from '.';

export default defineComponent({
  props: {
    config: {
      type: Object as PropType<IOffcanvas>,
      required: true,
    },
  },
  setup(props) {
    const close = () => {
      if (props.config.close) {
        props.config.close();
      } else {
        if (props.config.onClose) {
          props.config.onClose();
        }
        props.config.isActive = false;
      }
    };
    return { close };
  },
});
