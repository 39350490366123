import {
  ISessionDetailsTranslations,
  ISessionsAssignmentTranslations,
  ISessionsCommonTranslations,
  ISessionsTranslations,
} from '.';

const details: ISessionDetailsTranslations = {
  session_has_ended: 'Cette session est terminée.',
  register_title: 'Inscrivez-vous à cette session',
  online_seats: 'Places en ligne',
  in_person_seats: 'Sièges en personne',
};

const inputs = {
  other_languages: 'Autres langues',
  session_type: 'Type de session',
  session_type_placeholder: 'Sélectionnez le type de session',
  privacy_label: 'Qui peut regarder cette session?',
  privacy_open: 'Tout le monde',
  privacy_registration: 'Enregistrement requis',
  privacy_closed: 'Exiger une invitation directe',
  video_call_url: "URL d'appel vidéo",
  video_call_url_placeholder: "Insérez l'URL de l'appel vidéo ou laissez vide",
  select_channel: 'Sélectionnez la chaîne',
  stage: 'Stage',
  stage_placeholder: 'Select a stage',
  allow_tag_not_registered: 'Autoriser un tag non enregistré',
};

const common: ISessionsCommonTranslations = {
  in_person: 'Présentiel',
  online: 'Online',
  stream: 'Stream',
  video_call: 'Appel vidéo',
  in_person_registration: 'Inscription en Présentiel',
  online_registration: 'Inscription en Ligne',
  moderation_question: 'Poser une question au modérateur',
  moderation_chat: 'Conversation avec le modérateur',
  moderation_chat_error: "Impossible d'accéder au chat de modération.",
  moderation_question_created: 'Question soumise au modérateur avec succès.',
  moderation_question_error: 'La question ne peut être soumise.',
};

const assignment: ISessionsAssignmentTranslations = {
  heading: 'Choisissez un visiteur à désigner comme gestionnaire de session',
  success: 'Visiteur nommé avec succès gestionnaire de session .',
  error: "Ce visiteur n'a pas pu être désigné comme gestionnaire de session .",
  empty_list: 'Pas de résultats !',
  assign: 'Ajouter un manager',
  remove_from: 'Supprimer le gestionnaire de',
  unassign_1: 'Do you wish to unassign',
  unassign_2: 'as a manager to this session?',
};

const sessions: ISessionsTranslations = {
  assignment,
  details,
  common,
  inputs,
};

export default sessions;
