import common from './common/pt';
import exhibitors from './exhibitors/pt';
import visitors from './visitors/pt';
import managers from './managers/pt';

export default {
  common,
  exhibitors,
  visitors,
  managers,
};
