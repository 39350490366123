import { IAuthLoginTranslations, IAuthRegisterTranslations } from '.';

const login: IAuthLoginTranslations = {
  welcome: 'Bienvenido',
  email: 'Email',
  email_placeholder: 'Introduce tu email',
  password: 'Contraseña',
  password_placeholder: 'Introduce tu contraseña',
  login: 'Login',
  log_back: 'Estás de vuelta?',
  logout: 'Logout',
  logged_out: 'Has sido logged out',
  or: 'o',
  other_account: 'Otra cuenta',
  forgot_password: '¿Olvidaste tu contraseña?',
  newAccount: 'Nueva cuenta',
  wrong_password: 'No pudimos iniciar sesión con las credenciales especificadas',
  link_sent: '¡Revisa tu bandeja de entrada, te enviamos un enlace directo para acceder al evento!',
  send_link: 'Enviar enlace de inicio de sesión',
  send_me_link: 'Envíame un email con el link de inicio de sesión',
  use_password: 'Usar contraseña para iniciar sesión',
  recover_password: 'Recuperar contraseña',
  recover_password_link_sent:
    '¡Revisa tu bandeja de entrada! Le enviamos un enlace para restablecer su contraseña.',
};

const register: IAuthRegisterTranslations = {
  newAccount: 'Nueva cuenta',
  error: 'Hubo un error creando tu usuario. Por favor, inténtalo de nuevo más tarde.',
  cancel: 'Cancelar',
  signUp: 'Regístrate',
  welcome: 'Bienvenido a Beamian!',
  confirmationEmail: 'Hemos enviado un email confirmando tu cuenta. Por favor, mira tu inbox.',
  login: 'Login',
  password_not_match: 'Las contraseñas no coinciden.',
};

export default { login, register };
