import auth from './auth/en';
import common from './common/en';
import dashboard from './dashboard/en';
import account from './account/en';
import exhibitors from './exhibitors/en';
import visitors from './visitors/en';
import sessions from './sessions/en';
import managers from './managers/en';
import root from './root/en';
import notifications from './notifications/en';
import phone_input from './phone_input/en';
import live from './live/en';

export default {
  auth,
  common,
  sessions,
  managers,
  dashboard,
  account,
  exhibitors,
  visitors,
  root,
  notifications,
  phone_input,
  live,
};
