import {
  IEventManagerAddonsTranslations,
  IEventManagerCheckInTranslations,
  IEventManagerEventsTranslations,
  IEventManagerExhibitorsTranslations,
  IEventManagerExportsTranslations,
  IEventManagerHomeTranslations,
  IEventManagerProductsTranslations,
  IEventManagerPromoCodesTranslations,
  IEventManagerSessionsTranslations,
  IManagerLegacyNavTranslations,
  IManagerTranslations,
  IEventManagerTicketsTranslations,
} from '.';

const promo_codes: IEventManagerPromoCodesTranslations = {
  discount_value: 'Discount value',
  discount_type: 'Discount type',
  discount_type_percentage: 'Percentage',
  discount_type_value: 'Value',
  promo_code_type: 'Promo-code type',
  promo_code_type_discount: 'Discount',
  promo_code_type_revealing: 'Reveal hidden tickets',
  promo_code_type_hybrid: 'Discounts hidden tickets',
};

const legacy_nav: IManagerLegacyNavTranslations = {
  event_details: 'Ereignisdetails',
  event_information: 'Ereignisinformationen',
  media_channels: 'Medienkanäle',
  team: 'Team',
  networking: 'Vernetzung',
  trends: 'Trends',
  visitor_types: 'Besuchertypen',
  packages: 'Pakete',
  exhibitors: 'Aussteller',
  exhibitor_form: 'Ausstellerformular',
  exhibitor_list: 'Ausstellerliste',
  exhibitor_cards: 'Ausstellerkarten',
  exhibitor_sessions: 'Ausstellersitzungen',
  exhibitor_categories: 'Ausstellerkategorien',
  product_form: 'Produktformular',
  product_list: 'Produktliste',
  product_categories: 'Produktkategorien',
  interactions: 'Wechselwirkungen',
  exhibitor_tickets: 'Ausstellertickets',
  exhibitor_packages: 'Ausstellerpakete',
  visitors: 'Besucher',
  tickets: 'Tickets',
  visitor_form: 'Besucherformular',
  session_areas: 'Sitzungsbereiche',
  labels: 'Labels',
  consumptions: 'Verbräuche',
  visitors_list: 'Besucherliste',
};

const checkin: IEventManagerCheckInTranslations = {
  subtitle: 'Review the visitor entrances and exits of your event',
  empty_list_title: 'No check-ins yet!',
  empty_list_subtitle: "Come back later and maybe we'll have something for you",
};

const home: IEventManagerHomeTranslations = {
  title: 'Hallo',
  subtitle: 'Willkommen bei der beamian Event Management App',
  visitor_registration: 'Besucherregistrierung',
  exhibitor_registration: 'Ausstelleranmeldung',
  general_information_title: 'Allgemeine Informationen',
  general_information_subtitle: 'Neueste Besucher-, Aussteller- und Interaktionszahlen',
  networking_subtitle: 'Besucher, die sich für Networking eingeschrieben haben',
  manage_networking_space: 'Netzwerkbereich verwalten',
  products_subtitle: 'Anzahl der erstellten Produkte',
};

const addons: IEventManagerAddonsTranslations = {
  subtitle: 'Manage add-ons',
  name_placeholder: "Enter this addon's name",
  description_placeholder: 'Describe this add-on',
  price_placeholder: 'Please insert the value in cents',
  price_label: 'Price',
  image_placeholder: '5mb max, png, jpg',
  availability_start_date_label: 'Available from',
  availability_end_date_label: 'Available until',
  time_limited_label: 'Time limited',
  time_limited_until_placeholder:
    'Select this setting if you want your addon to only be available for a limited time',
  restricted_usage_period_label: 'Restrict usage',
  restricted_usage_period_placeholder:
    'Select this setting if you want this to only be usable for a limited time',
  hidden_label: 'Hidden',
  max_quantity: 'Max quantity',
  max_quantity_per_user_label: 'Max quantity per user',
  min_quantity_per_user_label: 'Group ticket quantity',
  min_quantity_per_user_message: 'A group ticket must account for at least 2 visitors.',
  group_ticket: 'Group ticket',
  leave_ticket_empty: 'At least one add-on must be selected.',
  select_ticket: 'Select ticket',
  select_session: 'Select session',
  select_channel: 'Select channel',
  select_item: 'Select consumable',
  empty_list_title: 'No add-ons created yet',
  empty_list_subtitle: 'Start creating add-ons!',
  categories_message: 'Diese werden in Ihren Addon-Formularen verfügbar sein',
  categories_updated_message: 'Kategorienliste erfolgreich aktualisiert',
  ticket_type_restriction_label: 'Einschränkung nach Fahrscheinart',
};

const exhibitors: IEventManagerExhibitorsTranslations = {
  ticket_invites: 'Ticketvergabe',
  allowance_label: 'Zuteilungsgrenze',
  allowance_message: 'Anzahl der erlaubten Nutzungen für jedes Ticket',
  ticket_code_label: 'Ticket-Code',
  ticket_code_message: 'Aussteller können diesen Code weitergeben, um ihr Kontingent zu nutzen',
  ticket_type_default: 'Ticket-Typ auswählen',
  ticket_type_label: 'Eintrittskartenart',
  products_limit_label: 'Produktgrenzen',
  products_limit_message: 'Maximale Anzahl von Produkten, die ein Aussteller erstellen kann',
  sessions_limit_label: 'Sitzungslimits',
  sessions_limit_message: 'Maximale Anzahl von Sitzungen, die ein Aussteller erstellen kann',
  exhibitor_tier_label: 'Stufe',
  exhibitor_tier_default: 'Wählen Sie die Stufe dieses Ausstellers',
  exhibitor_tier_message:
    'Je höher die Stufe, desto prominenter erscheinen sie auf der Veranstaltung',
  exhibitor_tier_ds: 'Keine Stufe',
  exhibitor_tier: 'Stufe',
  exhibitor_qrcode_reader_ds: 'QR-Code-Scanner',
  exhibitor_qrcode_reader_message: 'Ermöglichen Sie Ausstellern das Scannen von QR-Codes',
  empty_list_title: 'Anmeldungen von Ausstellern',
  empty_list_subtitle: 'Informieren Sie die Aussteller, wo sie sich anmelden können',
};

const products: IEventManagerProductsTranslations = {
  empty_list_form_title: 'Formular',
  empty_list_form_subtitle: 'Ein Produktformular einrichten',
  empty_list_categories_title: 'Kategorien',
  empty_list_categories_subtitle: 'Produktkategorien hinzufügen',
  empty_list_product_title: 'Produkt',
  empty_list_product_subtitle: 'Ein neues Produkt erstellen',
  title: 'Freie Stellen verfügbar',
  subtitle: 'Zahl der geschaffenen Arbeitsplätze',
  empty_list_title: 'Lasst uns anfangen, Dinge zu erschaffen!',
  empty_list_subtitle: 'Bauen Sie Ihren Katalog auf',
};

const sessions: IEventManagerSessionsTranslations = {
  empty_list_title: 'Lasst uns anfangen, Dinge zu erschaffen!',
  empty_list_subtitle: 'Füllen Sie Ihren Kalender',
};

const exports: IEventManagerExportsTranslations = {
  empty_list_title: 'Kontakte exportieren',
  empty_list_subtitle: 'Holen Sie sich Ihre Kontakte!',
};

const events: IEventManagerEventsTranslations = {
  title: 'Willkommen bei beamian!',
  subtitle: 'Bitte wählen Sie ein Event',
  rolesTitle: 'Willkommen zu',
  rolesSubtitle: 'Was möchten Sie tun?',
  registerVisitor: 'Besucher registrieren',
  registerVisitorDescription:
    'Registrieren Sie sich oder eine andere Person als Besucher dieser Veranstaltung.',
  createExhibitor: 'Aussteller registrieren',
  createExhibitorDescription: 'Registrieren Sie Ihren Aussteller für diese Veranstaltung.',
  manageVisitor: 'Besucher verwalten',
  manageVisitorDescription: 'Bearbeiten Sie Besucherinformationen, siehe Interaktionen.',
  manageExhibitor: 'Aussteller verwalten',
  manageExhibitorDescription: 'Ausstellerinformationen bearbeiten, siehe Interaktionen.',
  manageEvent: 'Veranstaltung verwalten',
  manageEventDescription: 'Greifen Sie auf Ereigniseinstellungen, Informationen und Inhalte zu.',
  event_settings: 'Ereigniseinstellungen',
  new: 'New event',
  edit: 'Edit event',
  create: 'Create event',
  send_registration_invite: 'Send registration invite',
  email: 'Email',
  name: 'Name',
  name_placeholder: 'Enter event name',
  assign: 'Assign',
  delete_manager: 'Delete event manager',
  no_managers_assigned: 'This event has no event managers assigned',
  countdown_date: 'Countdown Date',
  start_date: 'Start date',
  end_date: 'End date',
  capacity: 'Capacity',
  used: 'Used',
  available: 'Available',
  location: 'Location',
  location_placeholder: 'Enter event location',
  is_published: 'Is published',
  base_cost: 'Base cost',
  max_capacity: 'Max capacity',
  delete: 'Delete event',
  assign_manager: 'Assign event manager (select 1 event only)',
  manager: 'Event manager',
  select_manager: 'Select event manager',
  timezone: 'Timezone',
  europe: 'Europe',
  lisbon: 'Lisbon',
  london: 'London',
  madrid: 'Madrid',
  berlin: 'Berlin',
  default_language: 'Default language',
  portuguese: 'Portuguese',
  spanish: 'Spanish',
  english: 'English',
  german: 'German',
  french: 'French',
  description: 'Description',
  description_placeholder: 'Enter an event description',
  image: 'Bild',
  image_placeholder: 'Ein Bild hochladen',
  background_image: 'Background image',
  background_image_placeholder: ' Ein Bild hochladen ',
  created_success: 'Event created successfuly',
  manager_email_placeholder: 'Enter user email',
  managers: 'Event managers',
  manager_added_success: 'Event manager added successfuly',
  manager_deleted_success: 'Event manager deleted successfuly',
  manager_user_not_exists:
    'User does not exist. Make sure you typed the email correctly or send a registration invitation to this email.',
  manager_setup: 'Choose name and password',
  manager_user_password: 'Password',
  manager_user_name_placeholder: 'Enter user name',
  manager_user_password_placeholder: 'Enter user password',
  setup_booklet: 'Set up booklet',
  booklet: 'Booklet',
  configure_booklet: 'If you want to send a booklet when the event ends please configure it.',
  no_fields_selected: 'No fields selected.',
  drag_drop_fields: 'Drag and drop fields to set order.',
  title_placeholder: 'Enter booklet title',
  cover: 'Cover',
  cover_placeholder: 'Upload booklet cover image',
  fields: 'Fields',
  create_booklet_success: 'Booklet created',
  create_booklet_error: 'Booklet creation failed',
  edit_booklet_success: 'Booklet edited',
  edit_booklet_error: 'Booklet editing failed',
  no_available_fields: 'No available fields',
  empty_space: 'Empty space',
  live_interactions_email: 'Live interactions email invitation',
};

const tickets: IEventManagerTicketsTranslations = {
  subtitle: 'Verwalten Sie Ihre Tickets',
  amount: 'Menge',
  canceled: 'Abgesagt',
};

const managers: IManagerTranslations = {
  legacy_nav,
  home,
  exhibitors,
  addons,
  products,
  sessions,
  exports,
  events,
  checkin,
  promo_codes,
  tickets,
};

export default managers;
