import { IDashboardTranslations } from '.';

const dashboard: IDashboardTranslations = {
  welcome: 'Platform for In-Person, Hybrid & Virtual Events',
  whatsup:
    'Beamian offers an all-in-one event management solution that works for all event scenarios.',
  contact_us: 'Contact us',
};

export default dashboard;
