import {
  IExhibitorsDashboardTranslations,
  IExhibitorTickets,
  IExhibitorExports,
  IExhibitorVisitors,
  IExhibitorSidebar,
  IExhibitorSettingsTranslations,
  IExhibitorProducsTranslations,
  IExhibitorInteractionsTranslations,
  IExhibitorOnboardingTranslations,
  IExhibitorSessions,
  IExhibitorProductInteractions,
  IExhibitorProfile,
} from '@/translations/default/exhibitors';

const dashboard: Partial<IExhibitorsDashboardTranslations> = {
  // leave empty until needed
};

const tickets: Partial<IExhibitorTickets> = {
  // leave empty until needed
};

const exports: Partial<IExhibitorExports> = {
  // leave empty until needed
};

const visitors: Partial<IExhibitorVisitors> = {
  title: 'Candidates',
  subtitle: 'See candidates information & invite candidates to private sessions.',
};

const sidebar: Partial<IExhibitorSidebar> = {
  exhibitor_interactions: 'Spontaneous applications',
  interactions: 'Candidates',
  products: 'Job offers',
  product_interactions: 'Applications for job offers',
};

const settings: Partial<IExhibitorSettingsTranslations> = {
  // leave empty until needed
};

const products: Partial<IExhibitorProducsTranslations> = {
  title: 'Job offers',
  subtitle: 'Create, edit and delete job offers',
  no_products: 'No job offers available',
  new_product: 'New job offer',
  create_new_product: 'Create a new job offer',
  edit_product: 'Edit job offer',
  interactions_exist_cannot_delete:
    "This job offer already has applicants, therefore it can't be deleted",
};

const interactions: Partial<IExhibitorInteractionsTranslations> = {
  title: 'Spontanbewerbungen',
  subtitle: 'Bewerbungen überprüfen und Meetings planen',
  noInteractions: 'Dieser Aussteller hat keine Bewerbungen.',
};

const onboarding: Partial<IExhibitorOnboardingTranslations> = {
  // leave empty until needed
};

const sessions: Partial<IExhibitorSessions> = {
  private_session_warning:
    "Create a private session to invite specific candidates to a meeting. If it's a public session, any candidate will be able to see and register for that session.",
};

const productInteractions: Partial<IExhibitorProductInteractions> = {
  title: 'Applications for job offers',
  subtitle: 'Check job applications and schedule meetings',
  product_name: 'Job offer',
  interaction_name: 'Candidate name',
  application: 'Apply',
};

const profile: Partial<IExhibitorProfile> = {
  products: 'Job offers',
};

export default {
  dashboard,
  settings,
  interactions,
  onboarding,
  sidebar,
  sessions,
  products,
  productInteractions,
  exports,
  visitors,
  profile,
  tickets,
};
