const activityCenter = {
  title: 'Centro de actividad',
  noRecentActivity: 'No hay actividad reciente',
  inProgress: 'En progreso',
  recentFiles: 'Archivos recientes',
};
const messages = {
  title: 'Mensajes',
};

const scanner = {
  title: 'Escáner de código QR',
};

const helpdesk = {
  title: 'Help desk',
};

const loginPrompt = {
  title: 'Es necesario iniciar sesión',
  text: 'Inicie sesión como invitado de este evento para acceder a esta función',
};

const sessionEnded = {
  title: 'Has abandonado la sesión!',
  subtitle: 'Seguir navegando?',
  backToCalendar: 'Volver al calendario',
  backToEvent: 'Volver a casa',
};

const cookiesConsent = {
  title: 'Cookie consent',
  text: 'Cookies help us deliver the best experience on our website. By using our website, you agree to the use of cookies.',
};

const nfc_reader = {
  title: 'Lector NFC',
};

const nfc_success_reading = {
  title: 'La etiqueta NFC se escaneó correctamente. Espere mientras la validamos.',
};

const nfc_tag_not_found = {
  title: 'La etiqueta no está vinculada a ningún visitante del evento.',
};

const nfc_paired = {
  title: 'Lector NFC emparejado.',
};

const select_option_register_interactions = {
  title: 'Seleccione una opción para registrar interacciones',
};

export default {
  activityCenter,
  messages,
  scanner,
  helpdesk,
  loginPrompt,
  sessionEnded,
  cookiesConsent,
  nfc_reader,
  nfc_success_reading,
  nfc_tag_not_found,
  nfc_paired,
  select_option_register_interactions,
};
