
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  props: {
    isActive: Boolean,
  },

  setup(props) {
    const state = reactive({
      isActive: props.isActive,
    });
    const toggle = () => {
      state.isActive = !state.isActive;
    };
    return { state, toggle };
  },
});
