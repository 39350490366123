var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('transition',{attrs:{"name":"fade-from-right"}},[_c('div',{staticClass:"notification popup-notification is-active",class:{
      'is-info': _vm.config.type === 'info',
      'is-success': _vm.config.type === 'success',
      'is-primary': _vm.config.type === 'primary',
      'is-warning': _vm.config.type === 'warning',
      'is-danger': _vm.config.type === 'danger',
    }},[_c('button',{staticClass:"delete",on:{"click":function($event){return _vm.close()}}}),_vm._t("default"),(_vm.config.message)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.config.message)}}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }