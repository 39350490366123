import { ActionContext } from 'vuex';
import { IParams } from '@/api';
import { getEvents } from '@/api/events';
import { IEvent, IEventV2 } from '@/models/event';

import { IState, IObjectState, IStateObject } from '..';
/*
  In a real scenario, all these interfaces would be under the "model" folder.
*/
export interface IStateEvent extends IObjectState, IEvent {}

export type IEventsState = IStateObject<IStateEvent>;

export interface IEventsParams extends IParams {
  search?: string;
  type?: string;
}

const state: IEventsState = {
  list: [],

  loading: false,
  message: '',
  error: false,
};

const addStateProps = (events: (IEvent | IEventV2)[]) =>
  events.map((event) => ({
    ...event,
    loading: false,
    error: false,
    message: '',
  }));

const getters = {
  eventsState: (state: IEventsState) => state,
};

const actions = {
  fetchEvents(context: ActionContext<IEventsState, IState>, params: IEventsParams) {
    context.commit('setEventsLoading');

    getEvents(params)
      .then((response) => {
        const events = {
          list: addStateProps(response.data.results),
          page: response.data.current,
          page_count: response.data.page_count,
          item_count: response.data.item_count,
        };

        if (params.reset) {
          context.commit('resetEvents');
        }

        context.commit('setEvents', events);
      })
      .catch((err) => {
        context.commit('setEventsError', err);
      });
  },
};

const mutations = {
  setEventsError: (state: IEventsState, message: string) => {
    state.loading = false;
    state.error = true;
    state.message = message;
  },

  setEventsLoading: (state: IEventsState) => {
    state.loading = true;
    state.error = false;
  },

  setEvents: (state: IEventsState, events: IEventsState) => {
    state.error = false;
    state.loading = false;
    state.list = [...state.list, ...events.list];
    state.page = events.page;
    state.page_count = events.page_count;
    state.item_count = events.item_count;
  },

  resetEvents: (state: IEventsState) => {
    state.error = false;
    state.loading = false;
    state.list = [];
    state.page = 0;
    state.page_count = 0;
    state.item_count = 0;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
