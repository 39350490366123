import {
  IExhibitorsDashboardTranslations,
  IExhibitorTickets,
  IExhibitorExports,
  IExhibitorVisitors,
  IExhibitorSidebar,
  IExhibitorSettingsTranslations,
  IExhibitorProducsTranslations,
  IExhibitorInteractionsTranslations,
  IExhibitorOnboardingTranslations,
  IExhibitorSessions,
  IExhibitorProductInteractions,
  IExhibitorProfile,
} from '@/translations/default/exhibitors';

const dashboard: Partial<IExhibitorsDashboardTranslations> = {
  // leave empty until needed
};

const tickets: Partial<IExhibitorTickets> = {
  // leave empty until needed
};

const exports: Partial<IExhibitorExports> = {
  // leave empty until needed
};

const visitors: Partial<IExhibitorVisitors> = {
  // leave empty until needed
};

const sidebar: Partial<IExhibitorSidebar> = {
  title: 'Marca',
  exhibitor_interactions: 'Interessados na marca',
  product_interactions: 'Interessados em produtos',
  exhibitor_sessions: 'Sessões da marca',
  products: 'Produtos',
};

const settings: Partial<IExhibitorSettingsTranslations> = {
  // leave empty until needed
};

const products: Partial<IExhibitorProducsTranslations> = {
  title: 'Produtos',
  subtitle: 'Criar, editar e apagar produtos',
  no_products: 'Nenhum pedido de informação disponível.',
  new_product: 'Novo produto',
  edit_product: 'Editar produto',
  interactions_exist_cannot_delete:
    'Esta produto já tem interações, por isso não é possível eliminar.',
};

const interactions: Partial<IExhibitorInteractionsTranslations> = {
  title: 'Visitantes interessados na marca',
  subtitle: 'Consultar lista de visitantes interessados na marca',
  noInteractions: 'Este marca não tem interações.',
};

const onboarding: Partial<IExhibitorOnboardingTranslations> = {
  // leave empty until needed
};

const sessions: Partial<IExhibitorSessions> = {
  private_session_warning:
    'Crie uma sessão privada para convidar visitantes específicos para uma reunião. Se for uma sessão pública, qualquer visitante poderá visualizar e registar-se na sua sessão.',
};

const productInteractions: Partial<IExhibitorProductInteractions> = {
  title: 'Visitantes interessados em produtos',
  subtitle: 'Consultar a lista de visitantes interessados nos seus produtos e agendar reuniões',
  product_name: 'Produto',
  interaction_name: 'Nome do visitante',
  application: 'Informação do contacto',
};

const profile: Partial<IExhibitorProfile> = {
  products: 'Produtos',
};

export default {
  dashboard,
  settings,
  interactions,
  onboarding,
  sidebar,
  sessions,
  products,
  productInteractions,
  exports,
  visitors,
  profile,
  tickets,
};
