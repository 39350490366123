import { ActionContext } from 'vuex';
import { assignSessionManager, getVisitorContacts, getVisitors } from '@/api/visitors';
import {
  IVisitorContactsParams,
  IVisitorsParams,
  IVisitor,
  ISessionManagerParams,
} from '@/models/visitor';

import { IState, IObjectState, IStateObject } from '@/store';

/*
  In a real scenario, all these interfaces would be under the "model" folder.
*/
export interface IStateVisitor extends IObjectState, IVisitor {}

export type IVisitorsState = IStateObject<IStateVisitor>;

const state: IVisitorsState = {
  list: [],
  page: 1,
  page_count: 0,
  item_count: 0,
  loading: false,
  message: '',
  error: false,
};

const addStateProps = (visitors: IVisitor[]) =>
  visitors.map((visitor) => ({
    ...visitor,
    loading: false,
    error: false,
    message: '',
  }));

const getters = {
  visitors: (state: IVisitorsState) => state.list,
  visitorsState: (state: IVisitorsState) => state,
};

const actions = {
  assignSessionManager(
    context: ActionContext<IVisitorsState, IState>,
    params: ISessionManagerParams,
  ) {
    assignSessionManager(params)
      .then(() => {
        context.commit('assignSessionManager');
      })
      .catch((err) => {
        context.commit('assignSessionManagerError', err.request);
      });
  },
  fetchVisitors(context: ActionContext<IVisitorsState, IState>, params: IVisitorsParams) {
    context.commit('setVisitorsLoading');

    getVisitors(params)
      .then((response) => {
        const visitors = {
          list: addStateProps(response.data.results),
          page: response.data.current,
          page_count: response.data.page_count,
          item_count: response.data.item_count,
        };

        context.commit('setVisitors', visitors);
      })
      .catch((err) => {
        context.commit('setVisitorsError', err.request);
      });
  },
  fetchVisitorContacts(
    context: ActionContext<IVisitorsState, IState>,
    params: IVisitorContactsParams,
  ) {
    context.commit('setVisitorsLoading');
    getVisitorContacts(params)
      .then((response) => {
        const visitors = {
          list: addStateProps(response.data.results),
          page: response.data.current,
          page_count: response.data.page_count,
          item_count: response.data.item_count,
        };

        context.commit('setVisitors', visitors);
      })
      .catch((err) => {
        context.commit('setVisitorsError', err.request);
      });
  },
};

const mutations = {
  assignSessionManager: (state: IVisitorsState, message: string) => {
    state.loading = false;
    state.error = false;
    state.message = message;
  },
  assignSessionManagerError: (state: IVisitorsState, message: string) => {
    state.loading = false;
    state.error = true;
    state.message = message;
  },
  setVisitorsError: (state: IVisitorsState, message: string) => {
    state.loading = false;
    state.error = true;
    state.message = message;
  },

  setVisitorError: (
    state: IVisitorsState,
    { failedVisitor, message }: { failedVisitor: IStateVisitor; message: string },
  ) => {
    state.list = state.list.map((visitor) =>
      visitor.id === failedVisitor.id
        ? {
            ...visitor,
            loading: false,
            error: true,
            message,
          }
        : visitor,
    );
  },

  setVisitorsLoading: (state: IVisitorsState) => {
    state.loading = true;
    state.error = false;
  },

  setVisitors: (state: IVisitorsState, visitors: IVisitorsState) => {
    state.error = false;
    state.loading = false;
    state.list = visitors.list;
    state.page = visitors.page;
    state.page_count = visitors.page_count;
    state.item_count = visitors.item_count;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
