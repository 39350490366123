import common from './common/en';
import exhibitors from './exhibitors/en';
import visitors from './visitors/en';
import managers from './managers/en';

export default {
  common,
  exhibitors,
  visitors,
  managers,
};
