import axios from 'axios';
import { IProductParams } from '@/store/modules/products';
import { IProduct, IProductItem } from '@/models/product';
import { Resolve, IData, IParams } from '.';
import config from './config';
import { ILimit } from '@/models/exhibitor';

const url = process.env.VUE_APP_SERVER_PATH;

export const getProducts = (params: IProductParams) =>
  new Promise((resolve: Resolve<IData<IProduct>>, reject) => {
    if (!params.page_size) {
      params.page_size = 100;
    }
    if (!params.page) {
      params.page = 1;
    }

    let requestUrl = `${url}/v2/products?page_size=${params.page_size}&page=${params.page}`;

    if (params.event) {
      requestUrl += `&event=${params.event}`;
    }
    if (params.exhibitor_sponsor_level) {
      requestUrl += `&exhibitor_sponsor_level=${params.exhibitor_sponsor_level}`;
    }
    if (params.home_page !== undefined) {
      requestUrl += `&home_page=${params.home_page}`;
    }

    if (params.submitted !== undefined) {
      requestUrl += `&submitted=${params.submitted}`;
    }

    if (params.search !== undefined) {
      requestUrl += `&search=${params.search}`;
    }

    if (params.exhibitor !== undefined) {
      requestUrl += `&exhibitor=${params.exhibitor}`;
    }

    axios
      .get(`${requestUrl}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getProductLimits = (params: IParams & { exhibitor: number }) =>
  new Promise((resolve: Resolve<IData<ILimit>>, reject) => {
    let requestUrl = `${url}/v2/product-limits?page_size=100&ordering=-id`;
    if (params.exhibitor) {
      requestUrl += `&exhibitor=${params.exhibitor}`;
    }

    axios
      .get(requestUrl, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const addProductLimit = (payload: ILimit) =>
  new Promise((resolve: Resolve<IData<ILimit>>, reject) => {
    const requestUrl = `${url}/v2/product-limits`;

    axios
      .post(requestUrl, payload, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const editProductLimit = (payload: ILimit) =>
  new Promise((resolve: Resolve<IData<ILimit>>, reject) => {
    const requestUrl = `${url}/v2/product-limits/${payload.id}`;

    axios
      .patch(requestUrl, payload, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getProductList = (params: IProductParams) =>
  new Promise((resolve: Resolve<IData<IProductItem>>, reject) => {
    if (!params.page_size) {
      params.page_size = 100;
    }
    if (!params.page) {
      params.page = 1;
    }
    let requestUrl = `${url}/v2/product-list?page_size=${params.page_size}&page=${params.page}`;

    if (params.event) {
      requestUrl += `&event=${params.event}`;
    }

    if (params.favorite !== undefined) {
      requestUrl += `&favourite=${params.favorite}`;
    }
    if (params.home_page !== undefined) {
      requestUrl += `&home_page=${params.home_page}`;
    }
    if (params.submitted !== undefined) {
      requestUrl += `&submitted=${params.submitted}`;
    }

    if (params.exhibitor_sponsor_level) {
      requestUrl += `&exhibitor_sponsor_level=${params.exhibitor_sponsor_level}`;
    }

    if (params.search !== undefined) {
      requestUrl += `&search=${params.search}`;
    }

    if (params.exhibitor) {
      requestUrl += `&exhibitor=${params.exhibitor}`;
    }

    if (params.category_name !== undefined) {
      requestUrl += `&category_name=${params.category_name}`;
    }

    if (params.category) {
      requestUrl += `&category_id=${params.category}`;
    }
    if (params.subcategory) {
      requestUrl += `&subcategory_id=${params.subcategory}`;
    }
    if (params.subcategory_name !== undefined) {
      requestUrl += `&subcategory_name=${params.subcategory_name}`;
    }

    axios
      .get(`${requestUrl}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getProduct = (product: number) =>
  new Promise((resolve: Resolve<IProduct>, reject) => {
    const requestUrl = `${url}/v2/products/${product}`;

    axios
      .get(`${requestUrl}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getProductItem = (product: number) =>
  new Promise((resolve: Resolve<IProductItem>, reject) => {
    const requestUrl = `${url}/v2/product-list/${product}`;

    axios
      .get(`${requestUrl}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const createProduct = (product: IProduct) =>
  new Promise((resolve: Resolve<{ id: number }>, reject) => {
    const requestUrl = `${url}/v2/products`;

    axios
      .post(`${requestUrl}`, product, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const updateProduct = (product: Partial<IProduct>) =>
  new Promise((resolve: Resolve<IProduct>, reject) => {
    axios
      .patch(`${url}/v2/products/${product.id}`, product, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const deleteProduct = (product: number) =>
  new Promise((resolve: Resolve<IProduct>, reject) => {
    axios
      .delete(`${url}/v2/products/${product}`, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
export const productInteraction = (interaction: {
  product: number;
  visitor: number;
  comment?: string;
}) =>
  new Promise((resolve: Resolve<IProduct>, reject: Resolve<{ Error: string[] }>) => {
    axios
      .post(`${url}/v2/product-interactions`, interaction, config())
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
