import auth from './auth/fr';
import common from './common/fr';
import dashboard from './dashboard/fr';
import account from './account/fr';
import exhibitors from './exhibitors/fr';
import visitors from './visitors/fr';
import sessions from './sessions/fr';
import managers from './managers/fr';
import root from './root/fr';
import phone_input from './phone_input/fr';
import live from './live/fr';

export default {
  auth,
  common,
  sessions,
  managers,
  dashboard,
  account,
  exhibitors,
  visitors,
  root,
  phone_input,
  live,
};
